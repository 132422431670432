import { isArray, omit } from 'lodash'

const modules = require.context('./modules', true, /\.js$/)
/**
 * 异步路由
 * @type { import('vue-router/types/router').Route[] }
 */
export const asyncRouterMap = []
modules.keys().forEach(name => {
  const mod = modules(name).default || {}
  asyncRouterMap.push(...(isArray(mod) ? mod : [mod]))
})

/**
 * 基础路由
 * @type { import('vue-router/types/router').Route[] }
 */
export const constantRouterMap = [
  { path: '/', name: 'index', meta: { auth: false }, redirect: { name: 'dashboard-console', query: {}, replace: true } },
  // 刷新页面 必须保留
  {
    path: '/refresh',
    name: 'refresh',
    meta: { auth: false },
    hidden: true,
    component: {
      beforeRouteEnter(to, from, next) {
        next(instance => instance.$router.replace(from.fullPath));
      },
      render: h => h()
    }
  },
  // 页面重定向 必须保留
  {
    path: '/redirect/:route*',
    name: 'redirect',
    meta: {auth: false, progressBar: false},
    hidden: true,
    component: {
      beforeRouteEnter(to, from, next) {
        const route = decodeURIComponent(to.params.route)

        next({
          ...(route && route.includes('{'))
            ? route
            : {path: `/${route}`, query: omit(to.query, ['id', 'userCode', 'userType', 'accessToken'])},
          replace: true
        })
      },
      render: h => h()
    }
  },
  {
    path: '/403', name: 'exception-403', meta: {auth: false, title: '403'},
    component: () => import('@/pages/system/error/403')
  },
  {
    path: '/404', name: 'exception-404', meta: {auth: false, title: '404'},
    component: () => import('@/pages/system/error/404')
  },
  {
    path: '/500', name: 'exception-500', meta: {auth: false, title: '500'},
    component: () => import('@/pages/system/error/500')
  },

  // 登录
  {
    path: '/login', name: 'login',
    meta: { auth: false },
    redirect: { name: 'dashboard-console', query: {}, replace: true }
  },
  // 注册
  {
    path: '/register', name: 'register',
    meta: { auth: false },
    redirect: { name: 'dashboard-console', query: {}, replace: true }
  },
  // 注册结果
  {
    path: '/register/result', name: 'register-result',
    meta: { auth: false },
    redirect: { name: 'dashboard-console', query: {}, replace: true }
  },
  // 企业微信登录
  {
    path: '/account/enterprise-wechat-login',
    name: 'enterprise-wechat-login',
    meta: { auth: false },
    redirect: { name: 'dashboard-console', query: {}, replace: true }
  },

  // 签字
  {
    path: '/mobile/tenantsignature',
    name: 'mobile-tenantsignature',
    meta: { auth: false, title: '合同' },
    component: () => import('@/pages/mobile/sinature/index')
  },
  {
    path: '/sinature/sinature',
    name: 'sinature-sinature',
    meta: { auth: false, title: '合同签字' },
    component: () => import('@/pages/mobile/sinature/signature')
  },

  // 下定签字
  {
    path: '/mobile/tenantordersign',
    name: 'mobile-tenantordersign',
    meta: { auth: false, title: '下定' },
    component: () => import('@/pages/mobile/xd-sinature/index')
  },
  {
    path: '/sinature/xd-sinature',
    name: 'sinature-xd-sinature',
    meta: { auth: false, title: '下定签字' },
    component: () => import('@/pages/mobile/xd-sinature/signature')
  },
  // 换房签字
  {
    path: '/mobile/hz-sinature',
    name: 'mobile-hz-sinature',
    meta: { auth: false, title: '换房' },
    component: () => import('@/pages/mobile/hz-sinature/index')
  },
  {
    path: '/sinature/hz-sinature',
    name: 'sinature-hz-sinature',
    meta: { auth: false, title: '换房签字' },
    component: () => import('@/pages/mobile/hz-sinature/signature')
  },
  {
    path: '/mobile/rz-sinature',
    name: 'mobile-rz-sinature',
    meta: { auth: false, title: '入住书' },
    component: () => import('@/pages/mobile/rz-sinature/index')
  },
  {
    path: '/mobile/sinature-rz',
    name: 'mobile-sinature-rz',
    meta: { auth: false, title: '入住签字' },
    component: () => import('@/pages/mobile/rz-sinature/signature')
  },
  //人脸认证
  {
    path: '/mobile/face-verification',
    name: 'mobile-face-verification',
    meta: { auth: false, title: '人脸认证' },
    component: () => import('@/pages/mobile/face-verification/faceVerification')
  },
  // 换房结算签字
  {
    path: '/mobile/hz-settlement-sinature',
    name: 'mobile-hz-settlement-sinature',
    meta: { auth: false, title: '换房结算' },
    component: () => import('@/pages/mobile/hz-settlement-sinature/index')
  },
  {
    path: '/sinature/hz-settlement-sinature',
    name: 'sinature-hz-settlement-sinature',
    meta: { auth: false, title: '换房结算签字' },
    component: () => import('@/pages/mobile/hz-settlement-sinature/signature')
  },

]

export default [
  ...constantRouterMap,
  ...asyncRouterMap,
  { path: '*', redirect: '/404', hidden: true }
]
