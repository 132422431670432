import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import storageManage from './modules/storageManage'
import dataChangeApply from './modules/dataChangeApply'
import financial from './modules/financial'
import store from './modules/store';
import dictionary from './modules/dictionary'
import rent from './modules/rent'
import baseManage from './modules/baseManage'
import processCenter from './modules/processCenter'
import voucherManage from './modules/voucherManage'
import invest from './modules/invest'
import configuration from './modules/configuration'
import reportCenter from './modules/reportCenter'
import device from './modules/device'
import dashboard from './modules/dashboard'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    admin,
    storageManage,
    dataChangeApply,
    store,
    dictionary,
    financial,
    rent,
    invest,
    baseManage,
    processCenter,
    voucherManage,
    configuration,
    device,
    reportCenter,
    dashboard
  },
})
