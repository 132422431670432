import BasicLayout from '@/layouts/basic-layout';

const prefix = 'dynamic-model-'
const redirect = {name: `${prefix}system-setting-dictionaryPj-list`}
export default [
  {path: '/dynamic-model', redirect},
  {
    path: '/dynamic-model/system-setting',
    redirect,
    component: BasicLayout,
    children: [
      {
        path: 'dictionaryPj/index',
        name: `${prefix}system-setting-dictionaryPj-list`,
        meta: {title: '平级数据字典'},
        component: () => import('@/pages/dynamic-model/system-setting/dictionaryPj/index')
      }
    ]
  }
]
