import BasicLayout from '@/layouts/basic-layout'

const prefix = 'equipment-'
export default [
  {
    path: '/equipment',
    name: 'equipment',
    redirect: {name: `${prefix}device-binding`},
    component: BasicLayout,
    children: [
      {
        path: 'device-binding',
        name: `${prefix}device-binding`,
        meta: {title: '智能设备绑定'},
        component: () => import('@/pages/equipment/device-binding')
      },
      {
        path: 'door-lock',
        name: `${prefix}door-lock`,
        meta: {title: '门锁管理'},
        component: () => import('@/pages/equipment/door-lock')
      },
      {
        path: 'electric-meter',
        name: `${prefix}electric-meter`,
        meta: {title: '电表管理'},
        component: () => import('@/pages/equipment/electric-meter')
      },
      {
        path: 'cold-water-meter',
        name: `${prefix}cold-water-meter`,
        meta: {title: '冷水表管理'},
        component: () => import('@/pages/equipment/cold-water-meter')
      },
      {
        path: 'hot-water-meter',
        name: `${prefix}hot-water-meter`,
        meta: {title: '热水表管理'},
        component: () => import('@/pages/equipment/hot-water-meter')
      },
      {
        path: 'gas-meter',
        name: `${prefix}gas-meter`,
        meta: {title: '燃气表管理'},
        component: () => import('@/pages/equipment/gas-meter')
      }
    ]
  }
]
