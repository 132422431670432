/*
 * @Author: co
 * @Date: 2021-09-06 15:15:18
 */
const RespCommonDef = {
  //查询条件
  AdvanceQuery: {
    entity: {
      orderByDesc: "",
      orderByList: "",
      pageNum: 1,
      pageSize: 10
    }
  },
  //列表初使化接口返回
  CompoundEntitiesDictionary: {
    configItems: {},
    configRanges: [],
    configTrees: [],
    pageFunctionAuthorizations: [{
      rangeAuthorizations: []
    }], //功能权限
    rowFunctionAuthorization: [],
    entities: [],
  },
  //列表接口返回
  CompoundEntities: {
    rowFunctionAuthorization: [],
    pageFunctionAuthorizations: [],
    entities: [{
      entities: [],
      total: 0
    }],
  },
  Entities: {
    entities: [],
    total: 0
  },
  CompoundEntity: {
    pageFunctionAuthorizations: [],
    entityResponse: []
  },
  CompoundEntityDictionary: {
    configItems: [],
    configRanges: [],
    configTrees: [],
    pageFunctionAuthorizations: [],
    entityResponse: []
  },
  Count: {
    effectCount: 0
  },
  Entity: {
    entity: [],
    isEffect: false
  },
  EntityCount: {
    entity: [],
    isEffect: false,
    effectCount: 0
  },
  Row: {
    row: [],
    isEffect: false
  },
  RowAuthorization: {
    rowId: [],
    accessCodes: []
  },
  Rows: {
    total: 0,
    rows: []
  }

};

export default RespCommonDef;
