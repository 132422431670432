// 房态图
import request from '@/plugins/request/http'
export const queryList = data => {
  return request('/operation/v1/house/queryList').post('postNormal', data)
}
// 查询用户所属门店
export const storePersonnel = data => {
  return request('/operation/v1/storePersonnel/userId/queryByUserId').get('locationPathQs', data)
}
// 房源详情
export const queryDetailById = data => {
  return request('/operation/v1/house/queryDetailById/id').get('locationPath', data)
}
