import http from '@/plugins/request/http'
import request from '@/plugins/request'

// 分页查询
export const getList = data => http('/reportform/v1/storePrimeCostReport/page').post('postNormal', data)
// 导出
export const exportStorePrimeCostReport = data => request.post('/reportform/v1/storePrimeCostReport/exportStorePrimeCostReport', data, {
  entire: true,
  responseType: 'blob'
})
