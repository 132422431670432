/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 人员管理
export const getStorePersonnelList = data => {
  return request('/operation/v1/storePersonnel/page').post('postNormal', data)
}
// 添加人员
export const storePersonnelAdd = data => {
  return request('/operation/v1/storePersonnel').post('postNormal', data)
}
// 删除人员
export const storePersonnelDelete = data => {
  return request('/operation/v1/storePersonnel/id/delete').post('postLocationPath', data)
}
// 修改人员
export const storePersonnelEdit = data => {
  return request('/operation/v1/storePersonnel/id/update').post('postLocationPath', data)
}
// 查看人员
export const storePersonnelView = data => {
  return request('/operation/v1/storePersonnel/id/queryByPrimaryKey').get('locationPath', data)
}