/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 实付分页查询
export const getFinEvidenceList = data => {
  return request('/operation/v1/finEvidence/page').post('postNormal', data)
}
// 实付根据id查询信息
export const finActualPaymentQuery = data => {
  return request('/operation/v1/finEvidence/evidenceId/queryByPrimaryKey').get('locationPath', data)
}
// 统计实付账单金额
export const countCollection = data => {
  return request('/operation/v1/finEvidence/countCollection').post('postNormal', data)
}
// 费用明细查询
export const queryEvidenceItemByPage = data => {
  return request('/operation/v1/finEvidence/queryEvidenceItemByPage').post('postNormal', data)
}
// 导出实付账单信息
export const exportEvidence = data => {
  return request('/operation/v1/finEvidence/exportEvidence').post('postBlobQuery', data)
}