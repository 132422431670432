/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 单次-日杂费管理用分页查询
export const getFinPaymentApplyList = data => {
  return request('/operation/v1/miscfee/page').post('postNormal', data)
}
// 根据id查询信息
export const finPaymentApplyQueryByPrimaryKey = data => {
  return request('/operation/v1/miscfee/selectPageOfHouseDetail').post('postNormal', data)
}
// 导出模板
export const downLoadMiscFeeTemplate = data => {
  return request('/operation/v1/miscfee/downLoadMiscFeeTemplate').post('postBlobQuery', data)
}
// 下载表单对应的日杂费详情
export const exportMiscFeeOfDocumentId = data => {
  return request('/operation/v1/miscfee/exportMiscFeeOfDocumentId').post('postBlobQuery', data)
}

// 周期-日杂费管理用分页查询
export const getCycleMiscfeeList = data => {
  return request('/operation/v1/cycleMiscfee/queryByCycleMiscFeeConfigPage').post('postNormal', data)
}
// 根据id查询周期性日杂费配置信息
export const cycleMiscfeeQueryByPrimaryKey = data => {
  return request('/operation/v1/cycleMiscfee/id/queryByPrimaryKey').get('locationPath', data)
}
// 周期详情-已生成账单分页查询
export const getQueryCycleMiscFeeByPageList = data => {
  return request('/operation/v1/cycleMiscfee/queryCycleMiscFeeByPage').post('postNormal', data)
}

// 房源查询-日杂费管理用
export const rentedHouseList = data => {
  return request('/operation/v1/house/rentedHouseList').post('postNormal', data)
}
// 生成历史账单
export const manualGenerateCycleMiscFee = data => {
  return request('/operation/v1/cycleMiscfee/id/manualGenerateCycleMiscFee/date').get('locationPathQs', data)
}
// 生成失败日志
export const queryCycleMiscFeeFailPaymentLogByPage = data => {
  return request('/operation/v1/cycleMiscfee/queryCycleMiscFeeFailPaymentLogByPage').post('postNormal', data)
}
// 生成应收账单
export const manualGeneratePayment = data => {
  return request('/operation/v1/cycleMiscfee/id/manualGeneratePayment').get('locationPath', data)
}