import BasicLayout from '@/layouts/basic-layout'

const prefix = 'bill-push-'
export default {
  path: '/financial-manage/bill-push',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '账单推送管理', cache: true},
      component: () => import('@/pages/financial-manage/bill-push/index')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '账单推送', cache: true, virtualScroller: true, currentActiveMenu: '/financial-manage/bill-push/index'},
      component: () => import('@/pages/financial-manage/bill-push/detail-edit')
    }
  ]
}
