


export default {
  namespaced: true,
  state: {

    mobile: { signature: {} },
    // 查询条件
    params: {
      houseRentCode: '',
      houseRentAddress: '',
      tenantName: '',
      tenantPhone: '',
      bizState: '',
      pageNum: 1,
      pageSize: 10,
      storeId: '',
    },
  },
  getters: {

  },
  // 持久缓存
  mutations: {
    setParams(state) {
      state.params.pageNum = 1
      state.params.pageSize = 10
      state.params.houseRentCode = ''

      state.params.houseRentAddress = ''
      state.params.tenantName = ''
      state.params.tenantPhone = ''
      state.params.bizState = ''
      state.params.storeId = ''

    }
  },

  actions: {



  }

}
