/*
 * @Author: co
 * @Date: 2021-09-06 15:14:17
 */
import request from '@/plugins/request'
import http from '@/plugins/request/http'

export const getCardMarketingList = data => {
  return http('/operation/v1/cardMarketing/getPagetList').post('postNormal', data)
}
// 房源查询-新增卡券使用
export const getRentHostList = data => {
  return http('/operation/v1/house/getRentHostList').post('postNormal', data)
}
// 新增卡券申请和明细接口
export const saveCardMarketing = data => {
  return http('/operation/v1/cardMarketing/saveCardMarketing').post('postNormal', data)
}
// 获取卡券详情接口
export const cardMarketinggetDetail = data => {
  return http('/operation/v1/cardMarketing/getDetail').get('normal', data)
}
// 导出卡券房源
export const exportCardMarketingHouse = data => request.post('/operation/v1/cardMarketing/exportCardMarketingHouse', data, {responseType: 'blob', entire: true})
// 导出优惠券
export const exportCard = data => request.post('/operation/v1/cardMarketing/exportCard', data, {responseType: 'blob', entire: true})

// 获取卡券分页列表接口
export const getCardPagetList = data => {
  return http('/operation/v1/cardMarketing/getCardPagetList').post('postNormal', data)
}
// 修改卡券申请状态接口
export const changeStatus = data => {
  return http('/operation/v1/cardMarketing/changeStatus').post('postNormal', data)
}
// 修改卡券明细申请状态接口
export const changeCardStatus = data => {
  return http('/operation/v1/cardMarketing/changeCardStatus').post('postNormal', data)
}
// 获取用户是否是品牌营销部接口
export const getRole = data => {
  return http('/operation/v1/cardMarketing/getRole').get('normal', data)
}
// 获取卡券详情接口(单张)
export const getCardDetail = data => {
  return http('/operation/v1/cardMarketing/getCardDetail').get('normal', data)
}

// 应付列表根据合同id查询有效卡券
export const getCardList = data => http('/operation/v1/cardMarketing/getCardList').get('normal', data)
