<template>
  <div>
    <JFDrawer style="position: relative; z-index: 1002;"
              :title="`流程${typeName}`" :closable="!isSubmit" :mask-closable="!isSubmit"
              v-model="visible">
      <Form ref="form" label-position="top" :model="form" :rules="rules"
            v-if="['sign', 'fallback'].includes(type)">
        <FormItem prop="auditBrief" :label="`${typeName}意见：`">
          <Input type="textarea"
                 :placeholder="`请输入${typeName}意见`" :autosize="{minRows: 4, maxRows: 8}"
                 v-model="form.auditBrief" />
        </FormItem>
      </Form>

      <template v-if="isMobile">
        <Card dis-hover :title="['sign', 'fallback'].includes(type) ? '环节列表' : ''" :bordered="false">
          <div class="cell-group">
            <div class="cell">
              <div class="cell-title">{{ ['sign', 'fallback'].includes(type) ? '环节人员' : '转发给' }}</div>
              <div class="cell-desc">
                <span class="nextAuditPersonName" v-if="selectedRow.name">{{ selectedRow.name }}</span>
                <a @click="selectApprover = true">选择人员</a>
              </div>
            </div>
          </div>
        </Card>
      </template>
      <template v-else>
        <SelectUser :visible="visible" @on-change="value => selectedRow = value" />
      </template>

      <Card dis-hover class="ivu-mt-8" title="审批详情" :bordered="false">
        <ProcessSteps :list="detail.prevDetailVos" />
      </Card>
      <template slot="footer">
        <Button type="primary"
                :long="isMobile" :loading="isSubmit"
                @click="onSubmitHandle">提交</Button>
      </template>
    </JFDrawer>

    <template v-if="isMobile">
      <JFDrawer style="position: relative; z-index: 1003;" title="选择人员" v-model="selectApprover">
        <SelectUser :visible="selectApprover" @on-change="value => selectApproverRow = value" />
        <template slot="footer">
          <Row style="flex: 1" :gutter="10">
            <Col :span="12"><Button :long="isMobile" @click="selectApprover = false">取消</Button></Col>
            <Col :span="12"><Button type="primary" :long="isMobile" @click="getSelectEvent">确认</Button></Col>
          </Row>
        </template>
      </JFDrawer>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { get, cloneDeep } from 'lodash'
  import { passAudit } from '@api/process-center'
  import SelectUser from './components/select-user'
  import ProcessSteps from './components/process-steps'

  export default {
    name: 'ProcessType',
    components: {SelectUser, ProcessSteps},
    props: {
      value: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: ''
      },
      detail: {
        type: Object,
        default: () => ({})
      },
    },
    watch: {
      visible (value) {
        if (value) this.form.auditBrief = `流程${this.typeName}`
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      rules () {
        return {
          auditBrief: [{required: true, message: `请输入${this.typeName}意见`}],
        }
      },
      typeName () {
        return {sign: '加签', forward: '转发', fallback: '回退'}[this.type]
      },
    },
    methods: {
      async onSubmitHandle () {
        try {
          this.$Message.destroy()
          if (['sign', 'fallback'].includes(this.type))
            if (!await this.$refs.form.validate()) return
          if (!this.selectedRow) return this.$Message.error(`请选择${this.typeName}人员`)
          const {auditBrief} = this.form
          const {query, params} = this.$route
          const {currentDetailVo} = this.detail
          const {id, name} = this.selectedRow

          this.isSubmit = true
          await passAudit({
            auditBrief,
            documentId: params.id,
            approvalProcessId: query.approvalProcessId,
            nextAuditPersonId: id,
            nextAuditPersonName: name,
            auditApprovalItemDetailId: get(currentDetailVo, 'id') || '',
            ...this.type === 'sign' && {
              nextNodeId: 0,
              nextNodeType: 3,
            },
            ...this.type === 'forward' && {
              nextNodeId: get(currentDetailVo, 'nodeId') || '',
              nextNodeType: 2,
            },
          })
          this.visible = false
          this.$emit('on-success', this.type)
          this.$Message.success(`流程${this.typeName}成功`)
        } catch (e) {} finally {
          this.isSubmit = false
        }
      },
      getSelectEvent () {
        this.selectedRow = cloneDeep(this.selectApproverRow)
        this.selectApprover = false
      }
    },
    data () {
      return {
        visible: false,
        isSubmit: false,
        form: {
          auditBrief: '',
        },
        selectedRow: {},

        selectApprover: false,
        selectApproverRow: {},
      }
    },
  }
</script>

<style scoped lang="less">
  /deep/ .cell {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &-title {
      width: 60px;
    }
    &-desc {
      flex: 1;
    }
  }
  // Card
  /deep/ .ivu-card-head,
  /deep/ .ivu-card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .nextAuditPersonName {
    margin-right: 15px;
  }
</style>
