


export default {
  namespaced: true,
  state: {

    // 查询条件
    params: {
      houseCode: '',
      pageNum: 1,
      pageSize: 10,
      storeId: '',
      doorLockType: '',
      isIntelligence: '',
      onLineState: '',
      state: ''
    },
  },
  getters: {

  },
  // 持久缓存
  mutations: {
    // setCurrentRow(state, row) {
    //   state.currentRow = row
    // }
    setParams(state, data) {
      state.params = data
    }
  },

  actions: {



  }

}
