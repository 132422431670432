/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const getList = data => {
  return request('/reportform/v1/orderReteatReport/page').post('postNormal', data)
}
// 导出
export const exportOrderReteatReport = data => {
  return request('/reportform/v1/orderReteatReport/exportOrderReteatReport').post('postBlobQuery', data)
}