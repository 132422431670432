/*
 * @Author: co
 * @Date: 2021-09-06 15:14:17
 */
import request from '@/plugins/request/http.js'
// 分页查询
export const getApprovalProcessList = data => {
  return request('/user/v1/approvalProcess/page').post('postNormal', data)
}
// 添加ApprovalProcess
export const approvalProcessAdd = data => {
  return request('/user/v1/approvalProcess').post('postNormal', data)
}
// 修改ApprovalProcess
export const approvalProcessUpdate = data => {
  return request('/user/v1/approvalProcess/id/update').post('postLocationPath', data)
}
// 删除ApprovalProcess
export const approvalProcessDelete = data => {
  return request('/user/v1/approvalProcess/id/delete').post('postLocationPath', data)
}
// 添加CompanyApprovalProcess
export const companyApprovalProcessBatchAdd = data => {
  return request('/user/v1/companyApprovalProcess/batchAdd').post('postNormal', data)
}
// 分页查询流程列表
export const processQueryByPageOfCompany = data => {
  return request('/user/v1/approvalProcess/queryByPageOfCompany').post('postNormal', data)
}
// 查寻指定公司指定审批流程节点配置
export const queryCompanyApprovalProcessNodeVos = data => {
  return request('/user/v1/approvalProcess/companyOrgId/queryCompanyApprovalProcessNodeVos/approvalProcessId').post('postLocationPath', data)
}
// 添加ApprovalProcessNode
export const approvalProcessNodeAdd = data => {
  return request('/user/v1/approvalProcessNode').post('postNormal', data)
}
// 修改approvalProcessNode
export const approvalProcessNodeUpdate = data => {
  return request('/user/v1/approvalProcessNode/id/update').post('postLocationPath', data)
}
// 删除approvalProcessNode
export const approvalProcessNodeDelete = data => {
  return request('/user/v1/approvalProcessNode/id/delete').post('postLocationPath', data)
}


// getApprovalItemList
export const getApprovalItemList = data => {
  return request('/user/v1/approvalItem/page').post('postNormal', data)
}

// 查看审批流的链路(审批中心进入)
export const approvalQueryWorkflowChain = data => {
  return request('/user/v1/approvalItem/approvalProcessId/queryWorkflowChain/documentId').get('locationPath', data)
}

// 查看审批流的链路(详情进入)
export const approvalProcessCodeQueryWorkflowChain = data => {
  return request('/user/v1/approvalItem/approvalProcessCode/queryWorkflowChainByCodeAndDocumentId/documentId').get('locationPath', data)
}
// 分页查询当前角色下的用户
export const queryRoleEmployeesByPage = data => {
  return request('/user/v1/role/queryRoleEmployeesByPage').post('postNormal', data)
}
// 审批通过：提交
export const passAudit = data => {
  return request('/operation/v1/workflow/passAudit').post('postNormal', data)
}
// 审批不通过：提交
export const rejectAudit = data => {
  return request('/operation/v1/workflow/reject').post('postNormal', data)
}

// 查询第一个节点
export const queryApprovalFirstNode = (data, headersConfig = {}) => {
  return request('/user/v1/approvalProcess/code/queryApprovalFirstNode/companyOrgId').post('postLocationPath', data)
}
// 表单提交
export const startWorkflow = (data, headersConfig = {}, config = {}) => request('/operation/v1/workflow/startWorkflow').post('postNormal', data, headersConfig, config)

// 流程回退
export const processRollBack = data => {
  return request('/user/v1/approvalItemDetail/rollBack').post('postNormal', data)
}

// 查询指定流程的流程图信息
export const queryProcessImage = (data) => {
  return request('/user/v1/processImage/companyOrgId/queryProcessImage/approvalProcessId').get('locationPath', data)
}
// 添加ProcessImage
export const processImageAdd = data => {
  return request('/user/v1/processImage').post('postNormal', data)
}
// 删除ProcessImage
export const processImageDelete = data => {
  return request('/user/v1/processImage/id/delete').post('postLocationPath', data)
}

// 流程作废
export const processCancel = data => {
  return request('/operation/v1/workflow/cancel').post('postNormal', data)
}

// 审批中心-未审批红点状态
export const updatePendingApprovalPoint = data => {
  return request('/user/v1/approvalItem/id/update').post('postLocationPath', data)
}

// 流程回撤
export const retreat = data => {
  return request('/operation/v1/workflow/retreat').post('postNormal', data)
}
// 批量审批
export const batchAudit = data => {
  return request('/operation/v1/workflow/batchAudit').post('postNormal', data)
}
// 获取投拓项目负责人信息
export const getProjectLeaderInfo = data => {
  return request('/operation/v1/pgConfiguration/id/getProjectLeaderInfo').post('postLocationPath', data)
}
