/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 结算分页查询
export const getFinSettlementList = data => {
  return request('/operation/v1/finSettlement/page').post('postNormal', data)
}
// 结算根据id查询信息
export const finSettlementQueryByPrimaryKey = data => {
  return request('/operation/v1/finSettlement/id/queryByPrimaryKey').get('locationPath', data)
}
// 确认付款/确认收款(承租端业主解约结算)
export const finSettlementConfirm = data => {
  return request('/operation/v1/finSettlement/confirm').get('normal', data)
}
// 确认付款/确认收款(出租端)
export const renterPayConfirm = data => {
  return request('/operation/v1/finSettlement/renterPayConfirm').get('normal', data)
}

// 统计结算账单金额
export const countSettlement = data => {
  return request('/operation/v1/finSettlement/countSettlement').post('postNormal', data)
}
// 导出结算账单信息
export const exportFinSettlement = data => {
  return request('/operation/v1/finSettlement/exportFinSettlement').post('postBlobQuery', data)
}