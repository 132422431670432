import BasicLayout from '@/layouts/basic-layout';

const prefix = 'house-layout-manage-'
export default [
  {
    path: '/store/house-layout-manage',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {title: '房型管理'},
        component: () => import('@/pages/store/house-layout-manage/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {title: '房型编辑'},
        component: () => import('@/pages/store/house-layout-manage/detail-edit')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '房型详情'},
        component: () => import('@/pages/store/house-layout-manage/detail-view')
      }
    ]
  }
]
