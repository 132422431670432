<template>
  <div>
    <JFDrawer style="position: relative; z-index: 1002;" title="流程回退"
              :closable="!isSubmit" :mask-closable="!isSubmit"
              v-model="visible">
      <Form ref="form" label-position="top" :model="form" :rules="rules">
        <FormItem prop="auditBrief" label="回退意见：">
          <Input type="textarea" placeholder="请输入回退意见"
                 :autosize="{minRows: 4, maxRows: 8}"
                 v-model="form.auditBrief" />
        </FormItem>
      </Form>
      <Card dis-hover title="环节列表" :bordered="false">
        <template v-if="isMobile">
          <div class="cell-group">
            <div class="cell">
              <div class="cell-title">环节名称</div>
              <div class="cell-desc">{{ selectRow.nodeName }}</div>
            </div>
            <div class="cell">
              <div class="cell-title">环节人员</div>
              <div class="cell-desc">
                <span class="nextAuditPersonName" v-if="selectRow.approvedByName">{{ selectRow.approvedByName }}</span>
                <a @click="selectApprover = true">选择人员</a>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <vxe-table resize auto-resize highlight-hover-row
                     ref="table" class="ivu-mt-8"
                     row-id="id" max-height="400"
                     :data="detail.prevDetailVos" :radio-config="radioConfig">
            <vxe-column type="radio" width="60" />
            <vxe-column field="nodeName" title="节点名称" />
            <vxe-column field="approvedByName" title="审批人名字" />
            <vxe-column field="type" title="类型">
              <template #default="{ row }">{{ typeName(row.type) }}</template>
            </vxe-column>
            <vxe-column field="brief" title="备注" />
            <vxe-column field="auditTime" title="审核完成时间" />
          </vxe-table>
        </template>
      </Card>
      <Card dis-hover class="ivu-mt-8" title="审批详情" :bordered="false">
        <ProcessSteps :list="detail.prevDetailVos" />
      </Card>
      <template slot="footer">
        <Button type="primary"
                :long="isMobile" :loading="isSubmit"
                @click="onSubmitHandle">提交</Button>
      </template>
    </JFDrawer>
    <JFDrawer style="position: relative; z-index: 1003;" title="环节列表" v-model="selectApprover">
      <RadioGroup v-model="radio">
        <template v-for="item of detail.prevDetailVos">
          <Radio :key="item.id" :label="item.id">
            <Card dis-hover :bordered="false">
              <div class="cell-group">
                <div class="cell">
                  <div class="cell-title">节点名称</div>
                  <div class="cell-desc">{{ item.nodeName }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">审批人名字</div>
                  <div class="cell-desc">{{ item.approvedByName }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">类型</div>
                  <div class="cell-desc">{{ typeName(item.type) }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">备注</div>
                  <div class="cell-desc">{{ item.brief }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">审核完成时间</div>
                  <div class="cell-desc">{{ item.auditTime }}</div>
                </div>
              </div>
            </Card>
          </Radio>
        </template>
      </RadioGroup>
      <template slot="footer">
        <Row style="flex: 1" :gutter="10">
          <Col :span="12"><Button :long="isMobile" @click="selectApprover = false">取消</Button></Col>
          <Col :span="12"><Button type="primary" :long="isMobile" @click="getSelectEvent">确认</Button></Col>
        </Row>
      </template>
    </JFDrawer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { get } from 'lodash'
  import {processRollBack} from '@api/process-center'
  import ProcessSteps from './components/process-steps'

  export default {
    name: 'ProcessFallback',
    components: {ProcessSteps},
    props: {
      value: {
        type: Boolean,
        default: false
      },
      detail: {
        type: Object,
        default: () => ({})
      },
    },
    watch: {
      visible (value) {
        if (value) this.form.auditBrief = '流程回退'
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      radioConfig () {
        return {
          strict: false,
          trigger: 'row',
          highlight: true
        }
      },
    },
    methods: {
      async onSubmitHandle () {
        try {
          this.$Message.destroy()
          if (!await this.$refs.form.validate()) return
          if (!this.getRadioRecord()) return this.$Message.error('请选择回退节点')
          const {auditBrief} = this.form
          const {query, params} = this.$route
          const {currentDetailVo} = this.detail
          const {type, nodeId, approvedById, approvedByName} = this.getRadioRecord()

          this.isSubmit = true
          await processRollBack({
            auditBrief,
            documentId: params.id,
            nextNodeId: nodeId,
            nextNodeType: type,
            approvalProcessId: query.approvalProcessId,
            nextAuditPersonId: approvedById,
            nextAuditPersonName: approvedByName,
            auditApprovalItemDetailId: get(currentDetailVo, 'id') || '',
          })

          this.visible = false
          this.$emit('on-success')
          this.$Message.success('流程回退成功')
        } catch (e) {} finally {
          this.isSubmit = false
        }
      },
      getRadioRecord () {
        if (this.isMobile) return this.selectRow
        return this.$refs.table.getRadioRecord()
      },
      getSelectEvent () {
        this.selectApprover = false
        this.selectRow = this.detail.prevDetailVos.find(item => item.id === this.radio)
      },
      typeName (type) {
        return {
          1: '正常流程',
          2: '流程转发',
          3: '流程加签',
        }[type]
      },
    },
    data () {
      return {
        visible: false,
        selectApprover: false,
        isSubmit: false,
        form: {
          auditBrief: '',
        },
        rules: {
          auditBrief: [{required: true, message: '请输入回退意见'}],
        },
        radio: '',
        selectRow: {},
      }
    },
  }
</script>

<style scoped lang="less">
  /deep/ .cell {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &-title {
      width: 60px;
    }
    &-desc {
      flex: 1;
    }
  }
  // Card
  /deep/ .ivu-card-head,
  /deep/ .ivu-card-body {
    padding-left: 0;
    padding-right: 0;
  }
  // Drawer
  /deep/ .ivu-drawer-body {
    display: flex;
    padding: 0;
    flex-direction: column;
  }
  // scroll-view
  /deep/ .scroll-view {
    flex: 1;
    padding: 16px;
    overflow: auto;
  }

  /deep/ .ivu-radio-group {
    display: block;
  }
  /deep/ .ivu-radio-wrapper {
    padding: 16px 0;
    display: block;
    border-bottom: 1px solid #e8eaec !important;
    position: relative;

    .ivu-card {
      padding-left: 30px;

      &-body {
        padding: 0;
      }
    }
    .cell-title {
      width: 85px;
    }
  }
  /deep/ .ivu-radio {
    position: absolute;
    z-index: 2;
    left: 0;
  }
  .nextAuditPersonName {
    margin-right: 15px;
  }
</style>
