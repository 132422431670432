import qs from 'qs'
import service from '@/plugins/request'

const createAxios = (url) => {
  const commonJoinPath = (data, url) => {
    if (Object.keys(data).length) {
      for (const key in data) {
        url += `/${data[key]}`
      }
    }
    return url
  }
  const commonJoinQuery = (data, url) => {
    for (const key in data) {
      url += `${url.indexOf('?') < 0 ? '?' : '&'}${key}=${data[key]}`
    }
    return url
  }

  return {
    /*
     * axios get 请求
     * @param {Object} data - 需要的传参，选传，默认值为空对象
     * @param {Object} config - 其他配置项，选传，默认值为空对象
     * */
    get (requestType, data = {}, config = {}) {
      // console.log(data, baseURL)
      function commonGetPath (url, type = 0) {
        if (type) {
          url += `&_t=${Date.now()}`;
        } else {
          url += `?_t=${Date.now()}`; // 加时间戳，防止ie取缓存
        }

        return new Promise((resolve, reject) => {
          service({
            url: url,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
      }

      function commonGetBlob (url) {
        url.indexOf('?') < 0 ? url += `?_t=${Date.now()}` : url += `&_t=${Date.now()}`
        return new Promise((resolve, reject) => {
          service({
            url: url,
            method: 'GET',
            responseType: 'blob',
          })
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
      }

      if (requestType === 'normal' || !requestType) {
        // param参数类型
        url += requestType ? '' : `?_t=${Date.now()}`; // 加时间戳，防止ie取缓存
        data = requestType
          ? Object.assign({}, data, {
            _t: Date.now(),
          })
          : data;
        return new Promise((resolve, reject) => {
          service({
            url: url,
            method: 'GET',
            params: data,
          })
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })

      }
      if (requestType === 'orderPath') {
        // 按顺序拼接到url上
        url = commonJoinPath(data, url);
        return commonGetPath(url);
      }
      if (requestType === 'locationPath') {
        //  指定位置拼接到url上
        if (Object.keys(data).length) {
          for (let key in data) {
            let reg = new RegExp(key, 'g');
            url = url.replace(reg, data[key]);
          }
        }
        return commonGetPath(url);
      }
      if (requestType === 'locationPathQs') {
        //  指定位置拼接到url上
        if (Object.keys(data).length) {
          let str = ''
          for (let key in data) {
            let reg = new RegExp(key, 'g');
            url = url.replace(reg, data[key]);
            // str += `${key}=${data[key]}&`;
          }
          // str = str.substr(0, str.length - 1)
          // url += `?${str}`
          str = qs.stringify(data)
          url += `?${str}`
        }

        return commonGetPath(url, 1);
      }
      if (requestType === 'blob') {
        //  返回值为blob数据流
        return commonGetBlob(url);
      }
      if (requestType === 'pathBlob') {
        //  返回值为blob数据流
        url = commonJoinPath(data, url);
        return commonGetBlob(url);
      }
      if (requestType === 'queryBlob') {
        //  返回值为blob数据流
        url = commonJoinQuery(data, url);
        return commonGetBlob(url);
      }
    },
    /*
     * axios post 请求
     * @param {Object} data - 需要的传参，选传，默认值为空对象
     * @param {Object} config - 其他配置项，选传，默认值为空对象
     * */
    post (requestType, data = {}, headersConfig = {}, config = {}) {
      function commonPostBody (url, data) {
        return new Promise((resolve, reject) => {
          service({
            method: 'POST',
            url: url,
            data: data,
            headers: headersConfig,
            ...config
          }).then(res => resolve(res)).catch(err => reject(err))
        })
      }

      function commonPostFormData (url, formData) {
        return new Promise((resolve, reject) => {
          service({
            method: 'POST',
            url: url,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(formData, { arrayFormat: 'brackets' }),
            ...config
          }).then(res => resolve(res)).catch(err => reject(err))
        })
      }

      function commonPostBlob (url, data) {
        return new Promise((resolve, reject) => {
          service({
            method: 'POST',
            url: url,
            responseType: 'blob',
            data: data,
            ...config
          }).then(res => resolve(res)).catch(err => reject(err))
        })
      }

      function commonPostBlobFormDataQuery (url, data) {
        return new Promise((resolve, reject) => {
          service({
            url,
            method: 'POST',
            responseType: 'blob',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data, { arrayFormat: 'brackets' }),
            ...config
          }).then(res => resolve(res)).catch(err => reject(err))
        })
      }

      if (requestType === 'postNormal' || !requestType) {
        return commonPostBody(url, data);
      }
      if (requestType === 'postLocationPath') {
        //  指定位置拼接到url上
        if (Object.keys(data).length) {
          for (let key in data) {
            let reg = new RegExp(key, 'g');
            url = url.replace(reg, data[key]);
          }
        }
        // console.log(url)
        return commonPostBody(url, data);
      }
      // 除了本身的body为json格式，部分参数以/形式拼接到url上
      if (requestType === 'postPath') {
        url = commonJoinPath(config, url);
        return commonPostBody(url, data);
      }
      // 除了本身的body为json格式，部分参数以?a=xx&b=yy形式拼接到url上
      if (requestType === 'postQuery') {
        url = commonJoinQuery(config, url);
        return commonPostBody(url, data);
      }
      // 只有formdata参数
      if (requestType === 'formData') {
        return commonPostFormData(url, data);
      }
      // 除了formdata参数，部分参数以/形式拼接到url上
      if (requestType === 'postFormDataPath') {
        url = commonJoinPath(config, url);
        return commonPostFormData(url, data);
      }
      // 除了formdata参数，部分参数以?a=xx&b=yy形式拼接到url上
      if (requestType === 'postFormDataQuery') {
        url = commonJoinQuery(config, url);
        return commonPostFormData(url, data);
      }
      // 返回值为blob
      if (requestType === 'postBlob') {
        console.log(data);
        return commonPostBlob(url, data);
      }
      if (requestType === 'postBlobPath') {
        url = commonJoinPath(config, url);
        return commonPostBlob(url, data);
      }
      if (requestType === 'postBlobQuery') {
        url = commonJoinQuery(config, url);
        return commonPostBlob(url, data);
      }
      if (requestType === 'postBlobFormDataQuery') {
        url = commonJoinQuery(config, url);
        return commonPostBlobFormDataQuery(url, data);
      }
      // 只配置id
      if (requestType === 'postLocationPathForId') {
        //  指定位置拼接到url上
        let reg = new RegExp('id', 'g');
        url = url.replace(reg, data['id']);
        // console.log(url)
        return commonPostBody(url, data);
      }
    },
  }
}

export default (url) => createAxios(url)
