import BasicLayout from '@/layouts/basic-layout'

const prefix = 'financial-invoice-'
export default {
  path: '/financial-manage/invoice',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: `${prefix}index`,
      meta: {title: '开具发票', cache: true},
      component: () => import('@/pages/financial-manage/invoice/index')
    },
    {
      path: ':type(edit|view)/:state(create|apply|invalid)/:id(\\d+)?',
      name: `${prefix}edit`,
      meta: {title: ''},
      component: () => import('@/pages/financial-manage/invoice/edit')
    },
    {
      path: 'apply',
      name: `${prefix}apply`,
      meta: {title: '申请记录', cache: true},
      component: () => import('@/pages/financial-manage/invoice/apply')
    },
    {
      path: 'invalid',
      name: `${prefix}invalid`,
      meta: {title: '作废记录', cache: true},
      component: () => import('@/pages/financial-manage/invoice/invalid')
    },
  ]
}
