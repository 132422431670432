import BasicLayout from '@/layouts/basic-layout'

export default {
  path: '/member',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: 'member',
      meta: {cache: true, title: '会员列表'},
      component: () => import('@/pages/member/index')
    }
  ]
}
