import BasicLayout from '@/layouts/basic-layout'

const prefix = 'keep-'
export default {
  path: '/keep',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: 'keep',
      meta: {title: '保留房管理', cache: true},
      component: () => import('@/pages/rent/keep')
    },
    {
      path: 'keep-his/:houseCode',
      name: 'keep-his',
      meta: {title: '历史保留记录', cache: true},
      component: () => import('@/pages/rent/keep/index-his')
    },
    // {
    //   path: 'init',
    //   name: `${prefix}init`,
    //   meta: {title: '新增保留', cache: true},
    //   component: () => import('@/pages/rent/keep/operation/init')
    // },
    {
      path: 'details-sp/:id',
      name: `${prefix}details-sp`,
      meta: {title: '保留申请审批', cache: true},
      component: () => import('@/pages/rent/keep/operation/details/details-sp')
    }
  ]
}
