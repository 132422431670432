<template>
  <div>
    <Form inline ref="form" :model="form" @submit.prevent.stop.native>
      <FormItem prop="name">
        <Input clearable style="width: 200px;" placeholder="请输入姓名" v-model="form.name" />
      </FormItem>
      <FormItem>
        <Button type="primary" :loading="loading" @click="onSubmitHandle">查询</Button>
        <Button class="ivu-ml-8" @click="onResetHandle">重置</Button>
      </FormItem>
    </Form>
    <template v-if="isMobile">
      <RadioGroup v-model="radio" @on-change="onChangeRadioHandle">
        <template v-for="item of list">
          <Radio :key="item.id" :label="item.id">
            <Card dis-hover :bordered="false">
              <div class="cell-group">
                <div class="cell">
                  <div class="cell-title">用户名</div>
                  <div class="cell-desc">{{ item.name }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">手机号</div>
                  <div class="cell-desc">{{ item.phoneNum }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">部门</div>
                  <div class="cell-desc">{{ item.saasOrganizationVos.map(item => item.name).join('、') }}</div>
                </div>
              </div>
            </Card>
          </Radio>
        </template>
      </RadioGroup>
    </template>
    <template v-else>
      <vxe-table resize auto-resize highlight-hover-row
                 ref="selectUserXTable" row-id="id"
                 :data="list" :loading="loading" :radio-config="radioConfig"
                 @radio-change="({newValue}) => $emit('on-change', newValue)">
        <vxe-column type="radio" width="60" align="center" />
        <vxe-column field="name" title="用户名" />
        <vxe-column field="phoneNum" title="手机号" />
        <vxe-column field="department" title="部门">
          <template #default="{ row }">{{ row.saasOrganizationVos.map(item => item.name).join('、') }}</template>
        </vxe-column>
      </vxe-table>
    </template>
    <div class="ivu-mt ivu-text-center">
      <Page show-sizer show-total show-elevator
            :total="total" :simple="isMobile"
            :page-size="pageSize" :current.sync="pageNum"
            @on-change="onChangePageHandle"
            @on-page-size-change="onChangePageSizeHandle" />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { employeeList } from '@/api/sys'

  export default {
    name: 'SelectUser',
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      visible: {
        handler (value) {
          if (value && !this.list.length) this.getData()
        },
        immediate: true
      },
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      user () {
        return this.$store.state.admin.user.info
      },
      radioConfig () {
        return {
          strict: false,
          trigger: 'row',
          highlight: true
        }
      }
    },
    methods: {
      onResetHandle () {
        this.$refs.form.resetFields()
        this.pageNum = 1
        this.getData()
      },
      onSubmitHandle () {
        this.pageNum = 1
        this.getData()
      },
      onChangePageHandle (pageNum) {
        this.pageNum = pageNum
        this.getData()
      },
      onChangePageSizeHandle (pageSize) {
        this.pageNum = 1
        this.pageSize = pageSize
        this.getData()
      },
      onChangeRadioHandle () {
        this.$emit('on-change', this.list.find(item => item.id === this.radio))
      },
      async getData () {
        try {
          this.loading = true
          const {name} = this.form
          const {companyOrgId: orgId} = this.user
          const {result} = await employeeList({
            name, orgId,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            orderByDesc: '',
            orderByList: '',
          })

          this.list = result.list
          this.total = result.total
        } catch (e) {} finally {
          this.loading = false
        }
      },
    },
    data () {
      return {
        loading: false,
        form: {
          name: ''
        },
        list: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,

        radio: '',
      }
    },
  }
</script>

<style scoped lang="less">
  /deep/ .ivu-form-item {
    margin-bottom: 10px;
  }
  /deep/ .ivu-radio-group {
    display: block;
  }
  /deep/ .ivu-radio-wrapper {
    padding: 16px 0;
    display: block;
    border-bottom: 1px solid #e8eaec !important;
    position: relative;

    .ivu-card {
      padding-left: 30px;

      &-body {
        padding: 0;
      }
    }
  }
  /deep/ .ivu-radio {
    position: absolute;
    z-index: 2;
    left: 0;
  }
</style>
