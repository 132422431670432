import BasicLayout from '@/layouts/basic-layout'

const prefix = 'report-center-check-out-statement-'
export default {
  path: '/report-center/check-out-statement',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {cache: true, title: '退租报表(运营)'},
      component: () => import('@/pages/report-center/check-out-statement/index')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: {title: '退租详情(运营)'},
      component: () => import('@/pages/report-center/check-out-statement/detail-view')
    }
  ]
}
