<template>
  <Breadcrumb class="i-layout-header-breadcrumb" v-if="!isLimit" ref="breadcrumb">
    <BreadcrumbItem v-for="item in items" :key="item.path">
      <i-menu-head-title :item="item" :hide-icon="!showBreadcrumbIcon"/>
    </BreadcrumbItem>
  </Breadcrumb>
</template>
<script>
  import { mapState } from 'vuex';
  import { flattenSiderMenu } from '@/libs/system';
  import iMenuHeadTitle from '../menu-head/title';
  import { on, off } from 'view-design/src/utils/dom';
  import { findComponentUpward, getStyle } from 'view-design/src/utils/assist';
  import { find, throttle } from 'lodash';

  export default {
    name: 'iHeaderBreadcrumb',
    components: { iMenuHeadTitle },
    watch: {
      topItem: {
        handler () {
          this.handleGetWidth();
          this.handleCheckWidth();
        },
        deep: true
      },
      items: {
        handler () {
          this.handleGetWidth();
          this.handleCheckWidth();
        },
        deep: true
      },
      activePath: {
        handler () {
          this.handleGetWidth();
          this.handleCheckWidth();
        },
        deep: true
      }
    },
    computed: {
      ...mapState('admin/layout', ['showBreadcrumbIcon', 'menuCollapse']),
      ...mapState('admin/menu', ['openNames', 'activePath', 'header', 'headerName']),
      ...mapState('admin/account', ['menuList']),
      items () {
        const menuList = flattenSiderMenu(this.menuList, [])

        return this.openNames.map(item => find(menuList, ['path', item]))
      }
    },
    methods: {
      handleCheckWidth () {
        const $header = findComponentUpward(this, 'Header');
        if ($header) {
          const headerWidth = parseInt(getStyle($header.$el, 'width'));
          this.$nextTick(() => {
            this.isLimit = headerWidth - this.maxWidth <= this.breadcrumbWidth;
          });
        }
      },
      handleGetWidth () {
        this.isLimit = false;
        this.$nextTick(() => {
          const $breadcrumb = this.$refs.breadcrumb;
          if ($breadcrumb) {
            this.breadcrumbWidth = parseInt(getStyle($breadcrumb.$el, 'width'));
          }
        });
      }
    },
    mounted () {
      this.handleResize = throttle(this.handleCheckWidth, 100, { leading: false });
      on(window, 'resize', this.handleResize);
      this.handleGetWidth();
      this.handleCheckWidth();
    },
    beforeDestroy () {
      off(window, 'resize', this.handleResize);
    },
    data () {
      return {
        handleResize: () => {},
        isLimit: false,
        maxWidth: 560,
        breadcrumbWidth: 0
      }
    },
  }
</script>
