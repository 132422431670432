import BasicLayout from '@/layouts/basic-layout'

const prefix = 'sys-'
export default [
  {
    path: '/sys',
    name: 'sys',
    redirect: {name: `${prefix}process-center`},
    component: BasicLayout,
    children: [
      {
        path: 'process-center',
        name: `${prefix}process-center`,
        meta: {title: '流程中心'},
        component: () => import('@/pages/sys/process-center')
      },
      {
        path: 'process-node-config',
        name: `${prefix}process-node-config`,
        meta: {title: '流程节点配置'},
        component: () => import('@/pages/sys/process-node-config')
      },
      {
        path: 'dictionary-config',
        name: `${prefix}dictionary-config`,
        meta: {title: '数据字典'},
        component: () => import('@/pages/sys/dictionary-config')
      },
      {
        path: 'organization-manage',
        name: `${prefix}organization-manage`,
        meta: {cache: true, title: '组织管理'},
        component: () => import('@/pages/sys/organization-manage')
      },
      {
        path: 'organization-menu-manage',
        name: `${prefix}organization-menu-manage`,
        meta: {title: '组织菜单管理'},
        component: () => import('@/pages/sys/organization-menu-manage')
      },
      {
        path: 'user',
        name: `${prefix}user`,
        meta: {title: '员工管理'},
        component: () => import('@/pages/sys/user')
      },
      {
        path: 'platform-user',
        name: `${prefix}platform-user`,
        meta: {title: '用户管理'},
        component: () => import('@/pages/sys/platform-user')
      },
      {
        path: 'menu',
        name: `${prefix}menu`,
        meta: {title: '菜单管理'},
        component: () => import('@/pages/sys/menu')
      },
      {
        path: 'role',
        name: `${prefix}role`,
        meta: {title: '角色管理'},
        component: () => import('@/pages/sys/role')
      },
      {
        path: 'station',
        name: `${prefix}station`,
        meta: {title: '岗位管理'},
        component: () => import('@/pages/sys/station')
      }
    ]
  }
]
