import BasicLayout from '@/layouts/basic-layout'

const prefix = 'sys-employee-invitation-'
export default [
  {
    path: '/sys/employee-invitation',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {cache: true, title: '员工邀请列表'},
        component: () => import('@/pages/sys/employee-invitation/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '员工邀请编辑'},
        component: () => import('@/pages/sys/employee-invitation/detail-edit')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '员工邀请详情'},
        component: () => import('@/pages/sys/employee-invitation/detail-view')
      }
    ]
  }
]
