/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 实收分页查询
export const getFinCollectionList = data => {
  return request('/operation/v1/finCollection/page').post('postNormal', data)
}
// 实收根据id查询信息
export const finCollectionQueryByPrimaryKey = data => {
  return request('/operation/v1/finCollection/id/queryByPrimaryKey').get('locationPath', data)
}
// 统计实收账单金额
export const countCollection = data => {
  return request('/operation/v1/finCollection/countCollection').post('postNormal', data)
}
// 费用明细查询
export const queryCollectionItemByPage = data => {
  return request('/operation/v1/finCollection/queryCollectionItemByPage').post('postNormal', data)
}
// 导出实收账单信息
export const exportFinCollection = data => {
  return request('/operation/v1/finCollection/exportFinCollection').post('postBlobQuery', data)
}
// 生成收据
export const createReceipt = data => {
  return request('/operation/v1/finCollection/id/createReceipt').get('locationPath', data)
}