import { Message, Notice } from 'view-design'
import axios from 'axios'

import store from '@/store'
import storage from '@/libs/vendors/storage'
import Setting from '@/setting'
import router from '@/router'
import {jumpSSOLogin} from '@/libs/util'

// 记录和显示错误
const errorLog = (err) => {
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') console.error(err)
  // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
  if (Setting.errorModalType === 'Message') {
    Message.error({
      content: err.message,
      duration: Setting.modalDuration
    });
  } else if (Setting.errorModalType === 'Notice') {
    Notice.error({
      title: '提示',
      desc: err.message,
      duration: Setting.modalDuration
    })
  }
}

// 创建一个 axios 实例
const service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 360000
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = storage.get('token', '')
    const user = storage.get('user', '')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
      config.headers['companyOrgId'] = `${user.companyOrgId}`
    }
    return config
  },
  error => Promise.reject(error)
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    const {data, config} = response
    const {code, message} = data
    // 根据 code 进行判断
    if ([undefined, 0, 200].includes(code)) return config.entire !== true ? data : response
    switch (code) {
      case 4003:
        router.replace({ path: '/403' })
        break
      case 4004:
        router.replace({ path: '/404' })
        break
      default:
        if ([504, 510].includes(code) || code === 500 && message.includes('登入已过期请重新登入')) {
          if (document.querySelectorAll('.ivu-message').length) return
          // 清空用户信息
          store.dispatch('admin/user/set', {})
          jumpSSOLogin({logout: true})
        }
    }
    if (config.message !== false) Message.error({content: message, duration: Setting.modalDuration})
    return Promise.reject(response)
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break;
        case 401: error.message = '未授权，请登录'; break;
        case 403: error.message = '拒绝访问'; break;
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break;
        case 408: error.message = '请求超时'; break;
        case 500: error.message = '服务器内部错误'; break;
        case 501: error.message = '服务未实现'; break;
        case 502: error.message = '网关错误'; break;
        case 503: error.message = '服务不可用'; break;
        case 504: error.message = '网关超时'; break;
        case 505: error.message = 'HTTP版本不受支持'; break;
        default: break;
      }
    }
    errorLog(error);
    return Promise.reject(error);
  }
);

export default service;
