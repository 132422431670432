/*
 * @Author: co
 * @Date: 2021-09-28 10:48:07
 */
import util from '@/libs/util';
import router from '@/router';
import { getConSupplementalClauseList } from '@/api/storage-manage/supplementary-provisions';

import { Modal } from 'view-design';

import {
  cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
  namespaced: true,
  state: {
    // 初始化信息
    CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
    //查询接口返回的数据
    CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
    Entities: cloneObj(RespCommonDef.Entities),
    //查询条件
    AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
  },
  actions: {
    getConSupplementalClauseList({
      state
    }) {
      return new Promise((resolve, reject) => {
        getConSupplementalClauseList(state.AdvanceQuery.entity)
          .then(async res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
  }
};
