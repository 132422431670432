


export default {
  namespaced: true,
  state: {

    // 查询条件
    params: {
      pageNum: 1,
      pageSize: 10,
      tenantNameOrMobile: null,
      tenantType: null,
      tenantContractStatus: null,
    },
  },
  getters: {

  },
  // 持久缓存
  mutations: {

    setParams(state) {
      state.params.pageNum = 1
      state.params.pageSize = 10
      state.params.tenantNameOrMobile = null
      state.params.tenantType = null
      state.params.tenantContractStatus = null
    }
  },

  actions: {



  }

}
