import request from '@/plugins/request/http'
// 应付分页查询
export const getFinPayablesList = data => {
  return request('/operation/v1/finPayables/page').post('postNormal', data)
}
// 应付根据id查询信息
export const finPayablesQueryByPrimaryKey = data => {
  return request('/operation/v1/finPayables/id/queryByPrimaryKey').get('locationPath', data)
}
// 增加抵扣项
export const finPayablesItemDeduction = data => {
  return request('/operation/v1/finPayablesItemDeduction').post('postNormal', data)
}
// 查询抵扣详情
export const queryDeductionInfoById = data => {
  return request('/operation/v1/finPayablesItemDeduction/id/queryDeductionInfoById').get('locationPath', data)
}
// 查询抵扣申请详情
export const queryDeductionApplyInfoById = data => {
  return request('/operation/v1/finPayablesItemDeduction/queryDeductionApplyInfoById').get('normal', data)
}
// 查询付款申请详情
export const queryPayablesApplyInfoById = data => {
  return request('/operation/v1/finPayablesApply/queryPayablesApplyInfoById').get('normal', data)
}
// 查询付款情况（业主实付）
export const queryFinEvidenceItemList = data => {
  return request('/operation/v1/finPayables/Id/queryFinEvidenceItemList').get('locationPath', data)
}
// 统计应付账单金额
export const countPayables = data => {
  return request('/operation/v1/finPayables/countPayables').post('postNormal', data)
}
// 费用明细查询
export const queryPayablesItemByPage = data => {
  return request('/operation/v1/finPayables/queryPayablesItemByPage').post('postNormal', data)
}
// 导出应付账单信息
export const exportFinPayables = data => {
  return request('/operation/v1/finPayables/exportFinPayables').post('postBlobQuery', data)
}
// 查询付款申请业主收款信息
export const finPayablesApplyAddInit = data => {
  return request('/operation/v1/finPayablesApply/addInit').get('normal', data)
}

// 批量发起付款申请初始化
export const addInitBatch = data => {
  return request('/operation/v1/finPayablesBatchApply/addInitBatch').post('postNormal', data)
}
// 查询付款申请详情
export const queryPayablesBatchApplyInfoById = data => {
  return request('/operation/v1/finPayablesBatchApply/queryPayablesBatchApplyInfoById').get('normal', data)
}
// 历史付款申请
export const queryByPayablesId = data => {
  return request('/operation/v1/finPayablesBatchApply/queryByPayablesId').get('normal', data)
}

export const exportPayablesBatchApplyInfoById = params => request('/operation/v1/finPayablesBatchApply/exportPayablesBatchApplyInfoById').get('queryBlob', params)
