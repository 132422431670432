import RouteView from '@/layouts/basic-layout/route-view'
import BasicLayout from '@/layouts/basic-layout'

export default [
  {
    path: '/dashboard',
    redirect: {name: 'dashboard-console'},
    component: BasicLayout,
    children: [
      {
        path: 'console',
        name: 'dashboard-console',
        meta: {title: '主控台', closable: false, cache: true},
        component: () => import('@/pages/dashboard/console')
      },
      {
        path: 'data-board',
        name: 'dashboard-data-board',
        meta: {title: '数据看板', cache: true},
        component: () => import('@/pages/dashboard/data-board')
      },

      {path: 'process-center', redirect: {name: 'process-center-index'}},
      {
        path: 'process-center/index',
        name: 'process-center-index',
        meta: {title: '审批中心', cache: true},
        component: () => import('@/pages/dashboard/process-center/index')
      },
      {
        path: 'process-center/detail-create',
        name: 'process-center-create',
        auth: ['hidden'],
        meta: {title: '流程中心新增'},
        component: () => import('@/pages/dashboard/process-center/detail-create')
      },
      {
        path: 'process-center/detail-edit/:id',
        name: 'process-center-edit',
        auth: ['hidden'],
        meta: {title: '流程中心编辑'},
        component: () => import('@/pages/dashboard/process-center/detail-edit')
      },
      {
        path: 'process-center/detail-view/:id',
        name: 'process-center-view',
        auth: ['hidden'],
        meta: {title: '流程中心详情'},
        component: () => import('@/pages/dashboard/process-center/detail-view')
      },

      {
        path: 'demand-manage',
        component: RouteView,
        children: [
          {
            path: '',
            name: 'dashboard-demand-manage-index',
            meta: { title: '需求管理'},
            component: () => import('@/pages/dashboard/demand-manage/index')
          },
          {
            path: 'detail-edit/:id',
            name: 'dashboard-demand-manage-edit',
            auth: ['hidden'],
            meta: {title: '需求编辑'},
            component: () => import('@/pages/dashboard/demand-manage/detail-edit')
          },
          {
            path: 'detail-view/:id',
            name: 'dashboard-demand-manage-view',
            auth: ['hidden'],
            meta: {title: '需求详情'},
            component: () => import('@/pages/dashboard/demand-manage/detail-view')
          }
        ]
      },
      {
        path: 'data-change-apply',
        redirect: {name: 'data-change-apply-index'},
        component: RouteView,
        children: [
          {
            path: 'index',
            name: 'data-change-apply-index',
            meta: {title: '数据变更申请'},
            component: () => import('@/pages/dashboard/data-change-apply/index')
          },
          {
            path: 'detail-edit/:id',
            name: 'data-change-apply-edit',
            auth: ['hidden'],
            meta: {title: '数据变更编辑'},
            component: () => import('@/pages/dashboard/data-change-apply/detail-edit')
          },
          {
            path: 'detail-view/:id',
            name: 'data-change-apply-view',
            auth: ['hidden'],
            meta: {title: '数据变更详情'},
            component: () => import('@/pages/dashboard/data-change-apply/detail-view')
          }
        ]
      },
      {
        path: 'reminder-manage',
        redirect: {name: 'reminder-manage-index'},
        component: RouteView,
        children: [
          {
            path: 'index',
            name: 'reminder-manage-index',
            meta: {title: '待办提醒'},
            component: () => import('@/pages/dashboard/reminder-manage/index')
          }
        ]
      }
    ]
  }
]
