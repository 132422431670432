import BasicLayout from '@/layouts/basic-layout'

const prefix = 'storage-manage-project-property-manage-'

export default [
  {
    path: '/storage-manage/project-property-manage',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {title: '项目物业管理'},
        component: () => import('@/pages/storage-manage/project-property-manage/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '项目物业编辑'},
        component: () => import('@/pages/storage-manage/project-property-manage/detail-edit')
      },
      {
        path: 'detail-create',
        name: `${prefix}create`,
        auth: ['hidden'],
        meta: {title: '项目物业新增'},
        component: () => import('@/pages/storage-manage/project-property-manage/detail-create')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '项目物业详情'},
        component: () => import('@/pages/storage-manage/project-property-manage/detail-view')
      }
    ]
  }
]
