<template>
  <div>
    <template v-if="isMobile">
      <JFDrawer style="position: relative; z-index: 1002;" :title="title" v-model="visible">
        <Input clearable type="text" style="width: 200px;" placeholder="请输入姓名，快速搜索"
               v-model="name" />
        <Divider style="margin: 16px 0 0 0;" />
        <RadioGroup v-model="radio">
          <template v-for="item of list">
            <Radio :key="item.id" :label="item.id">
              <Card dis-hover :bordered="false">
                <div class="cell-group">
                  <div class="cell">
                    <div class="cell-title">姓名</div>
                    <div class="cell-desc">{{ item.name }}</div>
                  </div>
                  <div class="cell">
                    <div class="cell-title">手机号</div>
                    <div class="cell-desc">{{ item.phoneNum }}</div>
                  </div>
                  <div class="cell">
                    <div class="cell-title">部门</div>
                    <div class="cell-desc">
                      {{ item.saasOrganizationVos.map(item => item.name).join('、') }}
                    </div>
                  </div>
                </div>
              </Card>
            </Radio>
          </template>
        </RadioGroup>
        <template slot="footer">
          <Button @click="visible = false">取消</Button>
          <Button type="primary" @click="getSelectEvent">确认</Button>
        </template>
      </JFDrawer>
    </template>
    <template v-else>
      <Modal lock-scroll width="800" :title="title"
             :styles="{top: '20px'}"
             v-model="visible">
        <Input clearable type="text" style="width: 200px;" placeholder="请输入姓名，快速搜索"
               v-model="name" />
        <vxe-table resize auto-resize ref="table" class="ivu-mt-8"
                   row-id="id" max-height="400"
                   :data="list" :radio-config="radioConfig">
          <vxe-column type="radio" width="50" align="center" />
          <vxe-column field="name" title="姓名" />
          <vxe-column field="phoneNum" title="手机号" />
          <vxe-column field="apartment" title="部门">
            <template #default="{ row }">{{ row.saasOrganizationVos.map(item => item.name).join('、') }}</template>
          </vxe-column>
        </vxe-table>
        <template slot="footer">
          <Button @click="visible = false">取消</Button>
          <Button type="primary" @click="getSelectEvent">确认</Button>
        </template>
      </Modal>
    </template>
  </div>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    name: 'SelectApprover',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      defaultSelecteRow: {
        type: String,
        default: '',
      },
      nextNodeApproverList: {
        type: Array,
        default: () => ([]),
      },
      roleId: {
        type: String,
        default: '',
      },
    },
    watch: {
      defaultSelecteRow: {
        handler (value) {
          if (!value) return
          this.radio = value
          this.selectRow = this.list.find(item => item.id === value)
        },
        immediate: true,
      },
      visible (value) {
        if (!value) setTimeout(() => this.name = '', 250)
        if (value && !this.isMobile) this.$refs.table.setRadioRow(this.selectRow)
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      list () {
        const {name, nextNodeApproverList} = this

        if (name) return nextNodeApproverList
          .filter(item => item.name.includes(name))
        return nextNodeApproverList
      },
      title () {
        return '选择人员'
      },
      radioConfig () {
        return {
          strict: false,
          trigger: 'row',
          highlight: true
        }
      }
    },
    methods: {
      getSelectEvent() {
        this.visible = false
        this.selectRow = this.isMobile
          ? this.list.find(item => item.id === this.radio)
          : this.$refs.table.getRadioRecord()
        this.$emit('getApproverFn', this.selectRow)
      },
    },
    data () {
      return {
        name: '',
        radio: '',
        visible: false,
        selectRow: {},
      }
    },
  }
</script>
<style scoped lang="less">
  /deep/ .JFDrawer {

    .cell {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
      &-title {
        width: 60px;
      }
      &-desc {
        flex: 1;
      }
    }
    .drawer-footer-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > * {
        width: 100%;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .ivu-card-body {
      padding: 0 0 0 30px;
    }
  }
  /deep/ .ivu-radio-group {
    display: block;
  }
  /deep/ .ivu-radio-wrapper {
    padding: 16px 0;
    display: block;
    border-bottom: 1px solid #e8eaec !important;
    position: relative;
  }
  /deep/ .ivu-radio {
    position: absolute;
    z-index: 2;
    left: 0;
  }
</style>
