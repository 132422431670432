import BasicLayout from '@/layouts/basic-layout'

const prefix = 'fin-payable-'
export default {
  path: '/financial-manage/fin-payable',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '应付管理-承'},
      component: () => import('@/pages/financial-manage/fin-payable/index')
    },
    {
      path: 'detail-create',
      name: `${prefix}create`,
      auth: ['hidden'],
      meta: {title: '应付申请新增-承'},
      component: () => import('@/pages/financial-manage/fin-payable/detail-create')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '应付申请编辑-承'},
      component: () => import('@/pages/financial-manage/fin-payable/detail-edit')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: {title: '应付申请详情-承'},
      component: () => import('@/pages/financial-manage/fin-payable/detail-view')
    },
    {
      path: 'deduction-apply-detail-view/:id',
      name: `${prefix}deduction-apply-detail-view`,
      auth: ['hidden'],
      meta: {title: '抵扣申请详情-承'},
      component: () => import('@/pages/financial-manage/fin-payable/deduction-apply-detail-view')
    },
    {
      path: 'payable-apply-detail-view/:id',
      name: `${prefix}payable-apply-detail-view`,
      auth: ['hidden'],
      meta: {title: '付款申请详情-承'},
      component: () => import('@/pages/financial-manage/fin-payable/payable-apply-detail-view')
    },
    {
      path: 'payment-status-list/:id',
      name: `${prefix}payment-status-list`,
      auth: ['hidden'],
      meta: {title: '付款情况-承'},
      component: () => import('@/pages/financial-manage/fin-payable/payment-status-list')
    },
    {
      path: 'history-payable-apply-list/:id',
      name: `${prefix}history-payable-apply-list`,
      auth: ['hidden'],
      meta: {title: '历史付款申请'},
      component: () => import('@/pages/financial-manage/fin-payable/history-payable-apply-list')
    }
  ]
}
