import BasicLayout from '@/layouts/basic-layout'

const prefix = 'storage-manage-sign-change-'
export default [
  {
    path: '/storage-manage/sign-change',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {cache: true, title: '换签历史'},
        component: () => import('@/pages/storage-manage/sign-change/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '换签申请编辑'},
        component: () => import('@/pages/storage-manage/sign-change/detail-edit')
      },

      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '换签申请详情'},
        component: () => import('@/pages/storage-manage/sign-change/detail-view')
      }
    ]
  }
]
