/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 业主管理
export const conSubjectManage = data => {
  return request('/operation/v1/conSubjectManage/page').post('postNormal', data)
}
// 查看业主详情
export const viewConLesseeSubject = data => {
  return request('/operation/v1/conSubjectManage/id/queryByPrimaryKey').get('locationPath', data)
}
// 查看物业详情
export const viewProjectProperty = data => {
  return request('/operation/v1/conSubjectManage/id/queryProjectPropertyByPrimaryKey').get('locationPath', data)
}
// 添加业主信息
export const conSubjectManageAdd = data => {
  return request('/operation/v1/conSubjectManage/add').post('postNormal', data)
}