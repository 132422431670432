/*
 * @Author: co
 * @Date: 2021-09-27 16:05:32
 */
import BasicLayout from '@/layouts/basic-layout'
const prefix = 'report-center-unexpired-lease-contract-statement-'
export default {
  path: '/report-center/unexpired-lease-contract-statement',
  redirect: { name: `${prefix}index` },
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: { cache: true, title: '未到期有效出租合同(运营)' },
      component: () => import('@/pages/report-center/unexpired-lease-contract-statement/index')
    },
  ]
}
