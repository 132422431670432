import BasicLayout from '@/layouts/basic-layout'

const prefix = 'storage-manage-contract-archiving-'
export default [
  {
    path: '/storage-manage/contract-archiving',
    component: BasicLayout,
    children: [
      {
        path: 'detail-create',
        name: `${prefix}create`,
        auth: ['hidden'],
        meta: { title: '业主合同归档新增'},
        component: () => import('@/pages/storage-manage/contract-archiving/detail-create')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '业主合同归档编辑'},
        component: () => import('@/pages/storage-manage/contract-archiving/detail-edit')
      },
      {
        path: 'detail-add-edit/:id',
        name: `${prefix}add-edit`,
        auth: ['hidden'],
        meta: {title: '业主合同归档编辑'},
        component: () => import('@/pages/storage-manage/contract-archiving/detail-add-edit')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '业主合同归档详情'},
        component: () => import('@/pages/storage-manage/contract-archiving/detail-view')
      }
    ]
  }
]
