<!--
 * @Author: co
 * @Date: 2021-09-06 15:14:18
-->
<template>
  <div>
    <div
      class="i-layout-sider-logo"
      :class="{ 'i-layout-sider-logo_dark_gf': siderTheme === 'dark' }"
    >
      <transition name="fade-quick">
        <i-link to="/" v-show="!hideLogo">
          <img src="@/assets/images/logo_small_gf.png" v-if="menuCollapse"/>
          <!-- <img src="@/assets/images/logo.png" v-else-if="siderTheme === 'light'" /> -->
          <template v-else-if="siderTheme === 'light'">
            <img src="@/assets/images/logo_small_gf.png" style="margin-left:-15px;"/>
            <span class="logo_title_black">{{ sysTitle }}</span>
          </template>
          <template v-else>
            <img src="@/assets/images/logo_small_gf.png" style="margin-left:-15px;"/>
            <span class="logo_title">{{ sysTitle }}</span>
          </template>
        </i-link>
      </transition>
    </div>
    <Menu
      ref="menu"
      class="i-layout-menu-side i-scrollbar-hide"
      :theme="siderTheme"
      :accordion="menuAccordion"
      :active-name="activePath"
      :open-names="openNames"
      width="auto"
    >
      <template v-if="!menuCollapse" v-for="(item, index) in filterSider">
        <i-menu-side-item
          v-if="item.children === undefined || !item.children.length"
          :menu="item"
          :key="index"
        />
        <i-menu-side-submenu v-else :menu="item" :key="index"/>
      </template>
      <template v-else>
        <Tooltip
          :content="item.title"
          placement="right"
          v-if="item.children === undefined || !item.children.length"
          :key="index"
        >
          <i-menu-side-item :menu="item" hide-title/>
        </Tooltip>
        <i-menu-side-collapse v-else :menu="item" :key="index" top-level/>
      </template>
    </Menu>
  </div>
</template>
<script>
  import iMenuSideItem from './menu-item'
  import iMenuSideSubmenu from './submenu'
  import iMenuSideCollapse from './menu-collapse'
  // 配置
  import Setting from '@/setting'
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'iMenuSide',
    components: { iMenuSideItem, iMenuSideSubmenu, iMenuSideCollapse },
    props: {
      hideLogo: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        sysTitle: Setting.titleSuffix,
      }
    },
    computed: {
      ...mapState('admin/layout', [
        'siderTheme',
        'menuAccordion',
        'menuCollapse',
      ]),
      ...mapState('admin/menu', ['activePath', 'openNames']),
      ...mapGetters('admin/menu', ['filterSider']),
    },
    watch: {
      $route: {
        handler () {
          this.handleUpdateMenuState()
        },
        immediate: true,
      },
      // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
      menuCollapse () {
        this.handleUpdateMenuState()
      },
    },
    methods: {
      handleUpdateMenuState () {
        this.$nextTick(() => {
          if (this.$refs.menu) {
            this.$refs.menu.updateActiveName()
            if (this.menuAccordion) this.$refs.menu.updateOpened()
          }
        })
      },
    },
  }
</script>
<style lang="less" scoped>
.logo_title {
  font-size: 14px;
  color: #ffffff;
}

.logo_title_black {
  font-size: 14px;
  color: #000000;
}
</style>
