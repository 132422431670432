import BasicLayout from '@/layouts/basic-layout'

const prefix = 'website-manage-'
export default [
  {
    path: '/website-manage',
    redirect: {name: `${prefix}platform-info-approval`},
    component: BasicLayout,
    children: [
      {
        path: 'platform-info-approval/index',
        name: `${prefix}platform-info-approval`,
        meta: {title: '平台信息审批'},
        component: () => import('@/pages/website-manage/platform-info-approval/index')
      },
      {
        path: 'platform-info-approval/store-detail-view/:id',
        name: `${prefix}platform-info-approval-store-detail-view`,
        meta: {title: '门店详情'},
        component: () => import('@/pages/website-manage/platform-info-approval/store-detail-view')
      },
      {
        path: 'platform-info-approval/house-layout-detail-view/:id',
        name: `${prefix}platform-info-approval-house-layout-detail-view`,
        meta: {title: '房型详情'},
        component: () => import('@/pages/website-manage/platform-info-approval/house-layout-detail-view')
      },
      {
        path: 'news-manage/index',
        name: `${prefix}news-manage-index`,
        meta: {title: '官网文章管理'},
        component: () => import('@/pages/website-manage/news-manage/index')
      },
      {
        path: 'applets/home-manage/index',
        name: `${prefix}applets-home-manage-index`,
        meta: {title: '小程序首页管理'},
        component: () => import('@/pages/website-manage/applets/home-manage/index')
      }
    ]
  }
]
