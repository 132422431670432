import Vue from 'vue'
import VueRouter from 'vue-router'
import {cloneDeep} from 'lodash'
import {LoadingBar} from 'view-design'

import store from '@/store/index'
import storage from '@/libs/vendors/storage'
import Setting from '@/setting'
import { title, jumpSSOLogin } from '@/libs/util'
import routes from './routes'

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const createRouter = () => {
  const router = new VueRouter({
    mode: Setting.routerMode,
    routes: cloneDeep(routes),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition && to.meta.cache) return savedPosition
      return {x: 0, y: 1}
    }
  })

  router.beforeEach(async (to, from, next) => {
    if (Setting.showProgressBar && to.meta.progressBar !== false) LoadingBar.start()
    if (to.query.accessToken) {
      // 没有权限
      if (!['1', '3'].includes(to.query.userType)) return next({name: '403'})
      await store.dispatch('admin/account/login', to.query)
    }
    // 判断是否需要登录才可以进入
    if (to.meta.auth !== false) {
      if (!storage.has('user')) return jumpSSOLogin({to})
      if (!store.getters['admin/user/token'] && storage.has('user')) await store.dispatch('admin/account/autoLogin', storage.get('user', {}))
      // 是否有权限访问页面
      // if (to.meta.auth !== false && !permissionGuard(to, store.state.admin.account.menuList)) return next({path: '/403'})
      // 多页控制 打开新的页面
      await store.dispatch('admin/page/open', to)
    }
    // 更改标题
    title({title: to.meta.title})
    next()
  })
  router.afterEach(to => {
    if (Setting.showProgressBar && to.meta.progressBar !== false) LoadingBar.finish()
  })
  return router
}

export const router = createRouter()
export default router
