import BasicLayout from '@/layouts/basic-layout'

const prefix = 'accounts-receipt-transfer-statement-'
export default {
  path: '/report-center/accounts-receipt-transfer-statement',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {cache: true, title: '预收转出报表'},
      component: () => import('@/pages/report-center/accounts-receipt-transfer-statement/index')
    },
  ]
}
