import BasicLayout from '@/layouts/basic-layout';

const prefix = 'setting-'
export default [
  {
    path: '/setting',
    name: 'setting',
    redirect: {name: `${prefix}user`},
    component: BasicLayout,
    children: [
      {
        path: 'user',
        name: `${prefix}user`,
        meta: {title: '个人中心'},
        component: () => import('@/pages/setting/user')
      },
      {
        path: 'account',
        name: `${prefix}account`,
        meta: {title: '个人设置'},
        component: () => import('@/pages/setting/account')
      }
    ]
  }
]
