import BasicLayout from '@/layouts/basic-layout'

const prefix = 'claim-statement-'
export default {
  path: '/financial-manage/claim-statement',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '认领单管理'},
      component: () => import('@/pages/financial-manage/claim-statement/index')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '认领单编辑'},
      component: () => import('@/pages/financial-manage/claim-statement/detail-edit')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}detail-view`,
      auth: ['hidden'],
      meta: {title: '认领单详情'},
      component: () => import('@/pages/financial-manage/claim-statement/detail-view')
    },
    {
      path: 'components/cancel-claim-view/:id',
      name: `${prefix}cancel-claim-view`,
      auth: ['hidden'],
      meta: {title: '取消认领申请详情'},
      component: () => import('@/pages/financial-manage/claim-statement/components/cancel-claim-view')
    }
  ]
}
