/*
 * @Author: co
 * @Date: 2021-09-28 10:48:07
 */
import util from '@/libs/util';
import router from '@/router';
import { Modal } from 'view-design';

import {
  cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
  namespaced: true,
  state: {
    Rights: []
  },
  mutations: {
    setRights: (state, data) => {
      state.Rights = data
    },
  },
  actions: {
    setRights: ({ commit }, data) => {
      commit('setRights', data)
    },
  }
};
