/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
import service from '@/plugins/request'

// 房源列表（在租，已签约）
export const rentedHouseList = data => {
  return request('/operation/v1/house/rentedHouseList').post('postNormal', data)
}

// 房源列表（门店房源）
export const queryStoreHouseList = data => {
  return request('/operation/v1/house/queryStoreHouseList').post('postNormal', data)
}
// 根据id查询房源编辑详情（门店房源）
export const queryEditHouseByPrimarykey = data => {
  return request('/operation/v1/house/id/queryEditHouseByPrimarykey').get('locationPath', data)
}
// 更新房源信息（门店房源）
export const houseEdit = data => {
  return request('/operation/v1/house/id/updateHouse').post('postLocationPath', data)
}
// 根据id查询房源查看详情（门店房源）
export const queryViewHouseByPrimarykey = data => {
  return request('/operation/v1/house/id/queryViewHouseByPrimarykey').get('locationPath', data)
}
// 查看房源物品配置详情
export const queryHouseGoods = data => {
  return request('/operation/v1/house/id/queryHouseGoods').get('locationPath', data)
}
// 保存房源物品配置
export const saveHouseGoods = data => {
  return request('/operation/v1/house/saveHouseGoods').post('postNormal', data)
}
// 门店房型参数查询
export const houseTypeQueryByParam = data => {
  return request('/operation/v1/houseType/queryByParam').post('postNormal', data)
}
// 房源物品配置编辑初始化（门店房源）
export const editHouseGoodsInit = data => {
  return request('/operation/v1/house/id/editHouseGoodsInit').get('locationPath', data)
}
// [房源定价]历史分页查询
export const houseSalesPriceHistory = data => {
  return request('/operation/v1/houseSalesPrice/page').post('postNormal', data)
}
// [房源定价]添加定价
export const addHouseSalesPrice = data => {
  return request('/operation/v1/houseSalesPrice/addHouseSalesPrice').post('postNormal', data)
}
// [房源定价]根据id查询信息
export const houseSalesPriceDetail = data => {
  return request('/operation/v1/houseSalesPrice/id/queryByPrimaryKey').get('locationPath', data)
}
// 导出定价房源
export const exportHouseSalesPrice = data => {
  return request('/operation/v1/houseSalesPrice/exportHouseSalesPrice').post('postBlobQuery', data)
}
// 导出房源
export const exportHouse = data => {
  return request('/operation/v1/house/exportHouse').post('postBlobQuery', data)
}
// 导出门店房源(配置户型)
export const exportStoreHouse = data => {
  return request('/operation/v1/house/exportStoreHouse').post('postBlobQuery', data)
}

// 导出门店房源模板(小B)
export const exportStoreHouseB = params => service.post('/operation/v1/store/exportStoreHouse', params, {responseType: 'blob', entire: true})

// 导入生成门店房源（小B）
export const generateStoreHouse = data => {
  return request('/operation/v1/store/generateStoreHouse').post('postNormal', data)
}
// 查询智能表读数
export const queryIntelligentDevice = houseId => service.get(`/operation/v1/house/${houseId}/queryIntelligentDevice`, {
  message: false
})
// [房源定价]根据门店id查询快过期的房源信息
export const storeQuickExpiration = data => {
  return request('/operation/v1/houseSalesPrice/storeId/storeQuickExpiration').get('locationPath', data)
}
// 导出门店快过期的房源信息
export const exportStoreQuickExpiration = data => {
  return request('/operation/v1/houseSalesPrice/exportStoreQuickExpiration').get('queryBlob', data)
}
// 门店人员查询
export const houseButlerList = data => {
  return request('/operation/v1/storePersonnel/page').post('postNormal', data)
}
// 批量更新同一个门店房源的管家
export const batchUpdateHouseManager = data => {
  return request('/operation/v1/store/batchUpdateHouseManager').get('normal', data)
}
// 导入调整定价导入模板
export const exportStoreHouseSalesPrice = (storeId) => request(`/operation/v1/houseSalesPrice/${storeId}/exportStoreHouseSalesPrice`).get('blob')


// 专属管家绑定的房源
export const queryHouseManagerList = () => request('/operation/v1/house/queryHouseManagerList').get('normal')
export const queryStoreHouseManagerList = (data) => request('/operation/v1/house/storeId/queryStoreHouseManagerList').get('locationPath', data)


