import BasicLayout from '@/layouts/basic-layout'

const prefix = 'rent-fin-settlement-doc-'
export default {
  path: '/financial-manage/rent/fin-settlement-doc',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '结算单管理-租'},
      component: () => import('@/pages/financial-manage/rent/fin-settlement-doc/index')
    },
    {
      path: 'detail-create',
      name: `${prefix}create`,
      auth: ['hidden'],
      meta: {title: '结算单申请新增-租'},
      component: () => import('@/pages/financial-manage/rent/fin-settlement-doc/detail-create')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '结算单申请编辑-租'},
      component: () => import('@/pages/financial-manage/rent/fin-settlement-doc/detail-edit')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: {title: '业主合同收付款情况详情-租'},
      component: () => import('@/pages/financial-manage/rent/fin-settlement-doc/detail-view')
    }
  ]
}
