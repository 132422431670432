import BasicLayout from '@/layouts/basic-layout'

const prefix = 'accounts-cost-fee-statement-'
export default {
  path: '/report-center/accounts-cost-fee-statement',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '成本费用报表'},
      component: () => import('@/pages/report-center/accounts-cost-fee-statement/index')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '成本费用报表编辑'},
      component: () => import('@/pages/report-center/accounts-cost-fee-statement/detail-edit')
    },

    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: {title: '成本费用报表详情'},
      component: () => import('@/pages/report-center/accounts-cost-fee-statement/detail-view')
    }
  ]
}
