<template>
  <JFDrawer style="position: relative; z-index: 1002;"
            :closable="!isSubmit" :mask-closable="!isSubmit"
            v-model="visible">
    <div slot="header" class="ivu-drawer-header-inner">
      审批操作<template v-if="nodeTip"><div class="nodeTip">（{{ nodeTip }}）</div></template>
    </div>

    <Form ref="form" :model="form" :rules="rules">
      <FormItem prop="processState">
        <RadioGroup v-model="form.processState">
          <Radio label="1">审批通过</Radio>
          <Radio label="0">审批不通过</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem prop="auditBrief">
        <Input type="textarea" placeholder="请输入审核意见"
               :autosize="{minRows: 4, maxRows: 8}"
               v-model="form.auditBrief" />
      </FormItem>
    </Form>
    <template v-if="form.processState === '1'">
      <!-- 环节列表 -->
      <ApproverNode ref="approverNode"
                    :document-id="detail.documentId" :next-node-vo="nextNodeVo"
                    :initiator-id="detail.initiatorId" :initiator-name="detail.initiatorName"
                    :next-item-detail-vo="nextItemDetailVo"
                    :next-node-approver-list="nextNodeApproverList"
                    v-if="!isNull(detail.nextNodeVo)" />

      <!-- 财务管理 / 承租业务账单 / 应付管理 / 付款申请 -->
      <ProcessPayableApply ref="processPayableApply"
                           :ext-params="detail.extParamVos"
                           v-if="pageCode === 'FinPayablesBatchApplyServiceImpl'" />
      <!-- 节点参数 -->
      <!--        <ProcessParams ref="processParams"
                             :ext-params="detail.extParamVos"
                             v-if="(isArray(detail.extParamVos) && detail.extParamVos.length)" />-->
    </template>
    <Card dis-hover title="审批详情" :bordered="false">
      <ProcessSteps :list="detail.prevDetailVos" />
    </Card>

    <template slot="footer">
      <Button type="primary"
              :long="isMobile" :loading="isSubmit"
              @click="onSubmitHandle">提交</Button>
    </template>
  </JFDrawer>
</template>
<script>
  import { mapState } from 'vuex'
  import { get, isArray, isNull } from 'lodash'
  import { passAudit, rejectAudit } from '@api/process-center'
  import ApproverNode from './components/approver-node'
  import ProcessSteps from './components/process-steps'
  import ProcessPayableApply from './components/process-payable-apply'

  export default {
    name: 'ProcessOperate',
    inheritAttrs: false,
    components: {
      ApproverNode, ProcessPayableApply,
      ProcessSteps
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      detail: {
        type: Object,
        default: () => ({})
      },
      nextNodeApproverList: {
        type: Array,
        default: () => ([])
      }
    },
    watch: {
      'form.processState': {
        handler (value) {
          this.form.auditBrief = `${value === '0' ? '不' : ''}同意`
        },
        immediate: true
      },
      visible (value) {
        if (value) this.form.processState = '1'
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      ...mapState('processCenter/public', ['commonAttachmentVoList']),
      user() {
        return this.$store.state.admin.user.info
      },
      pageCode () {
        return this.$route.query.pageCode
      },
      nodeTip () {
        return get(this.detail, 'currentDetailVo.nodeTip')
      },
      nextNodeVo () {
        return get(this.detail, 'nextNodeVo', {})
      },
      nextItemDetailVo () {
        return get(this.detail, 'nextItemDetailVo', {})
      }
    },
    methods: {
      async onSubmitHandle () {
        try {
          let processParams = {}
          const {id: documentId} = this.$route.params
          const {approvalProcessId} = this.$route.query
          const {auditBrief, processState} = this.form
          const {nextNodeVo, extParamVos, notifyUserIds, currentDetailVo} = this.detail
          const {nextAuditPersonId, nextAuditPersonName} = (this.$refs.approverNode && get(this.$refs.approverNode.getApprovalNodeList(), '0')) || {}

          this.$Message.destroy()
          if (!await this.$refs.form.validate()) return

          if (processState === '1') {
            // 环节人员
            if (!isNull(nextNodeVo) && !nextAuditPersonName) return this.$Message.error('请选择环节人员')

            // 财务管理 / 承租业务账单 / 应付管理 / 付款申请
            if (this.pageCode === 'FinPayablesBatchApplyServiceImpl') {
              processParams = await this.$refs.processPayableApply.getFormData()
              if (!processParams) return
            }

            // 节点参数
            // if (isArray(extParamVos) && extParamVos.length) {
            //   processParams = await this.$refs.processParams.getFormData()
            //   if (!processParams) return
            //
            //   return console.log(processParams)
            // }
          }

          this.isSubmit = true
          await (processState === '1' ? passAudit : rejectAudit)({
            documentId, approvalProcessId, auditBrief,
            nextNodeId: get(nextNodeVo, 'id', ''),
            nextAuditPersonId: nextAuditPersonId || '',
            nextAuditPersonName: nextAuditPersonName || '',
            auditApprovalItemDetailId: get(currentDetailVo, 'id', ''),
            nextNodeType: 1,
            extParamMap: {
              ...processParams,
              ...(this.commonAttachmentVoList.length && this.pageCode === 'FIN_ADJUSTMENT_APPLY') && {
                attachment_update: JSON.stringify(this.commonAttachmentVoList)
              },
              ...this.pageCode === 'FIN_CLAIM_APPLY' && {
                approverId: this.user.id,
                approverName: this.user.userName,
              }
            },
            ...processState !== '1' && {notifyUserIds: notifyUserIds || []},
          })

          this.visible = false
          this.$emit('on-success')
          this.$Message.success('提交成功')
        } catch (e) {} finally {
          this.isSubmit = false
        }
      },
    },
    data () {
      return {
        isNull,
        isArray,
        visible: false,
        isSubmit: false,
        form: {
          processState: '',
          auditBrief: '',
        },
        rules: {
          processState: [{required: true, message: '请选择是否审批状态'}],
          auditBrief: [{required: true, message: '请输入审核意见'}],
        },
      }
    },
  }
</script>

<style scoped lang="less">
  .nodeTip {
    color: red;
    display: inline-block;
  }
</style>
