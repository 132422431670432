import VXETable from 'vxe-table'

// CurrencyInput
VXETable.renderer.add('CurrencyInput', {
  renderEdit (h, {attrs = {}, props = {}}, {row, column}) {
    return h('CurrencyInput', {
      attrs,
      props: {
        clearable: true,
        ...props,
        value: row[column.property]
      },
      on: {
        input (value) {
          row[column.property] = value
        }
      }
    })
  },
  renderCell: (h, renderOpts, { row, column }) => h('span', row[column.property]),
})
