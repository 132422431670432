import BasicLayout from '@/layouts/basic-layout'

const prefix = 'storage-manage-sublease-termination-bill-'
export default [
  {
    path: '/storage-manage/sublease-termination-bill',
    component: BasicLayout,
    children: [
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '转租解约结算单编辑'},
        component: () => import('@/pages/storage-manage/sublease-termination-bill/detail-edit')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '转租解约结算单详情'},
        component: () => import('@/pages/storage-manage/sublease-termination-bill/detail-view')
      }
    ]
  }
]
