import BasicLayout from '@/layouts/basic-layout'

const prefix = 'financial-invoice-rate-'
export default {
  path: '/financial-manage/invoice/rate',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: `${prefix}index`,
      meta: {title: '企业税率管理'},
      component: () => import('@/pages/financial-manage/invoice/rate/index')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {cache: true, title: '企业税率编辑'},
      component: () => import('@/pages/financial-manage/invoice/rate/detail-edit')
    }
  ]
}
