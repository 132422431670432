<template>
  <a class="ivu-ml-8" @click="collapse = !collapse">
    {{ collapse ? '收起' : '展开' }}
    <Icon :type="`ios-arrow-${collapse ? 'up' : 'down'}`" />
  </a>
</template>

<script>
  export default {
    name: 'CommonCollapse',
    props: {
      value: {
        type: Boolean,
        default: false,
      }
    },
    watch: {
      collapse (value) {
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.collapse = value
        },
        immediate: true,
      }
    },
    data () {
      return {
        collapse: false,
      }
    }
  }
</script>
