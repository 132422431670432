/*
 * @Author: co
 * @Date: 2021-09-06 15:14:17
 */
import request from '@/plugins/request/http'

// 添加用户
export const employeeAdd = data => {
  return request('/user/v1/employee').post('postNormal', data)
}
// 编辑用户
export const employeeEdit = data => {
  return request('/user/v1/employee/id/update').post('postLocationPath', data)
}
// 删除用户
export const employeeDelete = data => {
  return request('/user/v1/employee/id/delete').post('postLocationPath', data)
}
// 修改用户状态
export const employeeStatus = data => {
  return request('/user/v1/employee/id/update/status').post('postLocationPath', data)
}
// 查询用户详情
export const employeeDetail = data => {
  return request('/user/v1/employee/id/queryByPrimaryKey').get('locationPath', data)
}
// 用户管理
export const employeeList = data => {
  return request('/user/v1/employee/page').post('postNormal', data)
}
// 根据关键字查询用户列表
export const employeeQueryByName = data => request('/user/v1/employee/queryByName').post('postNormal', data)

// 查询用户详情
export const queryEmployeeInfoForLoginUser = data => request('/user/v1/employee/queryEmployeeInfoForLoginUser').post('postNormal', data)
// 查询指定组织ID下面的员工
export const getProjectLeader = orgType => request(`/user/v1/employee/${orgType}/getProjectLeader`).post('postNormal')


// 添加角色
export const roleAdd = data => {
  return request('/user/v1/role').post('postNormal', data)
}
// 编辑角色
export const roleEdit = data => {
  return request('/user/v1/role/id/update').post('postLocationPath', data)
}
// 删除角色
export const roleDelete = data => {
  return request('/user/v1/role/id/delete').post('postLocationPath', data)
}
// 修改角色状态
export const roleStatus = data => {
  return request('/user/v1/role/id/update/status').post('postLocationPath', data)
}
// 查询角色详情
export const roleDetail = data => {
  return request('/user/v1/role/id/queryByPrimaryKey').post('postLocationPath', data)
}
// 角色管理
export const getRoleList = data => request('/user/v1/role/page').post('postNormal', data)
// 参数查询
export const roleQueryByParam = data => {
  return request('/user/v1/role/queryByParam').post('postNormal', data)
}


// 添加岗位
export const stationAdd = data => {
  return request('/user/v1/station').post('postNormal', data)
}
// 编辑岗位
export const stationEdit = data => {
  return request('/user/v1/station/id/update').post('postLocationPath', data)
}
// 删除岗位
export const stationDelete = data => {
  return request('/user/v1/station/id/delete').post('postLocationPath', data)
}
// 修改岗位状态
export const stationStatus = data => {
  return request('/user/v1/station/id/update/status').post('postLocationPath', data)
}
// 查询岗位详情
export const stationDetail = data => {
  return request('/user/v1/station/id/queryByPrimaryKey').post('postLocationPath', data)
}
// 岗位管理
export const getStationList = data => {
  return request('/user/v1/station/page').post('postNormal', data)
}


// 菜单
export const resourceList = data => {
  return request('/user/v1/resource/page').post('postNormal', data)
}

// 添加菜单权限
export const roleResourceAdd = data => {
  return request('/user/v1/roleResource').post('postNormal', data)
}
// 登录后调用菜单列表
export const foreEndMenusList = data => {
  return request('/user/v1/resource/foreEndMenus').post('postNormal', data)
}
// 查询所有菜单列表
export const queryByParamMenuList = data => {
  return request('/user/v1/resource/queryByParam').post('postNormal', data)
}
// 新增菜单
export const resourceAdd = data => {
  return request('/user/v1/resource').post('postNormal', data)
}
// 修改菜单
export const resourceEdit = data => {
  return request('/user/v1/resource/id/update').post('postLocationPath', data)
}
// 删除菜单
export const resourceDelete = data => {
  return request('/user/v1/resource/id/delete').post('postLocationPath', data)
}
// 批量删除菜单
export const resourceBatchDelete = data => {
  return request('/user/v1/resource/batchDelete').post('postNormal', data)
}

// 查询当前用户所在公司的前端菜单
export const queryCurrentUserCompanyMenus = data => {
  return request('/user/v1/resource/queryCurrentUserCompanyMenus').post('postNormal', data)
}
// 根据公司组织id查询前端列表
export const queryMenusByCompanyOrgId = data => {
  return request('/user/v1/resource/companyOrgId/queryMenusByCompanyOrgId').post('postLocationPath', data)
}

// 员工邀请
export const inviteEmployee = data => {
  return request('/user/v1/employee/inviteEmployeeId/invite').post('postLocationPath', data)
}
// 查询当前登入者收到的企业邀请
export const employeeInvitation = data => {
  return request('/user/v1/employeeInvitation/userId/queryByUserId').post('postLocationPath', data)
}
// 修改EmployeeInvitation
export const updateAcceptStatus = data => {
  return request('/user/v1/employeeInvitation/id/updateAcceptStatus/acceptStatus').post('postLocationPath', data)
}

// 员工审核代理配置接口
export const approvalProxyConfig = data => {
  return request('/user/v1/approvalProxyConfig/queryByParam').post('postNormal', data)
}
// 员工审核代理配置接口添加ApprovalProxyConfig
export const approvalProxyConfigBatchAdd = data => {
  return request('/user/v1/approvalProxyConfig/batchAdd').post('postNormal', data)
}
// 用户管理
export const queryByPageForAllOfCompany = data => {
  return request('/user/v1/employee/queryByPageForAllOfCompany').post('postNormal', data)
}
