/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const getFinClaimList = data => {
  return request('/operation/v1/finClaim/page').post('postNormal', data)
}
// 认领初使化
export const claimInit = data => {
  return request('/operation/v1/finClaim/claimInit').post('postNormal', data)
}
// 根据id查询信息
export const finClaimQueryByPrimaryKey = data => {
  return request('/operation/v1/finClaim/id/queryByPrimaryKey').get('locationPath', data)
}
// 选择应收单
export const selectPayment = data => {
  return request('/operation/v1/finClaim/selectPayment').post('postNormal', data)
}
// 选择未收款结算单
export const selectFinSettlement = data => {
  return request('/operation/v1/finClaim/selectFinSettlement').post('postNormal', data)
}
// 查询应收单(未收款或部分收款)
export const queryPayment = data => {
  return request('/operation/v1/finClaim/queryPayment').post('postNormal', data)
}
// 查询未收款结算单
export const queryFinSettlement = data => {
  return request('/operation/v1/finClaim/queryFinSettlement').post('postNormal', data)
}
// 新增认领单
export const finClaimAdd = data => {
  return request('/operation/v1/finClaim/add').post('postNormal', data)
}
// 取消认领申请
export const cancelClaimApply = data => {
  return request('/operation/v1/finClaim/cancelClaimApply').post('postNormal', data)
}
// 保存或提交取消认领申请
export const saveCancelClaimApply = data => {
  return request('/operation/v1/finClaim/saveCancelClaimApply').post('postNormal', data)
}
// 认领对应应收单分页查询
export const claimItemQueryByPage = data => {
  return request('/operation/v1/finClaim/claimItemQueryByPage').post('postNormal', data)
}
// 查询取消认领申请单
export const queryFinClaimCancellationApplyById = data => {
  return request('/operation/v1/finClaim/claimCancellationApplyId/queryFinClaimCancellationApplyById').get('locationPath', data)
}