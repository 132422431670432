/*
 * @Author: co
 * @Date: 2021-06-30 10:12:40
 */
const state = {
  finPayablesBatchApplyVo: {},
  commonAttachmentVoList: []
}

const mutations = {
  setFinPayablesBatchApplyVo: (state, data) => {
    state.finPayablesBatchApplyVo = data
  },
  setCommonAttachmentVoList: (state, data) => {
    state.commonAttachmentVoList = data
  },
}

const actions = {
  setFinPayablesBatchApplyVo: ({ commit }, data) => {
    commit('setFinPayablesBatchApplyVo', data)
  },
  setCommonAttachmentVoList: ({ commit }, data) => {
    commit('setCommonAttachmentVoList', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}