import BasicLayout from '@/layouts/basic-layout'

const prefix = 'invest-'
const basePath = '/invest'
export default [
  {
    path: basePath,
    name: 'invest',
    redirect: {name: `${prefix}manage`},
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: `${prefix}manage`,
        meta: {title: '拓展前期管理' },
        component: () => import('@/pages/invest/manage')
      },
      {
        path: 'manage/init',
        name: `${prefix}manage-init`,
        meta: {title: '拓展前期管理新建', hidden: true, activePath: `${basePath}/manage`, cache: true, tabs: false},
        component: () => import('@/pages/invest/manage/operation/init/index')
      },
      {
        path: 'manage/details/:id',
        name: `${prefix}manage-details`,
        meta: {title: '拓展前期管理详情', hidden: true, activePath: `${basePath}/manage`},
        component: () => import('@/pages/invest/manage/operation/details/index')
      },
      {
        path: 'manage/research/:id',
        name: `${prefix}manage-research`,
        meta: {title: '可研', hidden: true, cache: true, activePath: `${basePath}/manage`},
        component: () => import('@/pages/invest/manage/operation/calculate/index')
      },
      {
        path: 'manage/decide/:id',
        name: `${prefix}manage-decide`,
        meta: {title: '立项', hidden: true, cache: true, activePath: `${basePath}/manage`},
        component: () => import('@/pages/invest/manage/operation/calculate/index')
      },
      {
        path: 'manage/proposal/:id',
        name: `${prefix}manage-proposal`,
        meta: {title: '董事会议案', hidden: true, cache: true, activePath: `${basePath}/proposal`},
        component: () => import('@/pages/invest/manage/operation/calculate/index')
      },
      {
        path: 'manage/file/:id',
        name: `${prefix}manage-file`,
        meta: {title: '项目物业档案', hidden: true, cache: true, activePath: `${basePath}/manage`},
        component: () => import('@/pages/invest/manage/operation/file/index')
      }
    ]
  }
]
