/**
 * @description 按扭级权限校验
 * @author 蛋蛋 <i@hzh.li>
 * @created 2022/04/29 10:43
 */
import {isArray} from 'lodash'

import store from '@/store'
import {includeArray} from '@/libs/system'

/**
 * 权限校验
 * @param {Array} roleIds 权限id
 * @returns {Boolean}
 * @example v-if="checkPermission([1])"
 */
export const checkPermission = (roleIds) => {
    const {Rights} = store.state.admin['sys/rights']

    if (isArray(roleIds) && roleIds.length && Rights.length) return includeArray(roleIds, Rights)
    return false
}
