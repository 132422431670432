import BasicLayout from '@/layouts/basic-layout'

const prefix = 'configuration-'
const basePath = '/configuration'
export default [
  {
    path: basePath,
    component: BasicLayout,
    children: [
      {
        path: '',
        name: 'configuration',
        meta: {title: '配置改造'},
        component: () => import('@/pages/configuration/demand')
      },
      {
        path: 'init',
        name: `${prefix}init`,
        meta: {title: '配置改造单', hidden: true, cache: true, activePath: basePath},
        component: () => import('@/pages/configuration/demand/operation/init')
      },
      {
        path: 'details/:id',
        name: `${prefix}details`,
        meta: {title: '配置改造单详情', hidden: true, activePath: basePath},
        component: () => import('@/pages/configuration/demand/operation/details')
      },
      {
        path: 'details-sp/:id',
        name: `${prefix}details-sp`,
        meta: {title: '配置改造单详情', hidden: true},
        component: () => import('@/pages/configuration/demand/operation/details/details-sp')
      },
      {
        path: 'split/init/:id',
        name: `${prefix}split-init`,
        meta: {title: '配置改造单房源拆分新建', hidden: true, cache: true, activePath: basePath},
        component: () => import('@/pages/configuration/demand/operation/split')
      },
      {
        path: 'split/edit/:id',
        name: `${prefix}split-edit`,
        meta: {title: '配置改造单房源拆分编辑', hidden: true, cache: true, activePath: basePath},
        component: () => import('@/pages/configuration/demand/operation/split/edit.vue'),
      },
      {
        path: 'split/details/:id',
        name: `${prefix}split-details`,
        meta: {title: '配置改造单房源拆分详情', hidden: true, activePath: basePath },
        component: () => import('@/pages/configuration/demand/operation/split/details.vue')
      },
      {
        path: 'split/details-sp/:id',
        name: `${prefix}split-details-sp`,
        meta: {title: '配置改造单房源拆分审批', hidden: true, activePath: basePath},
        component: () => import('@/pages/configuration/demand/operation/split/details-sp.vue')
      },
      {
        path: 'settlement/:pageType(init|edit|details|details-sp)/:id/:configurationType?',
        name: `${prefix}settlement`,
        meta: {title: '配置改造单改造结算书', hidden: true, cache: true, activePath: basePath},
        component: () => import('@/pages/configuration/demand/operation/settlement')
      },
      {
        path: 'deliver/:pageType(init|edit|details|details-sp)/:id/:type?',
        name: `${prefix}deliver`,
        meta: {title: '配置改造单物业交付', hidden: true, cache: true, activePath: basePath},
        component: () => import('@/pages/configuration/demand/operation/deliver')
      }
    ]
  }
]
