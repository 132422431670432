import { get, isArray } from 'lodash'
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import { checkPermission } from '@/libs/permission'

export default {
  computed: {
    ...mapState('admin/user', ['info']),
    ...mapState('dictionary/dictionary/index', ['CompoundEntitiesDictionary']),
  },
  filters: {
    formatDate (date, format = 'YYYY-MM-DD') {
      if (!date) return ''
      return dayjs(date).format(format)
    },
  },
  methods: {
    ...mapActions('admin/page', ['close']),
    // 当 $route 更新时触发
    appRouteChange (to, from) {},
    // 关闭当前页
    closePage() {
      const {fullPath: tagName} = this.$route

      this.close({tagName})
    },
    /**
     * 权限校验
     * @param {String | Array} roleIds 权限id
     * @returns {Boolean}
     */
    checkPermission (roleIds ) {
      return checkPermission(isArray(roleIds) ? roleIds : [roleIds])
    },
    // 数据字典
    getConfigItem (key) {
      return this.CompoundEntitiesDictionary.configItems[key]
    },
    getConfigItemName (key, code) {
      if (!this.CompoundEntitiesDictionary.configItems[key]) return ''
      const item = this.CompoundEntitiesDictionary
        .configItems[key]
        .find(item => item.itemCode === `${code}`)

      return get(item, 'itemName', '')
    },
  },
  data () {
    return {
      grid: {
        lg: 6,
        span: 24,
      },
      collapse: true,
    }
  },
}
