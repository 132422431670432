/*
 * @Author: co
 * @Date: 2021-09-27 16:05:32
 */
import BasicLayout from '@/layouts/basic-layout'
const prefix = 'report-center-room-status-statement-'
export default {
  path: '/report-center/room-status-statement',
  redirect: { name: `${prefix}index` },
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: { cache: true, title: '房态总表', auth: true },
      component: () => import('@/pages/report-center/room-status-statement/index')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: { title: '房态总表详情', auth: true },
      component: () => import('@/pages/report-center/room-status-statement/detail-view')
    }
  ]
}
