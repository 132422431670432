import BasicLayout from '@/layouts/basic-layout'

const prefix = 'put-pull-'
export default {
  path: '/put-pull',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: 'put-pull',
      meta: {title: '房源上下架管理'},
      component: () => import('@/pages/rent/put-pull')
    },
    {
      path: 'put-on/apply',
      name: `${prefix}put-on-apply`,
      meta: {title: '上架申请', cache: true},
      component: () => import('@/pages/rent/put-pull/operation/put-on/apply')
    },
    {
      path: 'put-on/details-sp/:id',
      name: `${prefix}put-on-details-sp`,
      meta: {title: '上架审批详情'},
      component: () => import('@/pages/rent/put-pull/operation/put-on/details/details-sp')
    }
  ]
}
