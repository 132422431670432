import BasicLayout from '@/layouts/basic-layout'

const prefix = 'report-center-relet-statement-'
export default {
  path: '/report-center/relet-statement',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {cache: true, title: '出租报表（业务）'},
      component: () => import('@/pages/report-center/relet-statement/index')
    },
  ]
}
