/*
 * @Author: co
 * @Date: 2021-09-06 15:14:17
 */
import request from '@/plugins/request/http'

// 添加用户
export const employeeAdd = data => {
  return request('/user/v1/user').post('postNormal', data)
}
// 编辑用户
export const employeeEdit = data => {
  return request('/user/v1/user/id/update').post('postLocationPath', data)
}
// 删除用户
export const employeeDelete = data => {
  return request('/user/v1/user/id/delete').post('postLocationPath', data)
}
// 查询用户详情
export const employeeDetail = data => {
  return request('/user/v1/user/id/queryByPrimaryKey').get('locationPath', data)
}
// 用户管理
export const employeeList = data => {
  return request('/user/v1/user/page').post('postNormal', data)
}

