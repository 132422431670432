


export default {
  namespaced: true,
  state: {
    currentRow: { name: 'xsn' },
    // 查询条件
    params: {
      areaId: '',
      cityId: '',
      orderByDesc: '',
      orderByList: '',
      pageNum: 1,
      pageSize: 10,
      projectAddress: '',
      projectBasicInformation: '',
      projectPhase: '',
      projectId: '',
      provinceId: '',
    },
    // cityArea: []
  },
  getters: {

  },
  // 持久缓存
  mutations: {
    // setCurrentRow(state, row) {
    //   state.currentRow = row
    // }
    setParams(state) {
      state.params.pageNum = 1
      state.params.pageSize = 10
      state.params.projectAddress = ''
      state.params.projectBasicInformation = ''
      state.params.projectPhase = ''
      state.params.projectId = ''
      // state.cityArea = []
    }
  },

  actions: {

    /**
     * @description 设置用户数据
     * @param {Object} state vuex state
     * @param {Object} dispatch vuex dispatch
     * @param {*} info info
     */
    set({ state, dispatch }, currentRow) {
      return new Promise(async resolve => {
        // store 赋值
        state.currentRow = currentRow;
        // 持久化
        await dispatch('admin/db/set', {
          dbName: 'invest',
          path: 'invest',
          value: currentRow,
          user: true
        }, { root: true });
        // end
        resolve();
      })
    },

    /**
     * @description 从数据库取用户数据
     * @param {Object} state vuex state
     * @param {Object} dispatch vuex dispatch
     */
    load({ state, dispatch }) {

      return new Promise(async resolve => {
        // store 赋值
        state.currentRow = await dispatch('admin/db/get', {
          dbName: 'invest',
          path: 'invest',
          defaultValue: {},
          user: true
        }, { root: true });
        // end
        resolve();
      })
    },


  }

}
