/**
 * @description 鉴权指令
 * 当传入的权限当前用户没有时，会移除该组件
 * 用例：<Tag v-auth="['admin']">text</Tag>
 * */
import { checkPermission } from '@/libs/permission'

export default {
    inserted (el, {value}) {
        if (!checkPermission(value)) el.parentNode && el.parentNode.removeChild(el)
    }
}
