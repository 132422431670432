<template>
  <Card dis-hover
        :title="title" :bordered="false"
        v-if="itemVoList.length">
    <Form ref="form" :model="itemVoList">
      <div :key="item.id" v-for="(item, index) in itemVoList">
        <DescriptionList col="3">
          <Description term="项目：">{{ item.projectName }}</Description>
          <Description term="账单编号：">{{ item.payablesCode }}</Description>
          <Description term="费用项：">{{ item.feeName }}</Description>
          <Description term="应付日期：">{{ item.payablesDate }}</Description>
          <Description term="账单金额：">{{ item.amount }}</Description>
          <template v-if="paramName === 'approvedAmount'">
            <Description term="已付金额：">{{ item.cfvAmount }}</Description>
            <Description term="抵扣金额：">{{ item.deductionAmount || 0 }}</Description>
            <Description term="未付金额：">{{ item.residualAmount }}</Description>
          </template>
          <Description term="本次申请金额：">{{ item.applyAmount }}</Description>
          <template v-if="['payDate', 'transactionSerialNo'].includes(paramName)">
            <Description term="审核通过实付金额：">{{ item.approvedAmount }}</Description>
          </template>
        </DescriptionList>
        <Row :gutter="24">
          <template v-if="paramName === 'approvedAmount'">
            <Col :md="8" :span="24">
              <FormItem label="实付金额"
                        :prop="`${index}.approvedAmount`"
                        :rules="{required: true, message: '请输入实付金额'}">
                <CurrencyInput clearable placeholder="请输入实付金额" v-model="item.approvedAmount" />
              </FormItem>
            </Col>
          </template>
          <template v-else-if="['payDate', 'transactionSerialNo'].includes(paramName)">
            <Col :md="8" :span="24">
              <FormItem label="付款日期"
                        :prop="`${index}.payDate`"
                        :rules="{required: true, message: '请选择付款日期'}">
                <DatePicker clearable type="date" placeholder="请选择付款日期"
                            v-width="'100%'"
                            :value="item.payDate"
                            @on-change="date => item.payDate = date" />
              </FormItem>
            </Col>
            <Col :md="8" :span="24">
              <FormItem prop="transactionSerialNo" label="交易流水号"
                        :prop="`${index}.transactionSerialNo`"
                        :rules="{required: true, message: '请输入交易流水'}">
                <Input clearable placeholder="请输入交易流水" v-model="item.transactionSerialNo" />
              </FormItem>
            </Col>
          </template>
        </Row>
        <Divider v-if="index < itemVoList.length - 1" />
      </div>
    </Form>
  </Card>
</template>

<script>
  import { mapState } from 'vuex'
  import { get, isEmpty, cloneDeep, flattenDeep } from 'lodash'

  export default {
    name: 'ProcessPayableApply',
    props: {
      extParams: {
        type: Array,
        default: () => ([]),
      },
    },
    watch: {
      finPayablesBatchApplyVo: {
        handler (value) {
          if (isEmpty(value)) return
          const {finPayablesBatchProjectOwnerVoList} = cloneDeep(value)

          this.itemVoList = flattenDeep(finPayablesBatchProjectOwnerVoList.map(({projectName, finPayablesBatchApplyItemOwnerVoList}) => {
            return finPayablesBatchApplyItemOwnerVoList.map(({finPayablesBatchApplyItemVoList}) => {
              return finPayablesBatchApplyItemVoList.map(item => {
                return {
                  ...item, projectName,
                  ...this.paramName === 'approvedAmount' && {approvedAmount: `${item.applyAmount}`},
                }
              })
            })
          }))
        },
        immediate: true
      },
    },
    computed: {
      ...mapState('processCenter/public', ['finPayablesBatchApplyVo']),
      title () {
        return `请确认付款${['payDate', 'transactionSerialNo'].includes(this.paramName) ? '信息' : '金额'}`
      },
      paramName () {
        return get(this.extParams, '0.paramName')
      },
    },
    methods: {
      getFormData () {
        return new Promise(async (resolve) => {
          if (!await this.$refs.form.validate()) {
            this.$Message.error(`${this.title}是否填写完整`)
            return resolve(false)
          }
          return resolve(this.itemVoList.reduce((acc, {payablesId, ...cur}) => ({
            ...acc,
            ...['approvedAmount'].includes(this.paramName) && {[`approvedAmount_${payablesId}`]: cur.approvedAmount},
            ...['payDate', 'transactionSerialNo'].includes(this.paramName) && {
              [`payDate_${payablesId}`]: cur.payDate,
              [`transactionSerialNo_${payablesId}`]: cur.transactionSerialNo,
            },
          }), {}))
        })
      },
    },
    data () {
      return {
        itemVoList: []
      }
    },
  }
</script>

<style scoped lang="less">
  /deep/ .ivu-divider {
    margin: 8px 0 24px 0;
  }
  /deep/ .ivu-description-term,
  /deep/ .ivu-description-detail {
    padding-bottom: 8px;
  }
</style>
