import BasicLayout from '@/layouts/basic-layout'


const prefix = 'personnel-manage-'

export default [
  {
    path: '/store/personnel-manage',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {title: '人员管理'},
        component: () => import('@/pages/store/personnel-manage/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {title: '人员编辑'},
        component: () => import('@/pages/store/personnel-manage/detail-edit')
      },

      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '人员详情'},
        component: () => import('@/pages/store/personnel-manage/detail-view')
      }
    ]
  }
]
