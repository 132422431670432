import BasicLayout from '@/layouts/basic-layout'

const pre = 'store-manage-'
export default [
  {
    path: '/store/store-manage',
    redirect: {name: `${pre}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${pre}index`,
        meta: {title: '门店管理'},
        component: () => import('@/pages/store/store-manage/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${pre}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '门店编辑'},
        component: () => import('@/pages/store/store-manage/detail-edit')
      },
      {
        path: 'base-detail-edit/:id',
        name: `${pre}base-edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '门店编辑'},
        component: () => import('@/pages/store/store-manage/base-detail-edit')
      },
      {
        path: 'picture-detail-edit/:id',
        name: `${pre}picture-edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '门店编辑'},
        component: () => import('@/pages/store/store-manage/picture-detail-edit')
      },

      {
        path: 'detail-view/:id',
        name: `${pre}view`,
        auth: ['hidden'],
        meta: {title: '门店详情'},
        component: () => import('@/pages/store/store-manage/detail-view')
      },
      {
        path: 'retreat-day-detail-view/:id',
        name: `${pre}retreat-day-view`,
        auth: ['hidden'],
        meta: {title: '自动挞定天数审批详情'},
        component: () => import('@/pages/store/store-manage/retreat-day-detail-view')
      },

      {
        path: 'detail-view-on-off-line/:id',
        name: `${pre}detail-view-on-off-line`,
        auth: ['hidden'],
        meta: {title: '上线审批详情'},
        component: () => import('@/pages/store/store-manage/detail-view-on-off-line')
      },
      {
        path: 'store-detail-view/:id',
        name: `${pre}store-detail-view`,
        auth: ['hidden'],
        meta: {title: '门店数据'},
        component: () => import('@/pages/store/store-manage/store-detail-view')
      }
    ]
  }
]
