import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'xe-utils'
import Vue from 'vue'
import dayjs from 'dayjs'
import ViewUI from 'view-design'
import VXETable from 'vxe-table'
import pluginIsBetween from 'dayjs/plugin/isBetween'
import pluginIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import pluginIsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import pluginCustomParseFormat from 'dayjs/plugin/customParseFormat'
import { VueJsonp } from 'vue-jsonp'

import store from '@/store/index'
import router from '@/router'
import mixins from '@/mixins/app'
import directives from '@/plugins/directives'
import iViewPro from '@/libs/iview-pro/iview-pro.min'
import iIconSelect from '@/components/icon-select/icon-select.min'
import {initPageQuery} from '@/plugins/utils/init-page-query'
import {selectByGroups} from '@/api/dictionary'
import {asyncRouterMap} from '@/router/routes'
import {formatDate} from '@/libs/util'
import App from '@/App'
import Components from '@/components'

import '@/libs/vendors/vxe-table/edit-render'

import '@/libs/iview-pro/iview-pro.css'
import '@/styles/vxe-table.scss'
import '@/styles/index.less'

// 替换日期过滤
dayjs.extend(pluginIsBetween)
dayjs.extend(pluginIsSameOrAfter)
dayjs.extend(pluginIsSameOrBefore)
dayjs.extend(pluginCustomParseFormat)

// mixin
Vue.mixin(mixins)
//
Vue.use(VueJsonp)
Vue.use(iViewPro)
Vue.use(VXETable)
Vue.use(directives)
Vue.use(Components)
Vue.use(iIconSelect)
Vue.use(ViewUI, {transfer: true})

Vue.filter('formatDate', formatDate)
Vue.filter('date_format', formatDate)

Vue.prototype.$XModal = VXETable.modal
Vue.prototype.$XPrint = VXETable.print
Vue.prototype.$XSaveFile = VXETable.saveFile
Vue.prototype.$XReadFile = VXETable.readFile
Vue.prototype.$selectByGroups = selectByGroups
// // 初始化页面查询条件
Vue.prototype.$initPageQuery = initPageQuery

// 取消 Vue 所有的日志与警告
Vue.config.silent = true

// init
store.commit('admin/page/init', asyncRouterMap)
store.dispatch('admin/account/load')
store.dispatch('admin/layout/listenFullscreen')
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
