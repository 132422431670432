import BasicLayout from '@/layouts/basic-layout'

const prefix = 'customer-'
const activePath = '/customer'
export default {
  path: '/customer',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: 'customer',
      meta: {title: '租客管理'},
      component: () => import('@/pages/rent/customer')
    },
    {
      path: ':pageType(personal-init|personal-edit|enterprise-init|enterprise-edit)/:id(\\d+)?',
      name: `${prefix}edit`,
      meta: {hidden: true, cache: true, activePath},
      component: () => import('@/pages/rent/customer/operation/edit')
    }
  ]
}
