/**
 * 系统内置方法集，正常情况下您不应该修改或移除此文件
 * */
import { isArray, cloneDeep } from 'lodash'

/**
 * @description 根据当前路由，获取当前菜单
 * @param {object} to 当前路径
 * @param {Array} menuList 所有路径
 * */
export const getCurrentMenu = (to, menuList) => {
  if (!isArray(menuList)) return {}
  const flattenMenuList = flattenSiderMenu(menuList, [])
  // 默认控制台
  const dashboard = flattenMenuList.find(item => '/dashboard/console' === item.path) || {}

  return flattenMenuList.find(item => {
    if (to.meta.currentActiveMenu) return item.path === to.meta.currentActiveMenu
    return to.path === item.path
  }) || dashboard
}

export const treeToFlat = (menuList = []) => {
  const menu = []

  for (const { children, ...item } of menuList) {
    if (isArray(children)) menu.push(...treeToFlat(children))
    menu.push(item)
  }
  return menu
}

/**
 * @description 根据当前顶栏菜单 name，找到对应的二级菜单
 * @param {Array} menuList 所有的二级菜单
 * @param {String} headerName 当前顶栏菜单的 name
 * */
function getMenuSider (menuList, headerName = '') {
  if (headerName) {
    return menuList.filter(item => item.header === headerName);
  } else {
    return menuList;
  }
}

export { getMenuSider };

/**
 * @description 递归获取所有子菜单
 * */
function getAllSiderMenu (menuList) {
  let allMenus = [];

  menuList.forEach(menu => {
    if (menu.children && menu.children.length) {
      const menus = getMenuChildren(menu);
      menus.forEach(item => allMenus.push(item));
    } else {
      allMenus.push(menu);
    }
  });

  return allMenus;
}

function getMenuChildren (menu) {
  if (menu.children && menu.children.length) {
    return menu.children.reduce((all, item) => {
      const foundChildren = getMenuChildren(item);
      return all.concat(foundChildren);
    }, []);
  } else {
    return [menu];
  }
}

export { getAllSiderMenu };

/**
 * @description 将菜单转为平级
 * */
function flattenSiderMenu (menuList, newList) {
  menuList.forEach(menu => {
    let newMenu = {};
    for (let i in menu) {
      if (i !== 'children') newMenu[i] = cloneDeep(menu[i]);
    }
    newList.push(newMenu);
    menu.children && flattenSiderMenu(menu.children, newList);
  });
  return newList;
}

export { flattenSiderMenu };

/**
 * @description 判断列表1中是否包含了列表2中的某一项
 * 因为用户权限 access 为数组，includes 方法无法直接得出结论
 * */
function includeArray (list1, list2) {
  let status = false;
  list2.forEach(item => {
    if (list1.includes(item)) status = true;
  });
  return status;
}

export { includeArray };
