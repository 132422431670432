import BasicLayout from '@/layouts/basic-layout'

const prefix = 'house-manage-'
export default [
  {
    path: '/store/house-manage',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {title: '房源管理'},
        component: () => import('@/pages/store/house-manage/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        meta: {title: '房源编辑'},
        component: () => import('@/pages/store/house-manage/detail-edit')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        meta: {title: '房源详情'},
        component: () => import('@/pages/store/house-manage/detail-view')
      },
      {
        path: 'goods-disposition-detail-view/:id',
        name: `${prefix}goods-disposition-view`,
        meta: {title: '物品配置详情'},
        component: () => import('@/pages/store/house-manage/goods-disposition-detail-view')
      },
      {
        path: 'goods-disposition-detail-edit/:id',
        name: `${prefix}goods-disposition-edit`,
        meta: {title: '物品配置编辑'},
        component: () => import('@/pages/store/house-manage/goods-disposition-detail-edit')
      },
      {
        path: 'pricing-import',
        name: `${prefix}pricing-import`,
        meta: {title: '导入调整定价'},
        component: () => import('@/pages/store/house-manage/pricing-import')
      },
      {
        path: 'pricing-history',
        name: `${prefix}pricing-history`,
        meta: {title: '定价历史'},
        component: () => import('@/pages/store/house-manage/pricing-history')
      }, {
        path: 'pricing-detail/:id',
        name: `${prefix}pricing-detail`,
        meta: {title: '定价详情'},
        component: () => import('@/pages/store/house-manage/pricing-detail')
      },
      {
        path: 'house-import',
        name: `${prefix}house-import`,
        meta: {title: '导入房源'},
        component: () => import('@/pages/store/house-manage/house-import')
      },
      {
        path: 'pricing-detail-reminder',
        name: `${prefix}pricing-detail-reminder`,
        meta: {title: '定价即将过期房源'},
        component: () => import('@/pages/store/house-manage/pricing-detail-reminder')
      }
    ]
  }
]
