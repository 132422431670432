/**
 * 持久化存储
 * 一般情况下，您无需修改此文件
 * */
import { get as _get, set as _set } from 'lodash'
import storage from '@/libs/vendors/storage'

const dbName = 'database'
/**
 * @description 检查路径是否存在 不存在的话初始化
 * @param {Object} params
 * @param {string=} params.path 路径
 * @param {string=} params.user 区分用户
 * @param {function=} params.validator 数据校验钩子 返回 true 表示验证通过
 * @param {*=} params.defaultValue 初始化默认值
 * @returns {string} 可以直接使用的路径
 */
export const pathInit = (params) => {
  const {path = '', user = true, validator = () => true, defaultValue = ''} = params
  const {uuid = 'ghost-uuid'} = storage.get('user', {})
  const database = storage.get(dbName, {})
  const currentPath = `${user ? `user.${uuid}` : 'public'}${path ? `.${path}` : ''}`
  const value = _get(database, currentPath)

  if (!(value !== undefined && validator(value))) {
    storage.set(dbName, _set(database, currentPath, defaultValue))
  }
  return currentPath
}
export default {
  namespaced: true,
  actions: {
    /**
     * @description 将数据存储到指定位置 | 路径不存在会自动初始化
     * @description 效果类似于取值 dbName.path = value
     * @param context context
     * @param {Object} payload
     * @param {string=} payload.path 存储路径
     * @param {boolean=} payload.user 是否区分用户
     * @param {*=} payload.value 需要存储的值
     */
    set (context, payload) {
      const {path = '', value = '', user = false} = payload
      const newPath = pathInit({path, user})
      const database = storage.get(dbName, {})

      storage.set(dbName, _set(database, newPath, value))
    },
    /**
     * @description 获取数据
     * @description 效果类似于取值 dbName.path || defaultValue
     * @param context context
     * @param {Object} payload
     * @param {string=} payload.path 存储路径
     * @param {boolean=} payload.user 是否区分用户
     * @param {*=} payload.defaultValue 取值失败的默认值
     */
    get (context, payload) {
      return new Promise(resolve => {
        const {path = '', defaultValue = '', user = false} = payload
        const newPath = pathInit({path, user, defaultValue})
        const database = storage.get(dbName, {})

        resolve(_get(database, newPath))
      })
    },
  }
}
