import BasicLayout from '@/layouts/basic-layout'
import RouteView from '@/layouts/basic-layout/route-view'

const prefix = 'integral-manage'
export default {
  path: '/integral-manage',
  redirect: {name: `${prefix}-activity`},
  component: BasicLayout,
  children: [
    {
      path: 'activity',
      component: RouteView,
      children: [
        {
          path: '',
          name: `${prefix}-activity`,
          meta: {cache: true, title: '积分活动管理'},
          component: () => import('@/pages/integral-manage/activity')
        },
        {
          path: 'detail/:id',
          name: `${prefix}-activity-detail`,
          meta: {cache: true, title: '积分活动详情'},
          component: () => import('@/pages/integral-manage/activity/detail')
        }
      ]
    },
    {
      path: 'integral',
      name: `${prefix}-integral`,
      meta: {cache: true, title: '积分明细'},
      component: () => import('@/pages/integral-manage/integral')
    }
  ]
}
