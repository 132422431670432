import BasicLayout from '@/layouts/basic-layout'
import RouteView from '@/layouts/basic-layout/route-view'

const prefix = 'device-manage-'
export default [
  {
    path: '/device-manage',
    redirect: { name: `${prefix}electricity-meter` },
    component: BasicLayout,
    children: [
      {
        path: 'electricity-meter/index',
        name: `${prefix}electricity-meter`,
        meta: {title: '电表管理'},
        component: () => import('@/pages/device-manage/electricity-meter/index')
      },
      {
        path: 'electricity-meter/detail-view/:id',
        name: `${prefix}electricity-meter-detail-view`,
        meta: {title: '电表详情'},
        component: () => import('@/pages/device-manage/electricity-meter/detail-view')
      },
      {
        path: 'electricity-meter/detail-edit/:id',
        name: `${prefix}electricity-meter-detail-edit`,
        meta: {title: '电表编辑'},
        component: () => import('@/pages/device-manage/electricity-meter/detail-edit')
      },
      {
        path: 'electricity-meter/components/import-data',
        name: `${prefix}electricity-meter-import-data`,
        meta: {title: '导入数据', cache: true},
        component: () => import('@/pages/device-manage/electricity-meter/components/import-data')
      },
      {
        path: 'electricity-meter/device-voucher',
        name: `${prefix}device-voucher`,
        meta: {title: '设备管理', cache: true},
        component: () => import('@/pages/device-manage/device-voucher')
      },

      {
        path: 'doorlock',
        component: RouteView,
        children: [
          {
            path: '',
            name: `${prefix}doorlock`,
            meta: {title: '门锁管理'},
            component: () => import('@/pages/device-manage/doorlock'),
          },
          {
            path: 'edit/:id',
            name: `${prefix}doorlock-edit`,
            meta: {title: '门锁编辑', cache: true},
            component: () => import('@/pages/device-manage/doorlock/operation/edit')
          }
        ]
      }
    ]
  }
]
