import BasicLayout from '@/layouts/basic-layout'

const prefix = 'project-payment-application-'
export default {
  path: '/financial-manage/project-payment-application',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '项目付款管理'},
      component: () => import('@/pages/financial-manage/project-payment-application/index')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {cache: true, title: '项目付款编辑'},
      component: () => import('@/pages/financial-manage/project-payment-application/detail-edit')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: {title: '项目付款详情'},
      component: () => import('@/pages/financial-manage/project-payment-application/detail-view')
    }
  ]
}
