/*
 * @Author: co
 * @Date: 2021-09-06 15:14:17
 */
import request from '@/plugins/request/http.js'

export const getAreaSimpleList = data => {
  return request('/operation/v1/area/getAreaSimpleList').post('postNormal', data)
}
export const getAreaList = data => {
  return request('/operation/v1/area/areaPage').post('postNormal', data)
}
// 删除Area
export const areaDelete = data => {
  return request('/operation/v1/area/id/delete').post('postLocationPath', data)
}
// 修改Area
export const areaEdit = data => {
  return request('/operation/v1/area/id/update').post('postLocationPath', data)
}