import request from '@/plugins/request/http'
// 配置改造分页查询

// 分页查询
export const configurationPage = data => {
  return request('/operation/v1/pgConfiguration/page').post('postNormal', data)
}
// 新建
export const configurationInit = data => {
  return request('/operation/v1/pgConfiguration/add').post('postNormal', data)
}
// 详情
export const configurationDetails = data => {
  return request('/operation/v1/pgConfiguration/id/queryByPrimaryKey').get('locationPath', data)
}
// 详情分页查询
export const configurationDetailsPage = data => {
  return request('/operation/v1/pgConfiguration/pageSecondaryHouse').post('postNormal', data)
}
// 分页删除
export const configurationDetailsPageDelete = data => {
  return request('/operation/v1/pgConfiguration/id/deleteSecondaryHouse').post('postLocationPath', data)
}
// 主单修改
export const configurationUpdate = data => {
  return request('/operation/v1/pgConfiguration/id/update').post('postLocationPath', data)
}
// 主单删除
export const configurationDelete = data => {
  return request('/operation/v1/pgConfiguration/id/delete').post('postLocationPath', data)
}
// 房源拆分查询
export const configurationSplitGet = data => {
  return request('/operation/v1/pgConfiguration/id/queryConfigSplitByMainPrimaryKey').get('locationPath', data)
}
// 拆分审批详情跳转
export const configurationSplitGetSp = data => {
  return request('/operation/v1/pgConfiguration/id/queryHouseSplitByPrimaryKey').get('locationPath', data)
}
// 结算审批详情跳转
export const configurationSettlementGetSp = data => {
  return request('/operation/v1/pgConfiguration/id/queryStatementByPrimaryKey').get('locationPath', data)
}
// 交付审批详情跳转
export const configurationDeliverGetSp = data => {
  return request('/operation/v1/pgConfiguration/id/queryDeliveryByPrimaryKey').get('locationPath', data)
}
// 房源拆分新建
export const configurationSplitInit = data => {
  return request('/operation/v1/pgConfiguration/houseSplitAdd').post('postNormal', data)
}
// 房源拆分更新
export const configurationSplitUpdate = data => {
  return request('/operation/v1/pgConfiguration/id/houseSplitUpdate').post('postLocationPath', data)
}
// 房源拆分房屋信息分页查询
export const configurationSplitFwPage = data => {
  return request('/operation/v1/pgConfiguration/queryHouseInfoByPage').post('postNormal', data)
}
// 房源拆分车位信息分页查询
export const configurationSplitCwPage = data => {
  return request('/operation/v1/pgConfiguration/queryPgParkingSplitByPage').post('postNormal', data)
}
// 房源拆分商铺信息分页查询
export const configurationSplitSpPage = data => {
  return request('/operation/v1/pgConfiguration/queryPgShopsSplitByPage').post('postNormal', data)
}
// 房屋删除
export const configurationSplitFwDelete = data => {
  return request('/operation/v1/pgConfiguration/id/deleteHouseInfo').post('postLocationPath', data)
}
// 车位删除
export const configurationSplitCwDelete = data => {
  return request('/operation/v1/pgConfiguration/id/deletePgParkingSplit').post('postLocationPath', data)
}
// 商铺删除
export const configurationSplitSpDelete = data => {
  return request('/operation/v1/pgConfiguration/id/deletePgShopsSplit').post('postLocationPath', data)
}
// 房源拆分修改
export const configurationSplitUpadte = data => {
  return request('/operation/v1/pgConfiguration/id/houseSplitUpdate').post('postLocationPath', data)
}
// 结算书新建
export const configurationSettlementInit = data => {
  return request('/operation/v1/pgConfiguration/statementAdd').post('postNormal', data)
}
// 结算书查询
export const configurationSettlementDetails = data => {
  return request('/operation/v1/pgConfiguration/id/queryStatementByMainPrimaryKey').get('locationPath', data)
}
// 结算书分页查询
// export const configurationSettlementPage = data => {
//   return request('/operation/v1/pgConfiguration/queryStatementByPage').post('postNormal', data)
// }
export const configurationSettlementPage = data => {
  return request('/operation/v1/pgConfiguration/queryStatementDetailsByPage').post('postNormal', data)
}
// 结算书分页删除
export const configurationSettlementDelete = data => {
  return request('/operation/v1/pgConfiguration/id/deletePgStatement').post('postLocationPath', data)
}
// 结算书修改
export const configurationSettlementUpdate = data => {
  return request('/operation/v1/pgConfiguration/id/statementUpdate').post('postLocationPath', data)
}
// 物业交付新建
export const configurationDeliverInit = data => {
  return request('/operation/v1/pgConfiguration/deliveryAdd').post('postNormal', data)
}
// 物业交付查询
export const configurationDeliverDetails = data => {
  return request('/operation/v1/pgConfiguration/id/queryDeliveryByMainPrimaryKey').get('locationPath', data)
}
// 物业交付分页查询
export const configurationDeliverPage = data => {
  return request('/operation/v1/pgConfiguration/queryDeliveryEnergyRecordItemByPage').post('postNormal', data)
}
// 物业交付分页删除
export const configurationDeliverDelete = data => {
  return request('/operation/v1/pgConfiguration/id/deleteEnergyRecordItem').post('postLocationPath', data)
}
// 物业支付更新
export const configurationDeliverUpdate = data => {
  return request('/operation/v1/pgConfiguration/id/deliveryUpdate').post('postLocationPath', data)
}
// 确认交付
export const configurationDeliverOk = data => {
  return request('/operation/v1/pgConfiguration/id/houseRelationGoods').post('postLocationPath', data)
}
// 请求项目列表
export const queryRelationStoreProjectList = data => {
  return request('/operation/v1/project/queryRelationStoreProjectList').post('postNormal', data)
}
// 拆分房屋导出
export const exportHouseSplit = data => {
  return request('/operation/v1/pgConfiguration/exportHouseSplit').post('postBlobQuery', data)
}
// 拆分车位导出
export const exportPgParkingSplit = data => {
  return request('/operation/v1/pgConfiguration/exportPgParkingSplit').post('postBlobQuery', data)
}
// 拆分商铺导出
export const exportPgShopsSplit = data => {
  return request('/operation/v1/pgConfiguration/exportPgShopsSplit').post('postBlobQuery', data)
}
// 检查项目改造单是否已交付
export const checkProjectInfo = data => {
  return request('/operation/v1/pgConfiguration/id/checkProjectInfo').post('postLocationPath', data)
}
// 费用承担方选择
export const querySignerInfoByProjectId = data => {
  return request('/operation/v1/project/querySignerInfoByProjectId').get('normal', data)
}
// 房源结算成本明细导出
export const exportHouseSplitInSettlement = data => {
  return request('/operation/v1/pgConfiguration/exportHouseSplitInSettlement').post('postBlobQuery', data)
}
// 结算书导出
export const exportPgStatement = data => {
  return request('/operation/v1/pgConfiguration/exportPgStatement').post('postBlobQuery', data)
}


// 交付电表导出模板
export const exportEnergyRecordItemTemplate = data => {
  return request('/operation/v1/pgConfiguration/exportEnergyRecordItemTemplate').post('postBlobFormDataQuery', data)
}
// 交付电表导出
export const exportEnergyRecordItem = data => {
  return request('/operation/v1/pgConfiguration/exportEnergyRecordItem').post('postBlobQuery', data)
}
// 物业交付分页查询
export const queryConPropertyDeliveryItemByPage = data => {
  return request('/operation/v1/pgConfiguration/queryConPropertyDeliveryItemByPage').post('postNormal', data)
}
