import BasicLayout from '@/layouts/basic-layout'

export default {
  path: '/report-center/report-contract-renew',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: 'ReportContractRenew',
      meta: {cache: true, title: '续租报表'},
      component: () => import('@/pages/report-center/report-contract-renew')
    }
  ]
}
