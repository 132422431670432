/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 户型管理
export const getHouseTypeList = data => {
  return request('/operation/v1/houseType/page').post('postNormal', data)
}
// 添加户型
export const houseTypeAdd = data => {
  return request('/operation/v1/houseType').post('postNormal', data)
}
// 删除户型
export const houseTypeDelete = data => {
  return request('/operation/v1/houseType/id/delete').post('postLocationPath', data)
}
// 修改户型
export const houseTypeUpdate = data => {
  return request('/operation/v1/houseType/id/update').post('postLocationPath', data)
}
// 查看户型
export const houseTypeView = data => {
  return request('/operation/v1/houseType/id/queryByPrimaryKey').get('locationPath', data)
}
// 户型下线
export const offline = data => {
  return request('/operation/v1/houseType/id/offline').get('locationPath', data)
}
// 查询户型id
export const houseTypeOnlineLog = data => {
  return request('/operation/v1/houseTypeOnlineLog/id/queryByPrimaryKey').get('locationPath', data)
}
// 平台信息查看户型
export const platformInfoHouseTypeView = data => {
  return request('/operation/v1/houseType/id/queryByPrimaryKeyNotNeedCompanyId').get('locationPath', data)
}
// 户型审核接口
export const auditOnline = data => {
  return request('/operation/v1/houseType/auditOnline').post('postNormal', data)
}
// 户型上下线日志
export const houseTypeOnlineLogPage = data => {
  return request('/operation/v1/houseTypeOnlineLog/page').post('postNormal', data)
}