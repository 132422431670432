/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 应收分页查询
export const getFinPaymentList = data => {
  return request('/operation/v1/finPayment/page').post('postNormal', data)
}
// 应收根据id查询信息
export const finPaymentQueryByPrimaryKey = data => {
  return request('/operation/v1/finPayment/id/queryByPrimaryKey').get('locationPath', data)
}
// 统计应收账单金额
export const countPayment = data => {
  return request('/operation/v1/finPayment/countPayment').post('postNormal', data)
}
// 费用明细查询
export const queryPaymentItemByPage = data => {
  return request('/operation/v1/finPayment/queryPaymentItemByPage').post('postNormal', data)
}
// 导出应收账单信息
export const exportFinPayment = data => {
  return request('/operation/v1/finPayment/exportFinPayment').post('postBlobQuery', data)
}

// 确认收款
export const confirmCollection = data => {
  return request('/operation/v1/finPayment/confirmCollection').get('normal', data)
}

// 作废帐单
export const cancelFinPayment = data => {
  return request('/operation/v1/finPayment/cancelFinPaymentById').get('normal', data)
}
