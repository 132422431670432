<template>
  <div :class="['process-approval', {mobile: isMobile}]" v-if="isVisible">
    <Card dis-hover title="流程操作" class="card" :bordered="false" v-if="!isMobile">
      <Row :gutter="8">
        <Col v-if="stepUserId && (user.employeeId === stepUserId || isAgent) && detail.auditStatus !== 3">
          <Button type="primary" @click="toApproval">流程审批</Button>
        </Col>
        <Col v-if="approvalProcessCode || $route.query.approvalProcessId">
          <Button type="primary" @click="toDetail">流程痕迹</Button>
        </Col>
        <Col v-if="detail.prevDetailVos && detail.prevDetailVos.length && (detail.prevDetailVos[0].approvedById === user.employeeId || isAgent) && detail.auditStatus === 0">
          <Button type="primary" @click="toWithdraw">流程回撤</Button>
        </Col>
        <template v-if="detail.auditStatus === 0 && stepUserId && (isAgent || user.employeeId === stepUserId)">
          <Col v-if="detail.prevDetailVos"><Button type="primary" @click="toBackOff">流程回退</Button></Col>
          <Col><Button type="primary" @click="toForward">流程转发</Button></Col>
          <Col><Button type="primary" @click="toSign">流程加签</Button></Col>
        </template>
        <Col v-if="processImg"><Button type="primary" @click="toViewProcessImg">流程图</Button></Col>
      </Row>
      <Spin fix v-if="!isInit" />
    </Card>
    <template v-else>
      <div class="toolbar" v-if="isInit">
        <div class="toolbar-inner">
          <Dropdown trigger="click" placement="top"
                    v-if="(detail.auditStatus === 0 && stepUserId && (isAgent || user.employeeId === stepUserId)) || processImg"
                    @on-click="onActionsHandle">
            <Button long>更多操作</Button>
            <DropdownMenu slot="list">
              <template v-if="detail.auditStatus === 0 && stepUserId && (isAgent || user.employeeId === stepUserId)">
                <DropdownItem name="toBackOff" v-if="detail.prevDetailVos">流程回退</DropdownItem>
                <DropdownItem name="toForward">流程转发</DropdownItem>
                <DropdownItem name="toSign">流程加签</DropdownItem>
              </template>
              <DropdownItem name="toViewProcessImg" v-if="processImg">流程图</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button type="primary"
                  v-if="approvalProcessCode || $route.query.approvalProcessId"
                  @click="toDetail">流程痕迹</Button>
          <Button type="primary"
                  v-if="stepUserId && (user.employeeId === stepUserId || isAgent) && detail.auditStatus !== 3"
                  @click="toApproval">审批</Button>
        </div>
        <div class="safe-area-inset-bottom" />
      </div>
    </template>

    <!-- 流程痕迹 -->
    <ProcessTrace ref="processTrace" v-model="processTrace" />
    <!-- 审批流程 -->
    <ProcessOperate :detail="detail"
                    :next-node-approver-list="nextNodeApproverList"
                    v-model="processOperate"
                    @on-success="closePage" />
    <!-- 流程图 -->
    <ProcessImage :src="processImg"
                  v-model="processImage" />
    <!-- 流程回退 -->
    <ProcessFallback :detail="detail"
                     v-model="processFallback"
                     @on-success="init" />

    <ProcessType :type="type" :detail="detail"
                 v-model="processType"
                 @on-success="onSuccessHandle" />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { get, pick, isArray } from 'lodash'
  import {
    approvalQueryWorkflowChain,
    queryRoleEmployeesByPage,
    queryProcessImage,
    retreat,
  } from '@/api/process-center'
  import ProcessTrace from './process-trace'
  import ProcessImage from './process-image'
  import ProcessOperate from './process-operate'
  import ProcessFallback from './process-fallback'
  import ProcessType from './process-type'

  export default {
    name: 'ProcessApproval',
    components: {
      ProcessTrace,
      ProcessImage,
      ProcessOperate,
      ProcessFallback,
      ProcessType,
    },
    props: {
      approvalProcessCode: {
        type: String,
        default: '',
      },
      approvalProcessId: {
        type: String,
        default: '',
      },
      documentId: {
        type: String,
        default: '',
      },
    },
    watch: {
      $route: {
        handler ({query}) {
          if (!query.approvalProcessId) return this.isInit = true
          this.init()
        },
        immediate: true,
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      user() {
        return this.$store.state.admin.user.info
      },
      pageCode () {
        return this.$route.query.pageCode
      },
      stepUserId () {
        return this.$route.query.stepUserId
      },
      isVisible () {
        const {user, detail, isAgent, stepUserId, processImg, approvalProcessCode} = this
        const {query} = this.$route

        return (stepUserId && (user.employeeId === stepUserId || isAgent) && detail.auditStatus !== 3)
          || (approvalProcessCode || query.approvalProcessId)
          || (detail.prevDetailVos && detail.prevDetailVos.length && (detail.prevDetailVos[0].approvedById === user.employeeId || isAgent) && detail.auditStatus === 0)
          || (detail.auditStatus === 0 && stepUserId && (isAgent || user.employeeId === stepUserId))
          || processImg
      },
    },
    methods: {
      ...mapActions('admin/page', ['close']),
      init () {
        this.approvalQueryWorkflowChainReq()
      },

      toSign () {
        this.type = 'sign'
        this.processType = true
      },
      toDetail () {
        const {query, params} = this.$route

        this.processTrace = true
        this.$refs.processTrace.init({
          ...this.approvalProcessCode && {
            documentId: this.documentId,
            approvalProcessCode: this.approvalProcessCode
          },
          ...!this.approvalProcessCode && {
            documentId: params.id,
            approvalProcessId: query.approvalProcessId,
          }
        })
      },
      toForward () {
        this.type = 'forward'
        this.processType = true
      },
      toBackOff () {
        this.processFallback = true
      },
      toWithdraw () {
        const {readStatus, prevDetailVos}  = this.detail

        this.$Message.destroy()
        if (readStatus === 1 || (isArray(prevDetailVos) && !prevDetailVos.length)) return this.$Message.error('下一审批人已读，不能流程回撤')
        this.$Modal.confirm({
          title: '流程回撤确认',
          content: '请确认是否流程回撤？',
          onOk: async () => {
            try {
              await retreat({
                documentId: this.$route.params.id,
                approvalProcessId: this.$route.query.approvalProcessId,
              })
              this.$Message.success('流程回撤成功')
              this.init()
            } catch (e) {}
          }
        })
      },
      toApproval () {
        this.processOperate = true
      },
      toViewProcessImg () {
        this.processImage = true
      },
      // 查看审批流的链路(审批中心进入)
      async approvalQueryWorkflowChainReq () {
        try {
          this.isInit = false
          const {user, documentId, approvalProcessId} = this
          const {result} = await approvalQueryWorkflowChain({documentId, approvalProcessId})

          this.isAgent = result.currentStepProxyEmployeeIds ? result.currentStepProxyEmployeeIds.includes(user.employeeId) : false
          await Promise.allSettled([
            this.queryProcessImageReq({...pick(result, ['companyOrgId']), approvalProcessId}),
            ...get(result, 'nextNodeVo.roleId') ? [this.queryRoleEmployeesByPageReq(result.nextNodeVo.roleId)] : [],
          ])
          this.detail = result
        } catch (e) {} finally {
          this.isInit = true
        }
      },
      // 流程图
      queryProcessImageReq (params) {
        return new Promise(async (resolve, reject) => {
          try {
            const {result} = await queryProcessImage(params)

            this.processImg = get(result, 'imageUrl')
            resolve()
          } catch (e) { reject(e) }
        })
      },
      // 查看审批人员列表
      queryRoleEmployeesByPageReq (roleId) {
        return new Promise(async (resolve, reject) => {
          try {
            const {result} = await queryRoleEmployeesByPage({pageNum: 1, pageSize: 100, roleId})

            this.nextNodeApproverList = result.list
            resolve()
          } catch (e) { reject(e) }
        })
      },
      // 移动端"更多"按钮
      onActionsHandle (name) {
        this[name]()
      },
      onSuccessHandle (type) {
        if (type === 'forward') return this.closePage()
        this.init()
      },

      closePage() {
        this.close({tagName: this.$route.fullPath})
      },
    },
    data () {
      return {
        isInit: false,
        loading: false,
        isAgent: false,
        detail: {},
        nextNodeApproverList: [],

        // 流程加签
        processSign: false,
        // 流程图
        processImg: '',
        processImage: false,
        // 流程痕迹
        processTrace: false,
        // 流程转发
        processForward: false,
        // 流程审批
        processOperate: false,
        // 流程回退
        processFallback: false,

        type: '',
        processType: false,
      }
    },
  }
</script>

<style scoped lang="less">
  /deep/ .JFDrawer {

    .cell {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
      &-title {
        width: 60px;
      }
      &-desc {
        flex: 1;
      }
    }
    // Card
    .ivu-card-head,
    .ivu-card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .toolbar {
    box-shadow: 0 0 20px 0 rgba(0, 91, 172, .1);
    background-color: #fff;
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;

    &-inner {
      display: flex;
      padding: 7.5px 15px;
      align-items: center;
      justify-content: space-between;

      > * {
        width: 100%;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
</style>
