<template>
  <div class="steps_area">
    <Tag color="success" v-if="approvedByName">下一审批人：{{ approvedByName }}</Tag>
    <Result extra="暂无数据"
            v-if="!list.length && !approvedByName" />
    <Steps direction="vertical" v-else>
      <template v-for="(item, index) in list">
        <Step icon="ios-disc"
              :key="index">
          <template slot="title">
            {{
              [
                item.approvedByName,
                getConfigItemName('COMPLETED_STATUS', item.completedStatus),
                item.brief
              ].join('--')
            }}
          </template>
          <template slot="content">
            <p>流程环节：{{ item.nodeName }}</p>
            <p>流程类型：{{ typeName(item.type) }}</p>
            <p>{{ item.auditTime }}</p>
          </template>
        </Step>
      </template>
    </Steps>
  </div>
</template>
<script>
  import {get} from 'lodash'

  export default {
    name: 'ProcessSteps',
    props: {
      list: {
        type: Array,
        default: () => ([])
      },
      detail: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      approvedByName () {
        return get(this.detail, 'approvedByName')
      }
    },
    methods: {
      typeName (type) {
        return {
          1: '正常流程',
          2: '流程转发',
          3: '流程加签',
        }[type]
      },
      //需动态生成 key为数据字典的key,获取code对应的名称
      getConfigItemName(key, code) {
        if (
          this.$store.state.dictionary['dictionary/index']
            .CompoundEntitiesDictionary.configItems[key] == null
        )
          return ''
        let obj = this.$store.state.dictionary[
          'dictionary/index'
          ].CompoundEntitiesDictionary.configItems[key].find(
          (value) => value.itemCode == code
        )
        return obj ? obj.itemName : ''
      }
    }
  }
</script>
