/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const conProjectPropertyManagement = data => {
  return request('/operation/v1/project/page').post('postNormal', data)
}
// 根据id查询项目物业信息
export const conProjectPropertyDetail = data => {
  return request('/operation/v1/project/id/queryByPrimaryKey').get('locationPath', data)
}
// 根据id查询关联业主信息
export const ownerList = data => {
  return request('/operation/v1/project/id/queryOwnersByPrimaryKey').get('locationPath', data)
}
// 项目更名
export const updateProjectName = data => {
  return request('/operation/v1/conProjectProperty/updateProjectName').get('', data)
}
// 根据id查询关联业主详细信息
export const getOwnerDetail = data => {
  return request('/operation/v1/project/id/getOwnerByPrimaryKey').get('locationPath', data)
}
