


export default {
  namespaced: true,
  state: {

    // 查询条件
    params: {
      areaId: '',
      cityId: '',
      configurationType: '',
      splitStatus: '',
      settlementStatus: '',
      deliveryStatus: '',
      pageNum: 1,
      pageSize: 10,
      provinceId: '',
    },
  },
  getters: {

  },
  // 持久缓存
  mutations: {
    // setCurrentRow(state, row) {
    //   state.currentRow = row
    // }
    setParams(state, data) {
      state.params = data
    }
  },

  actions: {



  }

}
