<template>
  <div>
    <Card dis-hover title="环节列表" :bordered="false">
      <template v-if="isMobile">
        <div class="cell-group">
          <div class="cell">
            <div class="cell-title">环节名称</div>
            <div class="cell-desc">{{ approvalNode.name }}</div>
          </div>
          <div class="cell">
            <div class="cell-title">环节人员</div>
            <div class="cell-desc">
              <span class="nextAuditPersonName" v-if="approvalNode.nextAuditPersonName">{{ approvalNode.nextAuditPersonName }}</span>
              <a v-if="![2, 3].includes(nextNodeVo.nodeType)"
                 @click="selectApproverDrawerShow = true">选择人员</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <vxe-table resize auto-resize :data="approvalNodeList" >
          <vxe-column field="name" title="环节名称" />
          <vxe-column field="nextAuditPersonName" title="环节人员" />
          <vxe-column title="操作" v-if="isEmpty(nextItemDetailVo)">
            <template #default="{ row }">
              <a v-if="![2, 3].includes(nextNodeVo.nodeType)"
                 @click="selectApproverDrawerShow = true">选择人员</a>
            </template>
          </vxe-column>
        </vxe-table>
      </template>
    </Card>
    <!-- 选择人员 -->
    <SelectApprover :defaultSelecteRow="defaultSelecteRow"
                    :nextNodeApproverList="nextNodeApproverList"
                    v-model="selectApproverDrawerShow"
                    @getApproverFn="getApproverFn" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import {get, has, isArray, isEmpty} from 'lodash'
  import * as api from '@/api/configuration'
  import {getProjectLeaderInfo} from '@/api/process-center'
  import SelectApprover from './select-approver'

  export default {
    name: 'ApproverNode',
    components: {SelectApprover},
    props: {
      nextNodeVo: {
        type: Object,
        default: () => ({})
      },
      nextItemDetailVo: {
        type: Object,
        default: () => ({})
      },
      approvalNodeDrawerShow: {
        type: Boolean,
        default: false,
      },
      nextNodeApproverList: {
        type: Array,
        default: () => ([])
      },
      initiatorId: {
        type: String,
        default: '',
      },
      initiatorName: {
        type: String,
        default: '',
      },
      documentId: {
        type: String,
        default: '',
      },
    },
    watch: {
      nextNodeVo: {
        handler (value) {
          if (isEmpty(value)) return
          this.approvalNodeList = [{...value, nextAuditPersonName: ''}]
          if (!isEmpty(this.nextItemDetailVo)) {
            const {approvedById, approvedByName} = this.nextItemDetailVo

            this.defaultSelecteRow = approvedById
            this.$set(this.approvalNodeList, '0', {
              ...get(this.approvalNodeList, '0', {}),
              nextAuditPersonId: approvedById,
              nextAuditPersonName: approvedByName,
            })
            return
          }
          if (this.nextNodeApproverList.length === 1 && isEmpty(this.nextItemDetailVo)) {
            const {id, name} = get(this.nextNodeApproverList, '0', {})

            this.defaultSelecteRow = id
            this.$set(this.approvalNodeList, '0', {
              ...get(this.approvalNodeList, '0', {}),
              nextAuditPersonId: id,
              nextAuditPersonName: name,
            })
            return
          }
          if (value.nodeType === 2) {
            this.defaultSelecteRow = this.initiatorId
            this.$set(this.approvalNodeList, '0', {
              ...get(this.approvalNodeList, '0', {}),
              nextAuditPersonId: this.initiatorId,
              nextAuditPersonName: this.initiatorName,
            })
            return
          }
          if (value.nodeType === 3) this.getProjectLeaderInfoFn()
        },
        deep: true,
        immediate: true,
      },
      nextNodeApproverList: {
        handler (value) {
          if (isArray(value) && value.length) {
            if (value.length === 1 && isEmpty(this.nextItemDetailVo)) {
              const {id, name} = get(value,'0', {})

              this.defaultSelecteRow = id
              this.$set(this.approvalNodeList, '0', {
                ...value[0],
                nextAuditPersonId: id,
                nextAuditPersonName: name,
              })
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      approvalNode () {
        return get(this.approvalNodeList, '0')
      }
    },
    methods: {
      async getProjectLeaderInfoFn() {
        try {
          const res = await api.configurationSplitGetSp({id: this.documentId})

          if (isEmpty(res.result)) return
          const { result } = await getProjectLeaderInfo({id: res.result.projectId})

          this.defaultSelecteRow = result.userId
          this.$set(this.approvalNodeList, '0', {
            ...get(this.approvalNodeList, '0', {}),
            nextAuditPersonId: result.userId,
            nextAuditPersonName: result.userName,
          })
        } catch (e) {}
      },
      getApprovalNodeList() {
        return this.approvalNodeList
      },
      getApproverFn (data = {}) {
        if (!has(data, 'id')) return

        this.defaultSelecteRow = data.id
        this.approvalNodeList.splice(0, 1, {
          ...this.approvalNodeList[0],
          nextAuditPersonId: data.id,
          nextAuditPersonName: data.name
        })
      },
    },
    data () {
      return {
        isEmpty,
        defaultSelecteRow: '',
        selectApproverDrawerShow: false,
        approvalNodeList: [],
      }
    },
  }
</script>

<style scoped lang="less">
  .nextAuditPersonName {
    margin-right: 15px;
  }
</style>
