<template>
  <div id="app"><router-view /></div>
</template>
<script>
  import { isArray } from 'lodash'
  import { mapMutations, mapActions } from 'vuex'
  import { on, off } from 'view-design/src/utils/dom'
  import { setMatchMedia } from 'view-design/src/utils/assist'
  import { getCurrentMenu } from '@/libs/system'

  setMatchMedia()
  export default {
    watch: {
      // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
      '$route': {
        handler (to) {
          const {path} = to
          const {menuList = [], menuDefaultList = []} = this.$store.state.admin.account
          const {header, openNames, activePath} = getCurrentMenu(to, menuList)

          if (header) {
            this.$store.commit('admin/menu/setSider', menuList)
            this.$store.commit('admin/menu/setHeaderName', header)
            this.$store.commit('admin/menu/setOpenNames', openNames)
            this.$store.commit('admin/menu/setActivePath', activePath)
          }
          const result  = this.getPageRightsFn(menuDefaultList, path);
          console.info('this.getPageRightsFn=='+result);
          this.$store.commit('admin/sys/rights/setRights',result )
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      ...mapMutations('admin/layout', ['setDevice']),
      ...mapActions('dictionary/dictionary/index', ['selectByGroups']),
      handleWindowResize () {
        this.handleMatchMedia()
      },
      handleMatchMedia () {
        const matchMedia = window.matchMedia

        if (matchMedia('(max-width: 600px)').matches) {
          this.setDevice('Mobile')
        } else if (matchMedia('(max-width: 992px)').matches) {
          this.setDevice('Tablet')
        } else {
          this.setDevice('Desktop')
        }
      },
      getPageRightsFn (menuDefaultList, pageRouter) {
        const result = []

        if (!isArray(menuDefaultList)) return []
        for (const item of menuDefaultList) {
          if (isArray(item.children)) {
            if (item.code === pageRouter) {
              result.push(...item.children.map(item => item.code))
            } else {
              result.push(...this.getPageRightsFn(item.children, pageRouter))
            }
          }
        }
    
        return result
      }
    },
    created() {
      // 弹窗显示默认3秒
      this.$Message.config({duration: 3})
      this.$root.$on('getDictionaryFn', this.selectByGroups)
    },
    mounted() {
      on(window, 'resize', this.handleWindowResize)
      this.handleMatchMedia()
    },
    beforeDestroy() {
      off(window, 'resize', this.handleWindowResize)
    }
  }
</script>
<style lang="less">
  @import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

  .ivu-global-footer-copyright,
  .ivu-global-footer-links a {
    font-size: 12px !important;
  }
  .i-layout-sider-logo {
    border-bottom: 0 !important;
  }
  .i-layout-menu-side-title-icon {
    font-size: 16px;
  }
  .vxe-table--render-default .vxe-table--empty-placeholder {
    z-index: 0 !important;
  }
  .ivu-card-head p {
    font-weight: bold !important;
  }
  .vxe-loading {
    z-index: 0 !important;
  }
  .i-layout-tabs-fix {
    position: fixed;
    z-index: 9 !important;
  }
  .vxe-table--render-default .vxe-table--border-line {
    z-index: 0 !important;
  }

  .video-modal .ivu-modal-body {
    padding: 0;
  }

  // 安全区
  .safe-area-inset-bottom {
    padding-bottom: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .safe-area-inset-top {
    padding-top: 0;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }

  // 文本颜色
  .ivu-text-primary { color: #2d8cf0; }
  .ivu-text-success { color: #19be6b; }
  .ivu-text-warning { color: #ff9900; }
  .ivu-text-error { color: #ed4014; }
  .ivu-text-info { color: #2db7f5; }
</style>
