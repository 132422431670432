/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const getList = data => {
  return request('/reportform/v1/rentContractSettlement/page').post('postNormal', data)
}
// 导出
export const exportRentContractSettlement = data => {
  return request('/reportform/v1/rentContractSettlement/exportRentContractSettlement').post('postBlobQuery', data)
}