import BasicLayout from '@/layouts/basic-layout'

const prefix = 'storage-manage-owner-contract-manage-'
export default [
  {
    path: '/storage-manage/owner-contract-manage',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {title: '业主合同管理'},
        component: () => import('@/pages/storage-manage/owner-contract-manage/index')
      },
      {
        path: 'detail-create',
        name: `${prefix}create`,
        auth: ['hidden'],
        meta: {cache: true, title: '业主合同新增'},
        component: () => import('@/pages/storage-manage/owner-contract-manage/detail-create')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '业主合同编辑'},
        component: () => import('@/pages/storage-manage/owner-contract-manage/detail-edit')
      },

      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '业主合同详情'},
        component: () => import('@/pages/storage-manage/owner-contract-manage/detail-view')
      },
      {
        path: 'payment-status-list/:id',
        name: `${prefix}payment-status-list`,
        auth: ['hidden'],
        meta: {title: '付款情况'},
        component: () => import('@/pages/storage-manage/owner-contract-manage/payment-status-list')
      }
    ]
  }
]
