/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const getFinPayApplyList = data => {
  return request('/operation/v1/finPayApply/page').post('postNormal', data)
}
// 添加
export const finPayApplyAdd = data => {
  return request('/operation/v1/finPayApply').post('postNormal', data)
}
// 删除
export const finPayApplyDelete = data => {
  return request('/operation/v1/finPayApply/id/delete').post('postLocationPath', data)
}
// 修改
export const finPayApplyUpdate = data => {
  return request('/operation/v1/finPayApply/id/update').post('postLocationPath', data)
}
// 根据id查询信息
export const finPayApplyQueryByPrimaryKey = data => {
  return request('/operation/v1/finPayApply/id/queryByPrimaryKey').get('locationPath', data)
}

// 合同查询-可选择已签约、已终止的合同
export const queryPaymentApplyContractList = data => {
  return request('/operation/v1/contract/queryPaymentApplyContractList').post('postNormal', data)
}