import BasicLayout from '@/layouts/basic-layout'

const prefix = 'voucher-manage-'
export default [
    {
        path: '/voucher-manage',
        redirect: {name: `${prefix}index`},
        component: BasicLayout,
        children: [
            {
                path: 'index',
                name: `${prefix}index`,
                meta: {title: '卡券管理'},
                component: () => import('@/pages/voucher-manage/index')
            },
            {
                path: 'detail-edit/:id',
                name: `${prefix}edit`,
                auth: ['hidden'],
                meta: {cache: true, title: '卡券编辑'},
                component: () => import('@/pages/voucher-manage/detail-edit')
            },

            {
                path: 'detail-view/:id',
                name: `${prefix}view`,
                auth: ['hidden'],
                meta: {title: '卡券详情'},
                component: () => import('@/pages/voucher-manage/detail-view')
            },
            {
                path: 'single-detail-view/:id',
                name: `${prefix}single-view`,
                auth: ['hidden'],
                meta: {title: '卡券详情'},
                component: () => import('@/pages/voucher-manage/single-detail-view')
            },
        ]
    }
]
