/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// [楼栋]参数查询
export const queryBlockByParam = data => {
  return request('/operation/v1/building/queryBlockByParam').post('postNormal', data)
}
// [楼栋]分页查询
export const queryBlockByPage = data => {
  return request('/operation/v1/building/queryBlockByPage').post('postNormal', data)
}
// [楼栋]根据id查询信息
export const queryBlockByPrimaryKey = data => {
  return request('/operation/v1/building/id/queryBlockByPrimaryKey').get('locationPath', data)
}
// [楼栋]修改
export const blockUpdate = data => {
  return request('/operation/v1/building/id/blockUpdate').post('postLocationPath', data)
}
// [楼栋]删除
export const blockDelete = data => {
  return request('/operation/v1/building/id/blockDelete').post('postLocationPath', data)
}
// [楼栋]添加
export const blockAdd = data => {
  return request('/operation/v1/building/blockAdd').post('postNormal', data)
}


// [楼盘]分页查询
export const queryBuildingByPage = data => {
  return request('/operation/v1/building/queryBuildingByPage').post('postNormal', data)
}
// [楼盘]添加
export const addBuilding = data => {
  return request('/operation/v1/building/addBuilding').post('postNormal', data)
}
// [楼盘]参数查询
export const queryBuildingByParam = data => {
  return request('/operation/v1/building/queryBuildingByParam').post('postNormal', data)
}
// [楼盘]修改
export const updateBuilding = data => {
  return request('/operation/v1/building/id/updateBuilding').post('postLocationPath', data)
}
// [楼盘]删除
export const deleteBuilding = data => {
  return request('/operation/v1/building/id/deleteBuilding').post('postLocationPath', data)
}


// [单元]分页查询
export const queryUnitByPage = data => {
  return request('/operation/v1/building/queryUnitByPage').post('postNormal', data)
}
// [单元]参数查询
export const queryUnitByParam = data => {
  return request('/operation/v1/building/queryUnitByParam').post('postNormal', data)
}
// [单元]修改
export const unitUpdate = data => {
  return request('/operation/v1/building/id/updateUnit').post('postLocationPath', data)
}
// [单元]删除
export const unitDelete = data => {
  return request('/operation/v1/building/id/deleteUnit').post('postLocationPath', data)
}
// [单元]添加
export const unitAdd = data => {
  return request('/operation/v1/building/addUnit').post('postNormal', data)
}