import BasicLayout from '@/layouts/basic-layout'

const prefix = 'goods-disposition-manage-'
export default [
  {
    path: '/store/goods-disposition-manage',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {title: '物品配置管理'},
        component: () => import('@/pages/store/goods-disposition-manage/index')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {title: '物品配置编辑'},
        component: () => import('@/pages/store/goods-disposition-manage/detail-edit')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '物品配置详情'},
        component: () => import('@/pages/store/goods-disposition-manage/detail-view')
      }
    ]
  }
]
