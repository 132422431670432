import BasicLayout from '@/layouts/basic-layout'

const prefix = 'housing-estate-manage-'
export default [
  {
    path: '/base-manage/housing-estate-manage',
    redirect: {name: `${prefix}index`},
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: `${prefix}index`,
        meta: {title: '楼盘管理'},
        component: () => import('@/pages/base-manage/housing-estate-manage/index')
      },
      {
        path: 'detail-create',
        name: `${prefix}create`,
        auth: ['hidden'],
        meta: {title: '楼盘新增'},
        component: () => import('@/pages/base-manage/housing-estate-manage/detail-create')
      },
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {title: '楼盘编辑'},
        component: () => import('@/pages/base-manage/housing-estate-manage/detail-edit')
      },

      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '楼盘详情'},
        component: () => import('@/pages/base-manage/housing-estate-manage/detail-view')
      }
    ]
  }
]
