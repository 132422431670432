import BasicLayout from '@/layouts/basic-layout'

const prefix = 'house-incidental-expenses-'
export default {
  path: '/financial-manage/house-incidental-expenses',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {cache: true, title: '日杂费管理'},
      component: () => import('@/pages/financial-manage/house-incidental-expenses/index')
    },
    {
      path: 'detail-edit',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '日杂费(单次)编辑', virtualScroller: true},
      component: () => import('@/pages/financial-manage/house-incidental-expenses/detail-edit')
    },
    {
      path: 'cyclicity-detail-edit/:id',
      name: `cyclicity-edit`,
      auth: ['hidden'],
      meta: {title: '日杂费(周期)编辑'},
      component: () => import('@/pages/financial-manage/house-incidental-expenses/cyclicity-detail-edit')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: {title: '日杂费(单次)详情'},
      component: () => import('@/pages/financial-manage/house-incidental-expenses/detail-view')
    },
    {
      path: 'cyclicity-detail-view/:id',
      name: `cyclicity-view`,
      auth: ['hidden'],
      meta: {title: '日杂费(周期)详情'},
      component: () => import('@/pages/financial-manage/house-incidental-expenses/cyclicity-detail-view')
    }
  ]
}
