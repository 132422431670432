import BasicLayout from '@/layouts/basic-layout'

const prefix = 'prepaid-order-'
export default {
  path: '/financial-manage/prepaid-order',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '预支付订单管理'},
      component: () => import('@/pages/financial-manage/prepaid-order/index')
    },
  ]
}
