import BasicLayout from '@/layouts/basic-layout'

const prefix = 'accounts-renewal-statement-'
export default {
  path: '/report-center/accounts-renewal-statement',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {cache: true, title: '换续房费结算报表'},
      component: () => import('@/pages/report-center/accounts-renewal-statement/index')
    },
  ]
}
