/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const getLesseeProjectCollectionApplyList = data => {
  return request('/operation/v1/lesseeProjectCollectionApply/page').post('postNormal', data)
}
// 查询已签约项目(不分页)
export const conProjectProperty = data => {
  return request('/operation/v1/project/queryIsSignProject').post('postNormal', data)
}
// 根据项目id查询关联的承租单位,门店信息
export const lesseeProjectCollectionApply = data => {
  return request('/operation/v1/lesseeProjectPayApply/projectId/queryByProjectId').get('locationPath', data)
}
// 根据id查询信息
export const lesseeProjectCollectionApplyQueryByPrimaryKey = data => {
  return request('/operation/v1/lesseeProjectCollectionApply/id/queryByPrimaryKey').get('locationPath', data)
}
// 作废
export const cancel = data => {
  return request('/operation/v1/lesseeProjectCollectionApply/cancel').get('normal', data)
}