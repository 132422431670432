/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const getFinTransferList = data => {
  return request('/operation/v1/finTransfer/page').post('postNormal', data)
}
// 认领
export const claimFinTransfer = data => {
  return request('/operation/finTransfer/claim').post('postNormal', data)
}
// 删除
export const finTransferDelete = data => {
  return request('/operation/v1/finTransfer/id/delete').post('postLocationPath', data)
}
// 批量删除
export const finTransferBatchDelete = data => {
  return request('/operation/v1/finTransfer/batchDelete').post('postNormal', data)
}
// 导出未认领数据
export const exportUnClaimData = data => {
  return request('/operation/v1/finTransfer/exportUnClaimData').post('postBlobQuery', data)
}
// 导入转帐帐流水
export const importTransfer = data => {
  return request('/operation/v1/finTransfer/importTransfer').post('postBlobQuery', data)
}
// 根据id查询信息
export const finTransferQueryByPrimaryKey = data => {
  return request('/operation/v1/finTransfer/id/queryByPrimaryKey').get('locationPath', data)
}
// 根据转帐流水Id查询操作日志
export const queryLogByTransferId = data => {
  return request('/operation/v1/finTransfer/id/queryLogByTransferId').get('locationPath', data)
}