/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询
export const getFinAdjustmentApplyList = data => {
  return request('/operation/v1/finAdjustmentApply/page').post('postNormal', data)
}
// 根据id查询信息
export const finAdjustmentApplyQueryByPrimaryKey = data => {
  return request('/operation/v1/finAdjustmentApply/id/queryByPrimaryKey').get('locationPath', data)
}
// 查询应收帐单
export const queryPaymentByPage = data => {
  return request('/operation/v1/finAdjustmentApply/queryPaymentByPage').post('postNormal', data)
}
// 获取调整表单信息
export const getAdjustmentApplyExVo = data => {
  return request('/operation/v1/finAdjustmentApply/getAdjustmentApplyExVo').post('postNormal', data)
}
// 调整单对应应收单明细项分页查询
export const finAdjustmentApplyItemQueryByPage = data => {
  return request('/operation/v1/finAdjustmentApply/finAdjustmentApplyItemQueryByPage').post('postNormal', data)
}
// 保存调整单信息(需要流程审批)
export const finAdjustmentApplyAdd = data => {
  return request('/operation/v1/finAdjustmentApply/add').post('postNormal', data)
}
// 删除FinAdjustmentApply
export const finAdjustmentApplyDelete = data => {
  return request('/operation/v1/finAdjustmentApply/id/delete').post('postLocationPath', data)
}
// 新建或更新（保存）
export const addOrUpdate = data => {
  return request('/operation/v1/finAdjustmentApply/addOrUpdate').post('postNormal', data)
}