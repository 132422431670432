/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 补充条款管理
export const getConSupplementalClauseList = data => {
  return request('/operation/v1/conSupplementalClause/page').post('postNormal', data)
}
// 添加补充条款
export const conSupplementalClauseAdd = data => {
  return request('/operation/v1/conSupplementalClause').post('postNormal', data)
}
// 统计付款计划（按财务月度）
export const statisticalPayablesList = data => {
  return request('/operation/v1/conSupplementalClause/statisticalPayablesList').post('postNormal', data)
}
// 查看补充条款
export const conSupplementalClauseView = data => {
  return request('/operation/v1/conSupplementalClause/id/queryByPrimaryKey').get('locationPath', data)
}
// 根据合同id查询当月及以后的应付账单
export const queryPayablesByContractId = data => {
  return request('/operation/v1/conSupplementalClause/id/queryPayablesByContractId').get('locationPathQs', data)
}

// 导出应付费用明细
export const exportPayables = data => {
  return request('/operation/v1/conSupplementalClause/exportPayables').post('postBlobQuery', data)
}