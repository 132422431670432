<template>
  <JFDrawer style="position: relative; z-index: 1002;" title="流程图" v-model="visible">
    <a :href="src" title="点击查看大图" target="_blank">
      <img :src="src" />
    </a>
    <template slot="footer">
      <Button type="primary"
              :long="isMobile"
              @click="visible = false">关闭</Button>
    </template>
  </JFDrawer>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ProcessImage',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      src: {
        type: String,
        default: ''
      },
    },
    watch: {
      visible (value) {
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
    },
    data () {
      return {
        visible: false
      }
    }
  }
</script>

<style scoped lang="less">
  a {
    display: block;
  }
  img {
    max-width: 100%;
    display: block;
    height: auto;
  }
</style>
