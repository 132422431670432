/**
 * 用户信息
 * */
import {get} from 'lodash'

export default {
  namespaced: true,
  state: {
    // 用户信息
    info: {},
    storeList: []
  },
  getters: {
    info: state => state.info,
    token: state => get(state.info, 'accessToken', '')
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} state vuex state
     * @param {Object} dispatch vuex dispatch
     * @param {*} info info
     */
    set ({ state, dispatch }, info) {
      return new Promise(resolve => {
        state.info = info
        resolve()
      })
    },
    setStoreList ({ state, dispatch }, storeList) {
      return new Promise(async resolve => {
        // store 赋值
        state.storeList = storeList;
        resolve();
      })
    },
  },
}
