/*
 * @Author: co
 * @Date: 2021-11-02 09:06:26
 */

// 初始化列表查询条件
export function initPageQuery() {
  this.AdvanceQuery.entity = {
    orderByDesc: "",
    orderByList: "",
    pageNum: 1,
    pageSize: 10
  }
}