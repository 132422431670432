/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 已签约项目物业管理
export const conProjectProperty = data => {
  return request('/operation/v1/project/page').post('postNormal', data)
}
// 添加承租单位
export const addConLessee = data => {
  return request('/operation/v1/conLesseeManagement').post('postNormal', data)
}
// 删除承租单位
export const deleteConLessee = data => {
  return request('/operation/v1/conLesseeManagement/id/delete').post('postLocationPath', data)
}
// 修改承租单位
export const updateConLessee = data => {
  return request('/operation/v1/conLesseeManagement/id/update').post('postLocationPath', data)
}
// 查看承租单位
export const viewConLessee = data => {
  return request('/operation/v1/conLesseeManagement/id/queryByPrimaryKey').get('locationPath', data)
}

