import BasicLayout from '@/layouts/basic-layout'

const prefix = 'decision'
export default {
  path: '/decision',
  component: BasicLayout,
  children: [
    {
      path: '',
      name: prefix,
      meta: {title: '下定管理'},
      component: () => import('@/pages/rent/decision')
    },
    // 房态图转下定
    {
      path: 'init',
      name: `${prefix}-init`,
      meta: {title: '下定新建', cache: true},
      component: () => import('@/pages/rent/decision/operation/init')
    },
    {
      path: 'details-sp/:id',
      name: `${prefix}-details-sp`,
      meta: {title: '退定审批'},
      component: () => import('@/pages/rent/decision/operation/details/details-sp')
    },
    {
      path: 'signing',
      name: `${prefix}-signing`,
      meta: {title: '合同签约', cache: true},
      component: () => import('@/pages/rent/contract-management/signing/operation/init/xd-signing')
    },
    {
      path: 'details-yd/:id',
      name: `${prefix}-details-yd`,
      meta: {title: '下定审批'},
      component: () => import('@/pages/rent/decision/operation/details/details-yd')
    },
  ]
}
