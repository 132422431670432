import { isString, isObject, isUndefined } from 'lodash'
import dayjs from 'dayjs'
import { encrypt, decrypt } from '@/libs/util'

export default class {
  /**
   * storage驱动
   * @type Storage
   */
  driver
  /**
   * 前缀
   * @type string
   */
  prefix
  /**
   * 是否加密
   * @type boolean
   */
  isEncrypt

  /**
   * @param {object} option
   */
  constructor (option) {
    this.driver = option.driver || window.localStorage
    this.prefix = option.prefix || ''
    this.isEncrypt = option.isEncrypt || false
  }

  /**
   * 根据键名查找是否存在
   * @param {string} key 键名
   * @return boolean
   */
  has(key) {
    return !!this.get(key)
  }

  /**
   * 根据键名读取storage
   * @param {string} key 键名
   * @param {*} alt
   * @return *
   */
  get(key, alt = null) {
    key = this._in(key)
    const value = this.driver.getItem(key) || ''

    if (value) {
      const {data, expire} = JSON.parse(this.isEncrypt ? decrypt(value) : value)

      if (expire && dayjs().valueOf() > expire) {
        this.remove(key)
        return alt
      }
      return data || alt
    }
    return alt
  }

  /**
   * 读取全部storage
   */
  getAll(fillObj = {}) {
    return this.each((key, value, all) => (all[key] = value), fillObj)
  }

  /**
   * 存储storage
   * @param {string} key 键名
   * @param {*}  data 值
   * @param {number=}  expire 过期时间，单位：秒
   * @return string
   */
  set(key, data, expire) {
    const name = this._in(key)
    const value = JSON.stringify({...(expire && {expire: dayjs().add(expire, 'second').valueOf()}), data})

    return this.driver.setItem(name, this.isEncrypt ? encrypt(value) : value) || name
  }

  /**
   * 批量存储storage
   * @param {object} data
   * @return boolean
   */
  setAll(data) {
    let changed = false

    for (const key in data) {
      const value = data[key]

      if (this.set(key, value) !== value) {
        changed = true
      }
    }
    return changed
  }

  /**
   * 清空storage
   */
  clear() {
    this.driver.clear()
  }

  /**
   * 根据键名批量storage
   * @param {string | array<string>} key 键名数组
   */
  remove(key) {
    const keys = isString(key) ? [key] : key

    keys.forEach(item => this.driver.removeItem(this._in(item)))
  }

  /**
   * storage大小
   * @return number
   */
  size() {
    return this.driver.keys().length
  }

  /**
   * each
   * @param {*} callback
   * @param {object} fill
   * @return object
   */
  each(callback, fill= {}) {
    let length = this.driver.length
    for (let index = 0; index < length; index++) {
      const key = this._out(this.driver.key(index))

      if (!isUndefined(key)) {
        if (callback.call(this, key, this.get(key), fill) === false) break
      }
      if (length > this.driver.length) {
        length--
        index--
      }
    }
    return fill || this
  }

  _in(key) {
    return `${this.prefix}${key}`
  }
  _out(key) {
    return this.prefix ? (key && key.includes(this.prefix) ? key.substring(this.prefix.length) : undefined) : key
  }
}
