import Vue from 'vue'
import { selectByGroups } from '@/api/dictionary'
import { cloneObj } from '@/plugins/utils'
import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
  namespaced: true,
  state: {
    // 初始化信息
    CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
    //查询接口返回的数据
    CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
    Entities: cloneObj(RespCommonDef.Entities),
    //查询条件
    AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
  },
  actions: {
    selectByGroups ({state}, arr) {
      return new Promise((resolve, reject) => {
        selectByGroups(arr)
          .then(async res => {
            if (res.result !== null) {
              let stateConfigItems = Object.keys(state.CompoundEntitiesDictionary.configItems)
              if (stateConfigItems.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                  Vue.set(state.CompoundEntitiesDictionary.configItems, arr[i], res.result[arr[i]])
                }
              } else {
                state.CompoundEntitiesDictionary.configItems = res.result
              }
            }
            resolve(res);
          })
          .catch(err => reject(err))
      })
    }
  }
}
