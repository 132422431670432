


export default {
  namespaced: true,
  state: {
    mobile: { signature: {} },
    // 查询条件
    signingParams: {
      checkInConfirmation: null,
      lesseeId: null,
      contractStatus: null,
      firstPaymentStatus: null,
      fuzzyQueryField: '',

      pageNum: 1,
      pageSize: 10,
      storeId: null,
    },
    // 换租查询
    changeParams: {
      bizType: 2,
      fuzzyQueryField: '',
      lesseeId: null,
      storeId: null,
      pageNum: 1,
      pageSize: 10,
    },
    // 退租查询
    exitParams: {
      bizType: 1,
      fuzzyQueryField: '',
      checkOutStatus: null,
      checkOutType: null,
      storeId: null,
      pageNum: 1,
      pageSize: 10,
    },
    // 续租查询
    reletParams: {
      bizType: 3,
      fuzzyQueryField: '',
      checkOutStatus: null,
      checkOutType: null,
      storeId: null,
      pageNum: 1,
      pageSize: 10,
      signDateStart: null,
      signDateEnd: null
    },
  },
  getters: {

  },
  // 持久缓存
  mutations: {
    setSigningParams(state) {
      state.signingParams.rentStartDateStart = null
      state.signingParams.rentStartDateEnd = null
      state.signingParams.checkInConfirmation = null
      state.signingParams.lesseeId = null
      state.signingParams.contractStatus = null
      state.signingParams.firstPaymentStatus = null
      state.signingParams.fuzzyQueryField = ''
      state.signingParams.pageNum = 1
      state.signingParams.pageSize = 10
      state.signingParams.storeId = null
      state.signingParams.signStartDate = ''
      state.signingParams.signEndDate = ''
    },
    setChangeParams(state) {
      state.changeParams.bizType = 2
      state.changeParams.fuzzyQueryField = ''
      state.changeParams.lesseeId = null
      state.changeParams.storeId = null
      state.changeParams.pageNum = 1
      state.changeParams.pageSize = 10
    },
    setExitParams(state) {
      state.exitParams.bizType = 1
      state.exitParams.fuzzyQueryField = ''
      state.exitParams.checkOutStatus = null
      state.exitParams.checkOutType = null
      state.exitParams.storeId = null
      state.exitParams.pageNum = 1
      state.exitParams.pageSize = 10
    },
    setReletParams(state) {
      state.reletParams.bizType = 3
      state.reletParams.fuzzyQueryField = ''
      state.reletParams.checkOutStatus = null
      state.reletParams.checkOutType = null
      state.reletParams.storeId = null
      state.reletParams.pageNum = 1
      state.reletParams.pageSize = 10
    }
  },

  actions: {



  }

}
