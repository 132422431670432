import iLink from './link'
import Modal from './modal'
import Drawer from './drawer'
import CurrencyInput from './currency-input'
import CommonCollapse from './common/collapse'
import ProcessApproval from './common/process/process-approval'

export const install = (Vue) => {
  if (install.installed) return
  Vue.component(iLink.name, iLink)
  Vue.component(Modal.name, Modal)
  Vue.component(Drawer.name, Drawer)
  Vue.component(CurrencyInput.name, CurrencyInput)
  Vue.component(CommonCollapse.name, CommonCollapse)
  // 流程审批
  Vue.component(ProcessApproval.name, ProcessApproval)
}

export default {
  install
}
