/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 承租单位管理
export const conLesseeManagement = data => {
  return request('/operation/v1/conLesseeManagement/page').post('postNormal', data)
}
// 添加承租单位
export const addConLessee = data => {
  return request('/operation/v1/conLesseeManagement').post('postNormal', data)
}
// 删除承租单位
export const deleteConLessee = data => {
  return request('/operation/v1/conLesseeManagement/id/delete').post('postLocationPath', data)
}
// 修改承租单位
export const updateConLessee = data => {
  return request('/operation/v1/conLesseeManagement/id/update').post('postLocationPath', data)
}
// 修改税率
export const updateLesseeRate = data => {
  return request('/operation/v1/conLesseeManagement/id/updateLesseeRate').post('postLocationPath', data)
}

// 查看承租单位
export const viewConLessee = data => {
  return request('/operation/v1/conLesseeManagement/id/queryByPrimaryKey').get('locationPath', data)
}
// 创建签署印章
export const createSignatureSeal = data => {
  return request('/operation/v1/conLesseeManagement/createSignatureSeal').get('normal', data)
}

// 选择承租人列表（合同新增）
export const queryLesseeList = data => {
  return request('/operation/v1/conDeputeContract/queryLesseeList').post('postNormal', data)
}

// 根据id查询账户信息
export const queryLesseeAccountById = data => {
  return request('/operation/v1/conLesseeManagement/id/queryLesseeAccountById').get('locationPath', data)
}

// 承租单位管理参数查询
export const conLesseeManagementQueryByParam = data => {
  return request('/operation/v1/conLesseeManagement/queryByParam').post('postNormal', data)
}

// 根据id查询企业信息
export const queryNonSelfLesseeByCompanyId = data => {
  return request('/operation/v1/conLesseeManagement/id/queryNonSelfLesseeByCompanyId').get('locationPath', data)
}

// 企业信息根据公司id查询查看承租单位
export const queryByCompanyId = data => {
  return request('/operation/v1/intelligentDeviceCompany/companyId/queryByCompanyId').get('locationPath', data)
}
// 企业信息电表
export const saveOrUpdateDevice = data => {
  return request('/operation/v1/intelligentDeviceCompany/saveOrUpdate').post('postNormal', data)
}
// 企业信息电表
export const intelligentDeviceVoucher = data => {
  return request('/operation/v1/intelligentDeviceVoucher/queryByParam').post('postNormal', data)
}
// 企业信息门锁
export const updateDoorLockConfig = data => {
  return request('/operation/v1/conLesseeManagement/updateDoorLockConfig').get('normal', data)
}
