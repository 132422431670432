import http from '@/plugins/request/http'
import request from '@/plugins/request'

// 分页查询
export const getList = data => http('/reportform/v1/rentContractPaymentCollectionTotal/page').post('postNormal', data)
// 导出
export const exportRentContractPaymentCollectionTotal = data => request.post('/reportform/v1/rentContractPaymentCollectionTotal/exportRentContractPaymentCollectionTotal', data, {
  entire: true,
  responseType: 'blob'
})

// 生成数据
export const rentContractPaymentCollectionTotal = data => request('/reportform/v1/rentContractPaymentCollectionTotal/genData').get('normal', data)
