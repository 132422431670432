<template>
  <Modal v-bind="$attrs" v-model="visible"
         @on-ok="$emit('on-ok')"
         @on-cancel="$emit('on-cancel')">
    <template v-if="!destroyClose">
      <template slot="close" v-if="$slots.close"><slot name="close" /></template>
      <template slot="header" v-if="$slots.header"><slot name="header" /></template>
      <template slot="footer" v-if="$slots.footer"><slot name="footer" /></template>
      <slot />
    </template>
  </Modal>
</template>

<script>
  import {sleep} from '@/libs/util'

  export default {
    name: 'JFModal',
    inheritAttrs: false,
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      // 关闭时销毁 Drawer 里的子元素
      destroyOnClose: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      async visible (value) {
        this.$emit('input', value)
        this.$emit('on-visible-change', value)
        if (this.destroyOnClose) {
          if (!value) {
            await sleep(.35)
            this.destroyClose = true
            this.$emit('on-destroy')
          }
          this.destroyClose = false
        }
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      },
    },
    data () {
      return {
        visible: false,
        destroyClose: false,
      }
    }
  }
</script>
