/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 业主合同管理
export const conDeputeContractManage = data => {
  return request('/operation/v1/conDeputeContract/page').post('postNormal', data)
}
// 添加ConDeputeContract
export const conDeputeContractAdd = data => {
  return request('/operation/v1/conDeputeContract').post('postNormal', data)
}
// 根据id查询业主合同信息
export const conDeputeContractDetail = data => {
  return request('/operation/v1/conDeputeContract/id/queryByPrimaryKey').get('locationPath', data)
}
// 查询入住信息
export const queryForCheckIn = data => {
  return request('/operation/v1/conDeputeContract/queryForCheckIn/id').get('locationPath', data)
}
// 根据推荐单ID发起签约
export const initiateSigningDetail = data => {
  return request('/operation/v1/investmentExpansion/id/initiateSigning').get('locationPath', data)
}
// 计算合同月份
export const calculationTotalMonth = data => {
  return request('/operation/v1/conDeputeContract/calculationTotalMonth').get('normal', data)
}
// 计算付款计划
export const calculationPayPlan = data => {
  return request('/operation/v1/conDeputeContract/calculationPayPlan').post('postNormal', data)
}
// 按财务月度统计付款计划
export const conDeputeContractCountPayPlan = data => {
  return request('/operation/v1/conDeputeContract/countPayPlan').post('postNormal', data)
}
// 计算其它付款计划
export const calculationOtherPayPlan = data => {
  return request('/operation/v1/conDeputeContract/calculationOtherPayPlan').post('postNormal', data)
}
// 导出付款计划
export const exportPayPlan = data => {
  return request('/operation/v1/conDeputeContract/exportPayPlan').post('postBlobQuery', data)
}
// 导出费用计划（按月度汇总）
export const exportMonthPayPlan = data => {
  return request('/operation/v1/conDeputeContract/exportMonthPayPlan').post('postBlobQuery', data)
}
// 导入付款计划
export const importPayPlan = data => {
  return request('/operation/v1/conDeputeContract/importPayPlan').post('postNormal', data)
}

// 合同付款情况
export const queryPayablesItemList = data => {
  return request('/operation/v1/conDeputeContract/queryPayablesItemList').post('postNormal', data)
}
// 历史付款情况
export const queryHisPayPlanList = data => {
  return request('/operation/v1/conDeputeContract/id/queryHisPayPlanList').get('locationPath', data)
}
// 导出历史付款计划
export const exportHisPayPlan = data => {
  return request('/operation/v1/conDeputeContract/exportHisPayPlan').post('postBlobQuery', data)
}

// 统计合同付款情况金额
export const countPayablesItem = data => {
  return request('/operation/v1/conDeputeContract/countPayablesItem').get('normal', data)
}

// 导出其它费用项
export const exportOtherFee = data => {
  return request('/operation/v1/conDeputeContract/exportOtherFee').post('postBlobQuery', data)
}