import { storePersonnel } from '@/api/dashboard'
export default {
  namespaced: true,
  state: {
    // 查询条件
    params: {
      houseCode: '',
      pageNum: 1,
      pageSize: 10,
      storeId: '',
      roomNumber: ''
    },
    storeList: []
  },
  getters: {

  },
  // 持久缓存
  mutations: {
    // setCurrentRow(state, row) {
    //   state.currentRow = row
    // }
    setParams(state, data) {
      state.params = data
    }
  },

  actions: {
    // 门店不刷新
    async getStoreList({ state, rootState }, params = {}) {
      let res = await storePersonnel({ userId: rootState.admin.user.info.id, ...params})
      if (res.result) {
        state.storeList = res.result
      }
    }
  }

}
