/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 门店管理
export const getStoreList = data => {
  return request('/operation/v1/store/page').post('postNormal', data)
}
// 门店管理参数查询
export const getStoreQueryByParamList = data => {
  return request('/operation/v1/store/queryByParam').post('postNormal', data)
}
// 添加门店
export const storeAdd = data => {
  return request('/operation/v1/store').post('postNormal', data)
}
// 删除门店
export const storeDelete = data => {
  return request('/operation/v1/store/id/delete').post('postLocationPath', data)
}
// 修改门店
export const storeUpdate = data => {
  return request('/operation/v1/store/id/update').post('postLocationPath', data)
}
// 查看门店
export const storeView = data => {
  return request('/operation/v1/store/id/queryByPrimaryKey').get('locationPathQs', data)
}
// 查询未关联门店（已签约）项目
export const queryProjectPropertyList = data => {
  return request('/operation/v1/store/queryProjectPropertyList').post('postNormal', data)
}
// 根据userId查询门店信息
export const queryByUserId = data => {
  return request('/operation/v1/storePersonnel/userId/queryByUserId').get('locationPath', data)
}
// 查询门店树（返回关联项目信息）
export const queryStoreTree = data => {
  return request('/operation/v1/store/queryStoreTree').get('normal', data)
}
// 查询门店id
export const storeOnlineLog = data => {
  return request('/operation/v1/storeOnlineLog/id/queryByPrimaryKey').get('locationPath', data)
}
// 门店下线
export const storeOfflineLog = data => {
  return request('/operation/v1/storeOnlineLog/id/offline').get('locationPath', data)
}
// 查询绑定门店人员
export const queryBindStorePersonnel = data => {
  return request('/operation/v1/storePersonnel/queryBindStorePersonnel').get('normal', data)
}
// 绑定门店人员
export const bindStorePersonnel = data => {
  return request('/operation/v1/storePersonnel/bindStorePersonnel').post('postNormal', data)
}
// 查询合同签约模版
export const queryTemplateByCompanyId = data => {
  return request('/operation/v1/store/queryTemplateByCompanyId').get('normal', data)
}
// 根据id查询信息
export const storeRetreatDaysLog = data => {
  return request('/operation/v1/storeRetreatDaysLog/id/queryByPrimaryKey').get('locationPath', data)
}
// 查询小b门店数据
export const queryNonSelfStoreData = data => {
  return request('/operation/v1/store/id/queryNonSelfStoreData').get('locationPath', data)
}
// 平台信息审批查看门店
export const platformInfoStoreView = data => {
  return request('/operation/v1/store/id/queryByPrimaryKeyNotNeedCompanyId').get('locationPath', data)
}
// 门店审核接口
export const auditOnline = data => {
  return request('/operation/v1/store/auditOnline').post('postNormal', data)
}
// 门店上下线日志
export const storeOnlineLogPage = data => {
  return request('/operation/v1/storeOnlineLog/page').post('postNormal', data)
}
// 营业状态
export const updateBusinessStatus = data => {
  return request('/operation/v1/store/updateBusinessStatus').get('normal', data)
}
// 历史审批记录接口
export const onlineApproveLog = data => {
  return request('/operation/v1/onlineApproveLog/queryByPage').get('normal', data)
}
// 删除门店人员
export const deleteStorePersonnelByUserId = data => {
  return request('/operation/v1/storePersonnel/userId/deleteStorePersonnelByUserId').get('locationPath', data)
}