/*
 * @Author: co
 * @Date: 2021-09-28 18:27:49
 */
import request from '@/plugins/request/http'
// 分页查询(项目列表)
export const getConPayApplyItemList = data => {
  return request('/operation/v1/conPayApplyItem/page').post('postNormal', data)
}
// 分页查询(业务批次列表)
export const getConPayApplyList = data => {
  return request('/operation/v1/conPayApply/page').post('postNormal', data)
}
// 根据id查询信息
export const conPayApplyQueryByPrimaryKey = data => {
  return request('/operation/v1/conPayApply/id/queryByPrimaryKey').get('locationPath', data)
}
// 根据id查询信息
export const conPayApplyItemQueryByPrimaryKey = data => {
  return request('/operation/v1/conPayApplyItem/id/queryByPrimaryKey').get('locationPath', data)
}
// 查询应付账单（业主付款申请）
export const getConPayApplyPayableList = data => {
  return request('/operation/v1/conPayApply/queryPayableList').post('postNormal', data)
}
// 添加ConPayApply
export const conPayApplyAdd = data => {
  return request('/operation/v1/conPayApply').post('postNormal', data)
}