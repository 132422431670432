import BasicLayout from '@/layouts/basic-layout'

const prefix = 'takeLook'
export default [
  {
    path: '/takelook',
    component: BasicLayout,
    children: [
      {
        path: '',
        name: prefix,
        meta: {cache: true, title: '预约带看'},
        component: () => import('@/pages/take-look/index')
      },
      {
        path: ':type(add|edit)/:id(\\d+)?',
        name: `${prefix}-edit`,
        meta: {cache: true},
        component: () => import('@/pages/take-look/edit')
      }
    ]
  }
]
