import BasicLayout from '@/layouts/basic-layout'

const prefix = 'electronic-account-'
export default {
  path: '/financial-manage/electronic-account',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '电子账户管理', cache: true},
      component: () => import('@/pages/financial-manage/electronic-account/index')
    },
    {
      path: 'detail-create',
      name: `${prefix}create`,
      auth: ['hidden'],
      meta: {title: '电子账户新增'},
      component: () => import('@/pages/financial-manage/electronic-account/detail-create')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '电子账户编辑'},
      component: () => import('@/pages/financial-manage/electronic-account/detail-edit')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}view`,
      auth: ['hidden'],
      meta: {title: '电子账户详情'},
      component: () => import('@/pages/financial-manage/electronic-account/detail-view')
    }
  ]
}
