/*
 * @Author: co
 * @Date: 2021-09-06 15:14:17
 */
import request from '@/plugins/request/http.js'

// 分页查询
export const dataChangesApplyList = data => {
  return request('/operation/v1/dataChangesApply/page').post('postNormal', data)
}
// 查看详情
export const queryByPrimaryKey = data => {
  return request('/operation/v1/dataChangesApply/id/queryByPrimaryKey').get('locationPath', data)
}
// 删除DataChangesApply
export const deleteDataChangesApply = data => {
  return request('/operation/v1/dataChangesApply/id/delete').post('postLocationPath', data)
}