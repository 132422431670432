import BasicLayout from '@/layouts/basic-layout'

const prefix = 'property-delivery-'
export default [
  {
    path: '/storage-manage/property-delivery',
    component: BasicLayout,
    children: [
      {
        path: 'detail-edit/:id',
        name: `${prefix}edit`,
        auth: ['hidden'],
        meta: {cache: true, title: '业主物业交付编辑'},
        component: () => import('@/pages/storage-manage/property-delivery/detail-edit')
      },
      {
        path: 'detail-view/:id',
        name: `${prefix}view`,
        auth: ['hidden'],
        meta: {title: '业主物业交付详情'},
        component: () => import('@/pages/storage-manage/property-delivery/detail-view')
      }
    ]
  }
]
