import BasicLayout from '@/layouts/basic-layout'

const prefix = 'cost-adjustment-'
export default {
  path: '/financial-manage/cost-adjustment',
  redirect: {name: `${prefix}index`},
  component: BasicLayout,
  children: [
    {
      path: 'index',
      name: `${prefix}index`,
      meta: {title: '费用调整管理'},
      component: () => import('@/pages/financial-manage/cost-adjustment/index')
    },
    {
      path: 'detail-edit/:id',
      name: `${prefix}edit`,
      auth: ['hidden'],
      meta: {title: '费用调整编辑'},
      component: () => import('@/pages/financial-manage/cost-adjustment/detail-edit')
    },
    {
      path: 'detail-view/:id',
      name: `${prefix}detail-view`,
      auth: ['hidden'],
      meta: {title: '费用调整详情'},
      component: () => import('@/pages/financial-manage/cost-adjustment/detail-view')
    }
  ]
}
