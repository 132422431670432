/*
 * @Author: co
 * @Date: 2021-09-06 15:14:17
 */
import request from '@/plugins/request/http'

export const selectByGroups = (data) => {
  return request('/operation/v1/configItem/selectByGroups').post('postNormal', data)
}
// 分组查询所有类型
export const configItemManageList = (data) => {
  return request('/operation/v1/configItem/selectGroups').post('postNormal', data)
}
// 根据类型查询值集记录
export const selectByGroup = (data) => {
  return request('/operation/v1/configItem/selectByGroup').get('normal', data)
}
// 添加数据字典
export const configItemAdd = (data) => {
  return request('/operation/v1/configItem/add').post('postNormal', data)
}
// 删除数据字典
export const configItemDelete = (data) => {
  return request('/operation/v1/configItem/id/delete').post('postLocationPath', data)
}
// 修改数据字典
export const configItemUpdate = (data) => {
  return request('/operation/v1/configItem/id/update').post('postLocationPath', data)
}