<template>
  <JFDrawer title="审批详情" style="position: relative; z-index: 1002;" v-model="visible">
    <template v-if="isInit">
      <Card dis-hover :class="{mobile: isMobile}" :bordered="false">
        <div slot="title" class="title">
          <div>发起人：{{ flowChainInfo.initiatorName }}，</div>
          <div>发起时间：{{ flowChainInfo.initiationTime }}</div>
        </div>
        <ProcessSteps :list="flowChainInfo.prevDetailVos"
                      :detail="flowChainInfo.currentDetailVo" />
      </Card>
      <template slot="footer">
        <Button type="primary"
                :long="isMobile"
                @click="visible = false">关闭</Button>
      </template>
    </template>
    <Spin fix v-if="loading" />
  </JFDrawer>
</template>

<script>
  import { mapState } from 'vuex'
  import {
    approvalQueryWorkflowChain,
    approvalProcessCodeQueryWorkflowChain,
    queryRoleEmployeesByPage,
  } from '@/api/process-center'
  import ProcessSteps from './components/process-steps.vue'

  export default {
    name: 'ProcessTrace',
    inheritAttrs: false,
    components: { ProcessSteps },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      documentId: {
        type: String,
        default: '',
      },
      approvalProcessId: {
        type: String,
        default: '',
      },
    },
    watch: {
      visible (value) {
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
    },
    methods: {
      init (row = {}) {
        this.documentId = row.documentId
        this.approvalProcessId = row.approvalProcessId

        if (row.approvalProcessCode) return this.approvalProcessCodeQueryWorkflowChainReq(row)
        this.approvalQueryWorkflowChainReq()
      },
      toApproval() {
        this.visible = true
        this.init()
      },
      // 查看审批流的链路(列表详情进入)
      async approvalProcessCodeQueryWorkflowChainReq(row) {
        try {
          const {result} = await approvalProcessCodeQueryWorkflowChain(row)

          this.flowChainInfo = result
          if (result.nextNodeVo !== null && result.nextNodeVo.roleId) {
            await this.queryRoleEmployeesByPageReq(result.nextNodeVo.roleId)
          }
          this.isInit = true
        } catch (e) {}
      },
      // 查看审批流的链路
      async approvalQueryWorkflowChainReq() {
        try {
          const {documentId, approvalProcessId} = this
          const {result} = await approvalQueryWorkflowChain({documentId, approvalProcessId})

          this.flowChainInfo = result
          this.isInit = true
        } catch (e) {}
      },
      // 查看审批流的链路
      queryRoleEmployeesByPageReq(roleId) {
        return new Promise(async (resolve, reject) => {
          try {
            const {result} = await queryRoleEmployeesByPage({
              roleId: roleId,
              pageNum: 1,
              pageSize: 9999,
            })
            this.nextNodeApproverList = result.list
            resolve()
          } catch (e) { reject(e) }
        })
      },
    },
    mounted () {
      this.$root.$emit('getDictionaryFn', ['COMPLETED_STATUS'])
    },
    data () {
      return {
        flowChainInfo: {},
        nextNodeApproverList: [],

        isInit: false,
        loading: false,
        visible: false
      }
    },
  }
</script>

<style scoped lang="less">
  /deep/ .ivu-card-head {
    padding-top: 0;
  }
  .title {
    display: flex;
    font-weight: 700;
  }
  .mobile .title {
    display: block !important;

    div:not(:last-child) {
      margin-bottom: 8px;
    }
  }
</style>
